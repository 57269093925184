import React, { FunctionComponent, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Plans } from './components/Plans/';
import { Layout } from 'antd';
import SignUp from './containers/SignUp';
import Login from './containers/Login';
import Password from './containers/Password';
import Client from './containers/Client';
import Game from './containers/Game';
import { AuthContextProvider } from './context/auth';
import { ClientContextProvider } from './context/clients';
import { NavigationBar } from './components/shared/NavigationBar';
import { GamesContextProvider } from './context/games';
import Dashboard from './containers/Dashboard';
import { store } from './store';
import { Provider, useDispatch } from 'react-redux';
import { RootState } from './reducers';
import { useSelector } from 'react-redux';
import Clients from './containers/Clients';
import Games from './containers/Games';
import { loginAnonymousUser } from './features/users';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
// import { message } from 'antd';

const { Header, Content } = Layout;
const RoutesComponent: FunctionComponent = () => {

  const currentUser = useSelector((state: RootState) => state.userReducer);
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();
  const navigate = useNavigate();
  // const [messageApi, contextHolder] = message.useMessage();

  const url = new URL(window.location.href);
  const userCredentials = new URLSearchParams(url.search);
  const token = userCredentials.get('token');

  // const warning = () => {
  //   messageApi.open({
  //     type: 'warning',
  //     content: 'For best experience and performance use desktop ',
  //     duration: 200,
  //     style: {
  //       position: 'fixed',
  //       bottom: '1vh',
  //       top: '3vh',
  //     },
  //   });
  // };

  // useEffect(() => {
  //   let name;
  //   if (window.navigator.userAgent.indexOf('Android') != -1)
  //     name = 'Android OS';
  //   if (window.navigator.userAgent.indexOf('like Mac') != -1) name = 'iOS';
  //   if (name) {
  //     warning();
  //   }
  // }, []);

  if (token) {
    dispatch(loginAnonymousUser(token));

    if (!currentUser.loggedIn) {
      return <>Welcome to Wiply!</>;
    }

    navigate(window.location.pathname);
  }
  // useEffect(() => {
  //   if (currentUser?.user && currentUser.user?.freePlan) {
  //     const { dateCreated, id } = currentUser.user;
  //     dispatch(checkTrialStatus(dateCreated, id));
  //   }
  // }, []);


  if (!currentUser.loggedIn) {
    return (
      <Routes>
        <Route path="/signup" element={<SignUp/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/password" element={<Password/>} />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    );
  }

  if (currentUser?.user?.freePlan || !!currentUser?.user?.planId) {
    return (
      <>
        <Header className="bg-gradient-to-r from-violet to-violet-light z-10">
          <NavigationBar />
        </Header>
        <Content className="px-2 md:px-10 h-full flex flex-col relative">
          <Routes>
            <Route path="/" element={
              <ClientContextProvider>
                <GamesContextProvider>
                  <Client/>
                </GamesContextProvider>
              </ClientContextProvider>
            }
            />
            {currentUser.isAdmin && (
              <>
                <Route path="/clients/all" element={
                  <ClientContextProvider>
                    <GamesContextProvider>
                      <Clients/>
                    </GamesContextProvider>
                  </ClientContextProvider>
                }
                />
                <Route path="/games/all" element={
                  <ClientContextProvider>
                    <GamesContextProvider>
                      <Games/>
                    </GamesContextProvider>
                  </ClientContextProvider>
                }
                />
                <Route path="/client/:id" element={
                  <ClientContextProvider>
                    <GamesContextProvider>
                      <Client/>
                    </GamesContextProvider>
                  </ClientContextProvider>
                }
                />
              </>
            )}
            <Route path="/:id/dashboard" element={
              <ClientContextProvider>
                <GamesContextProvider>
                  <Dashboard/>
                </GamesContextProvider>
              </ClientContextProvider>
            }
            />
            <Route path="/plans" element={
              <ClientContextProvider>
                <Plans/>
              </ClientContextProvider>
            }
            />
            <Route path="/:name" element={
              <ClientContextProvider>
                <Game/>
              </ClientContextProvider>
            }
            />
          </Routes>

          {/* Was this when we were using 'react-router-dom' v5.3.0, now we are using v6 */}
          {/* <Routes>
            <ClientContextProvider>
              <GamesContextProvider>
                <Route path="/" element={<Client/>} />
                {currentUser.isAdmin && (
                  <>
                    <Route path="/clients/all" element={<Clients/>} />
                    <Route path="/games/all" element={<Games/>} />
                    <Route path="/client/:id" element={<Client/>} />
                  </>
                )}
                <Route path="/:id/dashboard" element={<Dashboard/>} />
              </GamesContextProvider>
              <Route path="/plans" element={<Plans/>} />
              <Route path="/:name" element={<Game/>} />
            </ClientContextProvider>
          </Routes> */}
        </Content>
      </>
    );
  }

  return (
    <>
      <Header className="bg-gradient-to-r from-violet to-violet-light z-10">
        <NavigationBar />
      </Header>
      <ClientContextProvider>
        <Routes>
          <Route path="/" element={<Plans/>} />
        </Routes>
      </ClientContextProvider>
    </>
  );
};

export function App() {
  return (
    <Provider store={store}>
      <AuthContextProvider>
        <Layout className="layout min-h-screen bg-white">
          <RoutesComponent />
        </Layout>
      </AuthContextProvider>
    </Provider>
  );
}

export default App;

// http://localhost:4200/aa6375ff-0649-4075-87c4-d36f9ed52996/?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTY4MDUyNjc1MiwiZXhwIjoxNjgwNTMwMzUyLCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay10am1lM0B3aXBseS1kZXZlbG9wbWVudC5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInN1YiI6ImZpcmViYXNlLWFkbWluc2RrLXRqbWUzQHdpcGx5LWRldmVsb3BtZW50LmlhbS5nc2VydmljZWFjY291bnQuY29tIiwidWlkIjoiUWFnNHFGTm5Zc01telIwNHlzd0lQbWQxdERjMiJ9.WYD-N5HiLxSUvfElLhyF4KGMo6jTFv-AMUgTFSv6y0fFU8GpVSixgXWSaCacPTlEhMHTicl4SRJLDkvAaLlI8MH6xHTPR-WT0HMsKPTvAWAvAM2h3-psiKU9UzdbJbwvqO3FqrjAuSmk9tXNaM2BE9rOj3XF-JnjkXMncHEqlPbfhPk53efMptpELhOraBG1VFJxmvq1u3zyKLFwWLDa5mfCDpCYeHk4_T-IV5fPvsSe71fPJ08NuQ3rP7QbFO8koLh_ZJiZazYaS7VEkWdRdZC-JLwBaHDhy-6W6BDwyQiMYAqNRxq6ZqCA620aki_f2ToDXec1u0oNHjI2tYqFXA

// http://localhost:4200/93c6371f-f4bb-406e-9c7f-66675019c04d/?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTY4MDUzMDA5OCwiZXhwIjoxNjgwNTMzNjk4LCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay10am1lM0B3aXBseS1kZXZlbG9wbWVudC5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInN1YiI6ImZpcmViYXNlLWFkbWluc2RrLXRqbWUzQHdpcGx5LWRldmVsb3BtZW50LmlhbS5nc2VydmljZWFjY291bnQuY29tIiwidWlkIjoiTmxYY3p3WDdlMmNsTGVBTFZtUG1yNnAyUjlFMyJ9.oooDP0RvA9G87EeDu-WP0FalcJUtve5_-0SQKRtLikV7qe4eSqPE5r4JUrvxgV4Hxm9FvW0dJZeyTH_ZHywRXbWJQ74FDcTZ7KiEnZBsLcV81FfvY3QGFGrTWwLF23AljiZaV08waYwXb8cJhalKrS4BIBIlHYBpvcq-whOgc7gUtxuF2fW423x5kj-Igmtzg2dWB2Bu6kD77i0cQWiXosudRbZ3nEni_ggXV3FmyUaFWK-SqX-r6DIZMnxEkMJxgUniebXhCsUDtwvE1r3MWcd4iSkfLZr6MjwjqZPLHCsbwEjINrIr1OU7a8Rys0EPTFShm__TCBEBwKTUeHmb4A
