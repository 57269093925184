import React, { FunctionComponent } from 'react';
import EditImageCard from '../GameDesignCard/EditImageCard/EditImageCard';
import { hockeyTables } from '@wiply/utils';
import { GameDesignAssetsProps } from '.';

export const GameDesignAssetsHockey: FunctionComponent<GameDesignAssetsProps> = ({
  game,
  commonProps,
  onChangeHandler,
}) => {
  return (
    <EditImageCard
      {...commonProps}
      title="Table Image"
      description={{
        type: 'JPG/PNG',
        size: '554×1024',
        aspectRatio: Number(554 / 1024),
      }}
      accept="image/png, image/jpg"
      defaultValue={game.options.hockeyOptions?.tableImg || hockeyTables[0]}
      onChange={(field: string) =>
        onChangeHandler({
          hockeyOptions: {
            ...game.options.hockeyOptions,
            tableImg: field,
          },
        })
      }
      defaultImagesToChoose={hockeyTables}
      previewCss="max-h-32"
      containerCSS="max-w-md"
    />
  );
};

export default GameDesignAssetsHockey;
