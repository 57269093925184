import { Card, Radio, Space } from 'antd';
import React, { FunctionComponent, useState, useMemo, useEffect } from 'react';
import CardActionItem from '../shared/CardItem/CardActionItem/index';
import CardItem from '../shared/CardItem/index';
import { CheckSquareOutlined } from '@ant-design/icons';
import { Input } from 'antd';

interface EditFieldProps {
  title: string;
  onChange?: (fields: any) => void;
  defaultValue?: string;
  value1?: string;
  value2?: string;
  value3?: string;
  value4?: string;
  value5?: string;
}

const EditOptionsCard: FunctionComponent<EditFieldProps> = ({
  title,
  onChange,
  defaultValue,
  value1,
  value2,
  value3,
  value4,
  value5,
}) => {
  const [value, setValue] = useState(defaultValue || 'medium');

  const onChangeHandler = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  // useEffect(() => {
  //   // if (value !== defaultValue)
  //   onChange(value);
  // }, [value]);

  return (
    <Card title={title} style={{ width: 300 }}>
      <Radio.Group
        onChange={onChangeHandler}
        value={value}
        defaultValue={defaultValue}
      >
        <Space direction="vertical">
          <Radio.Button value={value1}>{value1}</Radio.Button>
          <Radio.Button value={value2}>{value2}</Radio.Button>
          {value3 ? <Radio.Button value={value3}>{value3}</Radio.Button> : null}
          {value4 ? <Radio.Button value={value4}>{value4}</Radio.Button> : null}
          {value5 ? <Radio.Button value={value5}>{value5}</Radio.Button> : null}
        </Space>
      </Radio.Group>
    </Card>
  );
};

export default EditOptionsCard;
