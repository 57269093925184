import React, { FunctionComponent, useContext, useState } from 'react';
import { CouponSettings, GameCoupon } from '@wiply/repository';
import CouponSettingForm from './CouponSettingForm';
import { Checkbox, Divider, Tooltip, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import {
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import GamesContext from '../../../context/games';
import Coupon from '..';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';

interface Props {
  config: GameCoupon;
  setConfig: any;
  setAlert: (boolean) => void;
}

export const TemplateForm: FunctionComponent<Props> = ({
  config,
  setConfig,
  setAlert,
}) => {
  const defaultProps = {
    config,
    setConfig,
    setAlert,
  };
  const [loading, setLoading] = useState(false);
  const { uploadImage, game } = useContext(GamesContext);
  const currentUser = useSelector((state: RootState) => state.userReducer);

  const updateImageHandler = async ({ file }) => {
    setLoading(true);
    const uploadedFile: string = await uploadImage(file, `coupons/logos`);
    if (uploadedFile) {
      const newConfig = { ...config };
      newConfig.logo = uploadedFile;
      setConfig(newConfig);
      setLoading(false);
    }
  };

  const updateBackgroundImageHandler = async ({ file }) => {
    setLoading(true);
    const uploadedFile: string = await uploadImage(file, `coupons/backgrounds`);
    if (uploadedFile) {
      const newConfig = { ...config };
      let newConfig2: any = '';
      if (newConfig?.emailSettings?.backgroundPhoto) {
        newConfig.emailSettings.backgroundPhoto = uploadedFile;
      } else {
        newConfig2 = {
          ...newConfig,
          emailSettings: {
            backgroundPhoto: uploadedFile,
          },
        };
      }
      if (newConfig2 != '') {
        setConfig(newConfig2);
      } else {
        setConfig(newConfig);
      }
      setLoading(false);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const crop = { x: 0, y: 0};

  return (
    <div className="py-2 px-4 bg-white rounded-xl m-auto text-center">
      <div>
        <label className="font-bold ">Logo</label>
        <div className="flex items-center">
          {/* <ImgCrop
            rotationSlider // was 'rotate' before
            showGrid // was 'grid' before
            aspect={3}
            minZoom={0}
            cropperProps={{
              objectFit: 'horizontal-cover',
              // crop: { x: 0, y: 0 },
              initialCroppedAreaPixels: { x: 0, y: 0, width: 100, height: 100},
              restrictPosition: false,
              style: undefined,
              zoomSpeed: undefined,
              mediaProps: undefined
            }}
            fillColor="transparent"
          > */}
            <Upload
              className="ml-4"
              listType="picture-card"
              showUploadList={false}
              customRequest={(file) => {
                setAlert(true);
                updateImageHandler(file);
              }}
            >
              {config.logo ? (
                <img src={config.logo} alt="logo" style={{ width: '100%' }} />
              ) : (
                uploadButton
              )}
            </Upload>
          {/* </ImgCrop> */}
          <span
            className="cursor-pointer text-lg"
            onClick={() => {
              const newConfig = { ...config };
              newConfig.logo = null;
              setAlert(true);
              setConfig(newConfig);
            }}
          >
            <DeleteOutlined />
          </span>
        </div>
        <Divider />
      </div>
      <CouponSettingForm {...defaultProps} instance="title" lines={2} />
      {!config?.fontSizeDiscount3 && (
        <CouponSettingForm {...defaultProps} instance="discount" lines={2} />
      )}
      {config?.fontSizeDiscount3 && (
        <CouponSettingForm {...defaultProps} instance="discount" lines={3} />
      )}
      <CouponSettingForm {...defaultProps} instance="button" />

      <CouponSettingForm {...defaultProps} instance="note" />

      <div className="flex flex-col my-4">
        <label className="font-bold">Background</label>
        <input
          type="color"
          onChange={(e) => {
            const newConfig = { ...config };
            newConfig.background = e.target.value;
            setAlert(true);
            setConfig(newConfig);
          }}
          className={`${config.background} w-full h-9`}
          value={config.background}
        />

        {config?.emailCoupon && (
          <div className="flex items-center">
            <Upload
              className="ml-4"
              listType="picture-card"
              showUploadList={false}
              customRequest={(file) => {
                setAlert(true);
                updateBackgroundImageHandler(file);
              }}
            >
              {config?.emailSettings?.backgroundPhoto ? (
                <img
                  src={config.emailSettings.backgroundPhoto}
                  alt="logo"
                  style={{ maxWidth: '35px' }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
            <span
              className="cursor-pointer text-lg"
              onClick={() => {
                const newConfig = { ...config };
                newConfig.emailSettings.backgroundPhoto = null;
                setAlert(true);
                setConfig(newConfig);
              }}
            >
              <DeleteOutlined />
            </span>
          </div>
        )}
        {config?.emailSettings?.placeHolder1 && (
          <input
            style={{ border: '1px solid black', padding: '10px' }}
            type="text"
            onChange={(e) => {
              const newConfig = { ...config };
              newConfig.emailSettings.placeHolder1 = e.target.value;
              setAlert(true);
              setConfig(newConfig);
            }}
            className={`${config.background} w-full h-9`}
            value={config.emailSettings.placeHolder1 || 'NAME'}
          />
        )}
        {config?.emailSettings?.placeHolder2 && (
          <input
            style={{ border: '1px solid black', padding: '10px' }}
            type="text"
            onChange={(e) => {
              const newConfig = { ...config };
              newConfig.emailSettings.placeHolder2 = e.target.value;
              setAlert(true);
              setConfig(newConfig);
            }}
            className={`${config.background} w-full h-9`}
            value={config.emailSettings.placeHolder2 || 'EMAIL'}
          />
        )}
        {config?.emailSettings?.placeHolder3 && (
          <input
            style={{ border: '1px solid black', padding: '10px' }}
            type="text"
            onChange={(e) => {
              const newConfig = { ...config };
              newConfig.emailSettings.placeHolder3 = e.target.value;
              setAlert(true);
              setConfig(newConfig);
            }}
            className={`${config.background} w-full h-9`}
            value={config.emailSettings.placeHolder3 || 'phone'}
          />
        )}
      </div>
      {!config?.emailCoupon && (
        <>
          <Divider />
          <Checkbox
            onChange={(e) => {
              const newConfig = { ...config };
              newConfig.showCode = e.target.checked;
              setAlert(true);
              setConfig(newConfig);
            }}
            className="font-bold"
            defaultChecked={config.showCode}
          >
            Show Code
          </Checkbox>
          <Divider />
        </>
      )}
        <Checkbox
          onChange={(e) => {
            setAlert(true);
            setConfig({ ...config, emailCoupon: e.target.checked });
            if (config.emailCoupon && config?.emailSettings?.backgroundPhoto) {
              config.emailSettings.backgroundPhoto = null;
            }
          }}
          className="font-bold"
          defaultChecked={config?.emailCoupon || false}
        >
          Email Coupon
        </Checkbox>
      
      {game?.options?.addCrm && game.options.addCrm.length > 3 && (
        <input
          type="text"
          onChange={(e) => {
            const newConfig = { ...config };
            newConfig.emailSettings.formCode = e.target.value;
            setAlert(true);
            setConfig(newConfig);
          }}
          className={`w-full h-9 pl-10 border-solid border-black border-2 `}
          value={config.emailSettings?.formCode}
          placeholder="add your form code from crm"
        />
      )}
    </div>
  );
};

export default TemplateForm;
