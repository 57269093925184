import React, { FunctionComponent, useCallback } from 'react';
import EditImageCard from '../GameDesignCard/EditImageCard/EditImageCard';
import {
  bricksContainers,
  briksBallGallery,
  defalutBricksPrizes,
  defaultBricksPaddle,
  defaultBricksImage
} from '@wiply/utils';
import { GameDesignAssetsProps } from '.';
import EditPrizesCard from '../GameDesignCard/EditPrizesCard';

export const GameDesignAssetsBricks: FunctionComponent<GameDesignAssetsProps> = ({
  game,
  commonProps,
  onChangeHandler,
}) => {
  return (
    <>
      <EditImageCard
        {...commonProps}
        title="Choose Game Machine"
        description={{
          type: 'SVG',
          size: '1140x1959',
          aspectRatio: Number(1140 / 1959),
          text: (
            <span>
              A rect container for the game <br></br>is part of this svg and the
              <br></br> id name has to be: <strong>"game-container"</strong>
            </span>
          ),
        }}
        accept="image/svg+xml"
        defaultValue={game.options.bricksOptions.mobileBgImgUrl}
        onChange={(field: string) =>
          onChangeHandler({
            bricksOptions: {
              ...game.options.bricksOptions,
              mobileBgImgUrl: field,
              bgImgUrl: field,
            },
          })
        }
        defaultImagesToChoose={bricksContainers}
        previewCss=" max-h- text-xs"
      />
      <EditImageCard
        {...commonProps}
        title="Add your Brand Logo"
        description={{
          type: 'JPG/PNG',
          size: '300×500',
          aspectRatio: Number(300 / 500),
        }}
        accept="image/png, image/jpg"
        defaultValue={game.options.bricksOptions?.logo || null}
        onChange={(field: string) =>
          onChangeHandler({
            bricksOptions: {
              ...game.options.bricksOptions,
              logo: field,
            },
          })
        }
      />
      {/* Bricks Game Ball */}
      <EditImageCard
        {...commonProps}
        title="Game Ball"
        description={{
          type: 'PNG',
          isOptional: true,
        }}
        accept="image/png"
        defaultValue={game.options.bricksOptions.ballImgUrl}
        onChange={(field: string) =>
          onChangeHandler({
            bricksOptions: {
              ...game.options.bricksOptions,
              ballImgUrl: field,
            },
          })
        }
        defaultImagesToChoose={briksBallGallery}
      />
      {/* Bricks Game Paddle */}
      <EditImageCard
        {...commonProps}
        title="Game Paddle"
        description={{
          type: 'PNG',
          isOptional: true,
        }}
        accept="image/png"
        defaultValue={game.options.bricksOptions.paddleImgUrl}
        onChange={(field: string) =>
          onChangeHandler({
            bricksOptions: {
              ...game.options.bricksOptions,
              paddleImgUrl: field,
            },
          })
        }
        defaultImagesToChoose={defaultBricksPaddle}

      />
      {/* Bricks Game Brick */}
      <EditImageCard
        {...commonProps}
        title="Game Brick"
        description={{
          type: 'PNG',
          isOptional: true,
        }}
        accept="image/png"
        defaultValue={game.options.bricksOptions.brickImgUrl}
        onChange={(field: string) =>
          onChangeHandler({
            bricksOptions: {
              ...game.options.bricksOptions,
              brickImgUrl: field,
            },
          })
        }
        defaultImagesToChoose={defaultBricksImage}

      />
      {/* Bricks prize */}
      <EditPrizesCard
        game={game}
        defaultImages={defalutBricksPrizes}
        commonProps={commonProps}
      />
    </>
  );
};

export default GameDesignAssetsBricks;
