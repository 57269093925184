import React, { FunctionComponent, memo, useMemo } from 'react';
import { Button as ButtonAntd } from 'antd';

const buttonStyle =
  'bg-gradient-to-r from-violet to-violet-light text-white hover:bg-violet hover:text-white hover:border-transparent';
interface Props {
  title: string | React.ReactNode;
  onClick?: () => void;
  submit?: boolean;
  className?: string;
  style?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  disableStyles?: boolean;
  shape?: "circle" | "default" | "round";
}

export const Button: FunctionComponent<Props> = memo(
  ({
    title,
    onClick,
    className,
    submit,
    style,
    icon,
    disabled,
    disableStyles,
    shape,
    
  }) => {
    const styles =
      style === 'inverted' ? buttonStyle : 'text-violet border-violet bg-transparent';

    return (
      <ButtonAntd
        onClick={onClick}
        htmlType={submit ? 'submit' : 'button'}
        className={`${!disableStyles ? styles : ''} rounded-lg shadow-md flex justify-center items-center ${
          className ? className : ''
        }`}
        disabled={disabled}
        shape={shape}
      >
        {icon}
        {title}
      </ButtonAntd>
    );
  }
);

export default Button;
