import React, { FunctionComponent, useCallback } from 'react';
import Icon, { TwitterOutlined, WhatsAppOutlined } from '@ant-design/icons';
import ShareButtonItem from './ShareButtonItem';

const FacebookSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.399"
    height="13.814"
    viewBox="0 0 7.399 13.814"
  >
    <path
      d="m8.523 7.77.384-2.5h-2.4V3.648A1.25 1.25 0 0 1 7.918 2.3h1.09V.169A13.3 13.3 0 0 0 7.072 0a3.052 3.052 0 0 0-3.267 3.365V5.27h-2.2v2.5h2.2v6.044h2.7V7.77z"
      data-name="Icon awesome-facebook-f"
      style={{ fill: '#fff' }}
      transform="translate(-1.609)"
    />
  </svg>
);

const LinkedinSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.68"
    height="12.679"
    viewBox="0 0 12.68 12.679"
  >
    <path
      d="M2.838 12.68H.209V4.215h2.629zM1.522 3.06a1.53 1.53 0 1 1 1.523-1.537A1.535 1.535 0 0 1 1.522 3.06zm11.155 9.62h-2.623V8.559c0-.982-.02-2.242-1.367-2.242-1.367 0-1.576 1.067-1.576 2.171v4.192H4.485V4.215h2.521v1.154h.037A2.762 2.762 0 0 1 9.53 4c2.66 0 3.15 1.752 3.15 4.028v4.65z"
      data-name="Icon awesome-linkedin-in"
      style={{ fill: '#fff' }}
      transform="translate(0 -.001)"
    />
  </svg>
);

const FacebookIcon = (props) => <Icon component={FacebookSvg} {...props} />;
const LinkedinIcon = (props) => <Icon component={LinkedinSvg} {...props} />;

const text = encodeURIComponent(
  'Click my link 🤳 for a chance to win amazing discounts!'
);
const shareparams = 'menubar=no,toolbar=no,status=no,width=570,height=570';
const hashtags = 'gamification,ecommerce';
let whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
  "I'm glad to share this game with you! you can win amazing discounts 🤩 "
)}`;

interface Props {
  url: string;
}

export const ShareList: FunctionComponent<Props> = ({ url }) => {
  const shareHandler = useCallback((platform: string) => {
    let shareUrl: string;
    switch (platform) {
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}&hashtags=${hashtags}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;
        break;
      case 'facebook':
        shareUrl = `http://www.facebook.com/sharer/sharer.phpu=${url}&hashtag=${hashtags}&t=${text}`;
        break;
      case 'whatsapp':
        shareUrl = `${whatsappUrl}${url}`;
    }
    window.open(shareUrl, 'NewWindow', shareparams);
  }, []);

  return (
    <ul className="flex flex-wrap justify-center gap-4 share-widget-icons">
      <ShareButtonItem
        onClickHandler={() => shareHandler('facebook')}
        icon={FacebookIcon}
      />
      <ShareButtonItem
        onClickHandler={() => shareHandler('twitter')}
        icon={TwitterOutlined}
      />
      <ShareButtonItem
        onClickHandler={() => shareHandler('linkedin')}
        icon={LinkedinIcon}
      />
      <ShareButtonItem
        onClickHandler={() => shareHandler('whatsapp')}
        icon={WhatsAppOutlined}
      />
    </ul>
  );
};

export default ShareList;
