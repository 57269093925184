import React, { FunctionComponent } from 'react';

interface Props {
  template: number;
  backgroundColor: string;
  logo: string;
}

export const CouponBody: FunctionComponent<Props> = ({
  template,
  backgroundColor,
  logo,
}) => (
  <>
    {template === 0 && (
      <path
        style={{ fill: backgroundColor }}
        d={`M933,1${
          logo ? '270' : '182'
        }.6c-0.1-7.4-4.3-14.2-11-17.4c-11.8-6.1-16.5-20.6-10.5-32.4c2.3-4.5,6-8.2,10.5-10.5
	c6.7-3.3,10.9-10,11-17.4l0,0c-0.1-7.4-4.3-14.2-11-17.4c-11.8-6.1-16.5-20.6-10.4-32.4c2.3-4.5,6-8.1,10.4-10.4
	c6.7-3.3,10.9-10,11-17.4l0,0c-0.1-7.4-4.3-14.2-11-17.4c-11.8-6.1-16.5-20.6-10.5-32.4c2.3-4.5,6-8.2,10.5-10.5
	c6.6-3.3,10.9-10,11-17.4l0,0c-0.1-7.4-4.3-14.2-11-17.4c-11.8-6.1-16.5-20.6-10.5-32.4c2.3-4.5,6-8.2,10.5-10.5
	c6.7-3.3,10.9-10,11-17.4l0,0c-0.1-7.4-4.3-14.2-11-17.4c-11.8-6.1-16.5-20.6-10.5-32.4c2.3-4.5,6-8.2,10.5-10.5
	c6.7-3.3,10.9-10,11-17.4l0,0c-0.1-7.4-4.3-14.2-11-17.4c-11.8-6.1-16.5-20.6-10.5-32.4c2-3.9,5-7.1,8.6-9.4
	c7.9-4.9,12.8-13.4,12.8-22.7l0,0c0-4-3.2-7.2-7.2-7.2c0,0,0,0,0,0H158.5c-6.3,0-11.4,5.1-11.5,11.4l0,0l0,0
	c0.1,7.4,4.3,14.2,11,17.4c11.8,6.1,16.5,20.6,10.4,32.4c-2.3,4.5-6,8.1-10.4,10.4c-6.7,3.3-10.9,10-11,17.4l0,0
	c0.1,7.4,4.3,14.2,11,17.4c11.8,6.1,16.5,20.6,10.5,32.4c-2.3,4.5-6,8.2-10.5,10.5c-6.7,3.3-10.9,10-11,17.4l0,0
	c0.1,7.4,4.3,14.2,11,17.4c11.8,6.1,16.5,20.6,10.5,32.4c-2.3,4.5-6,8.2-10.5,10.5c-6.7,3.3-10.9,10-11,17.5l0,0
	c0.1,7.4,4.3,14.2,11,17.5c11.8,6.1,16.5,20.6,10.5,32.4c-2.3,4.5-6,8.2-10.5,10.5c-6.7,3.3-10.9,10-11,17.4l0,0
	c0.1,7.4,4.3,14.2,11,17.4c11.8,6.1,16.5,20.6,10.4,32.4c-2.3,4.5-6,8.1-10.4,10.4c-6.6,3.3-10.9,10-11,17.4l0,0
	c0.1,7.4,4.3,14.2,11,17.4c11.8,6.1,16.5,20.6,10.5,32.4c-2.3,4.5-6,8.2-10.5,10.5c-6.7,3.3-10.9,10-11,17.4l0,0
	c0,6.3,5.1,11.4,11.4,11.4c0,0,0,0,0,0h767.3c4,0,7.2-3.2,7.2-7.2L933,1182.6z`}
      />
    )}
    {template === 1 && (
      <g>
        <circle
          cx="551.4"
          cy={logo ? '1600' : '1400'}
          r="800"
          style={{ fill: backgroundColor }}
        />
      </g>
    )}
  </>
);

export default CouponBody;
