import { BaseRepository } from './base.repository';
import { Subdomain } from '@wiply/repository';

export class SubdomainRepository extends BaseRepository<Subdomain> {
  constructor() {
    super('subdomains');
  }

  async getByGameId(gameId: string) {
    const snapshot = await this.collectionRef
      .where('gameId', '==', gameId)
      .get();
    return snapshot.docs.map((doc) => doc.data())[0];
  }
}
