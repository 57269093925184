import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { Base } from '@wiply/repository';

export class BaseRepository<T extends Base> {
  private firestore: firebase.firestore.Firestore;
  public collectionRef: firebase.firestore.CollectionReference<T>;
  private storageRef = firebase.storage().ref();

  constructor(private collectionPath?: string) {
    this.firestore = firebase.firestore();
    if (collectionPath) {
      this.collectionRef = this.firestore
        .collection(collectionPath)
        .withConverter(
          this.baseConverter()
        ) as firebase.firestore.CollectionReference<T>;
    }
  }

  baseConverter = <T>() => ({
    toFirestore: (data: T) => data,
    fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot<T>) => {
      return { id: snap.id, ...snap.data() };
    },
  });

  async create(id: string, entity: T) {
    try {
      const exist = await this.fetch(id);
      if (exist) id = `${id}-${this.getRandomString()}`;
      if (id) {
        this.collectionRef.doc(id).set(entity, { merge: true });
      } else {
        this.collectionRef.add(entity);
      }
      return id;
    } catch (e) {
      console.log(e);
    }
  }

  async update(id: string, entity: Partial<T>) {
    try {
      const collection = this.collectionRef.doc(id);
      return collection.update(entity);
    } catch (e) {
      console.log(e);
    }
  }

  async remove(id: string) {
    return this.collectionRef.doc(id).delete();
  }

  async increment(id: string, field: any, n: number) {
    return this.update(id, {
      [field]: [field]
        ? (firebase.firestore.FieldValue.increment(n) as any)
        : 1,
    } as any);
  }

  async fetch(entityId: string) {
    try {
    const res = await this.collectionRef.doc(entityId).get();
    return res?.data();
    } catch(e) {
      console.log(e)
    }
  }

  subscribe(entityId: string, onChange: (entity: T) => void) {
    return this.collectionRef
      .doc(entityId)
      .onSnapshot((e) => onChange(e.data() || null));
  }

  async fetchAll(filter: Partial<T> = {}) {
    const res = await this.collectionRef.get();
    return res?.docs?.map((d) => d?.data()) || [];
  }

  subscribeAll(
    onChange: (entity: T[]) => void,
    filter?: { condition: string; value: string }
  ) {
    const collection = filter
      ? this.collectionRef.where(filter.condition, '==', filter.value)
      : this.collectionRef;
    return collection.onSnapshot((res) =>
      onChange(res.docs.map((d) => d.data()))
    );
  }

  async uploadFile(file: File, path: string) {
    const randomId = Math.random().toString(36).substring(2);
    const { ref } = await this.storageRef
      .child(`${path}/images/${randomId}.${file.name.split('.').pop()}`)
      .put(file);
    const url: string = await ref.getDownloadURL();
    return url;
  }

  getRandomString() {
    return Math.floor(Math.random() * 16777215).toString(16);
  }
}
