import React, { FunctionComponent, memo } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { DescriptionField } from './EditImageCard';

interface Props {
  title?: string;
  description?: DescriptionField;
  imagePreview: React.ReactNode;
}

const EditImageCardDescription: FunctionComponent<Props> = memo(
  ({ title, description, imagePreview }) => {
    const { type, size, text, isOptional, aspectRatio } = description || {};

    let comments = null;
    if (text) {
      comments = (
        <div className="ml-2">
          <Popover
            placement="left"
            content={<p className="text-gray text-center">{text}</p>}
          >
            <InfoCircleOutlined className="text-violet cursor-pointer" />
          </Popover>
        </div>
      );
    }
    return (
      <div className="my-2 ">
        <div className="flex items-center justify-center">
          <h3 className={`mt-2 text-md font-bold text-left`}>
            {title ? title : null}{' '}
            {title ? !isOptional && <span className="text-red">*</span> : null}
          </h3>
          {comments}
        </div>
        <div className="flex flex-col justify-center">
          {imagePreview}
          {description ? (
            <ul className="text-xs self-center">
              {type && <li>Type: {type}</li>}
              {size && <li>Recommended size: {size}</li>}
              {size && aspectRatio && (
                <li>Aspect Ratio: {aspectRatio.toFixed(2)}</li>
              )}
            </ul>
          ) : null}
        </div>
      </div>
    );
  }
);

export default EditImageCardDescription;
