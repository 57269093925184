import React, { FunctionComponent, useState } from 'react';
import { EyeOutlined } from '@ant-design/icons';

interface Props {
  title?: string;
  icon: React.FunctionComponent;
  onClickHandler?: () => void;
  color?: string;
}

const CardActionIcon: FunctionComponent<Props> = ({
  onClickHandler,
  title,
  icon,
  color,
}) => {
  let Icon = icon || EyeOutlined;
  return (
    <span
      onClick={onClickHandler}
      className={`flex items-center cursor-pointer gap-1 hover:text-${
        color || 'white'
      } text-${color || 'gray'} py-3 px-4 w-full`}
    >
      <Icon className="p-2 mr-2 flex items-center justify-center" />
      {title && <span className="text-sm hidden lg:flex">{title}</span>}
    </span>
  );
};

export default CardActionIcon;
