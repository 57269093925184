import React, { FunctionComponent } from 'react';
import { GameTypeEnum } from '@wiply/utils';
import { ClientGame } from '@wiply/repository';
import GameDesignAssetsBasketball from './Basketball';
import GameDesignAssetsBricks from './Bricks';
import GameDesignAssetsDropit from './Dropit';
import GameDesignAssetsCups from './Cups';
import GameDesignAssetsHockey from './Hockey';
import GameDesignAssetsNinja from './Ninja';
import GameDesignAssetsWheel from './Wheel';
import GameDesignAssetsPuzzle from './Puzzle';
import GameDesignAssetsMemory from './Memory';
import GameDesignAssetsQuiz from './Quiz';
import GameDesignAssetsWordle from './Wordle';
import GameDesignAssetsTower from './Tower';
import GameDesignAssetsGems from './Gems';

export interface GameDesignAssetsProps {
  game: ClientGame;
  commonProps?: any;
  onChangeHandler: (field: any) => void;
}

export const GameDesignAssets: FunctionComponent<GameDesignAssetsProps> = ({
  game,
  commonProps,
  onChangeHandler,
}) => {
  const props = {
    game,
    commonProps,
    onChangeHandler,
  };

  switch (game.type) {
    case GameTypeEnum.Bricks:
      return <GameDesignAssetsBricks {...props} />;
    case GameTypeEnum.Cups:
      return <GameDesignAssetsCups {...props} />;
    case GameTypeEnum.DropIt:
      return <GameDesignAssetsDropit {...props} />;
    case GameTypeEnum.Hockey:
      return <GameDesignAssetsHockey {...props} />;
    case GameTypeEnum.Ninja:
      return <GameDesignAssetsNinja {...props} />;
    case GameTypeEnum.Puzzle:
      return <GameDesignAssetsPuzzle {...props} />;
    case GameTypeEnum.Wheel:
      return <GameDesignAssetsWheel {...props} />;
    case GameTypeEnum.Memory:
      return <GameDesignAssetsMemory {...props} />;
    case GameTypeEnum.Quiz:
      return <GameDesignAssetsQuiz {...props} />;
    case GameTypeEnum.Wordle:
      return <GameDesignAssetsWordle {...props} />;
    case GameTypeEnum.Tower:
      return <GameDesignAssetsTower {...props} />;
    case GameTypeEnum.Gems:
      return <GameDesignAssetsGems {...props} />;
    default:
      return <GameDesignAssetsBasketball {...props} />;
  }
};

export default GameDesignAssets;
