import React, { FunctionComponent, useMemo } from 'react';
import EditImageCard from '../GameDesignCard/EditImageCard/EditImageCard';
import { cupsGlb, cupsBoard } from '@wiply/utils';
import { GameDesignAssetsProps } from '.';
import EditColorCard from '../GameDesignCard/EditColorCard';
import { EditColorAction } from '../GameDesignCard/EditColorAction';
import EditOptionsCard from '../GameDesignCard/EditOptionsCard';

export const GameDesignAssetsCups: FunctionComponent<GameDesignAssetsProps> = ({
  game,
  commonProps,
  onChangeHandler,
}) => {
  const extraAction = useMemo(() => {
    return (
      <>
        <EditColorAction
          title="Change Color"
          onChangeHandler={(field) => {
            let colors = [...game.options.colors];
            colors[0] = field;
            onChangeHandler({ colors });
          }}
          defaultValue={game.options.colors[0]}
        />
      </>
    );
  }, [game]);

  const extraAction2 = useMemo(() => {
    return (
      <>
        <EditColorAction
          title="Change Color"
          onChangeHandler={(field) => {
            let colors = [...game.options.colors];
            colors[1] = field;
            onChangeHandler({ colors });
          }}
          defaultValue={game.options.colors[1]}
        />
      </>
    );
  }, [game]);

  return (
    <>
      {/* CUPS BOARD TEXTURE */}
      <EditImageCard
        {...commonProps}
        title="Board Image"
        description={{
          type: 'PNG',
          size: '1920x1148',
          aspectRatio: Number(1920 / 1148),
        }}
        accept="image/png"
        defaultValue={game.options.cupsOptions?.boardTexture || cupsBoard[0]}
        onChange={(field: string) =>
          onChangeHandler({
            cupsOptions: {
              ...game.options.cupsOptions,
              boardTexture: field,
            },
          })
        }
        defaultImagesToChoose={cupsBoard}
        extraActions={extraAction}
      />
      <EditOptionsCard
        {...commonProps}
        title="Choose Difficulty"
        value1="easy"
        value2="medium"
        value3="hard"
        defaultValue={game.options.cupsOptions?.level || 'medium'}
        onChange={(field: string) =>
          onChangeHandler({
            cupsOptions: {
              ...game.options.cupsOptions,
              level: field,
            },
          })
        }
      />

      {/* CUPS TEXTURE */}
      <EditImageCard
        {...commonProps}
        title="Cups Image"
        description={{
          type: 'PNG',
          size: '1920x1148',
          aspectRatio: Number(1920 / 1148),
          text: (
            <span>
              The ball image is a flat image that<br></br>
              covers the 3d Cups element
            </span>
          ),
        }}
        accept="image/png"
        defaultValue={game.options.cupsOptions?.cupsTexture || cupsBoard[0]}
        onChange={(field: string) =>
          onChangeHandler({
            cupsOptions: {
              ...game.options.cupsOptions,
              cupsTexture: field,
            },
          })
        }
        defaultImagesToChoose={cupsBoard}
        extraActions={extraAction2}
      />

      {/* PRIZE GLB TEXTURE */}
      <EditImageCard
        {...commonProps}
        title="PRIZE GLB 3D Image"
        description={{
          type: 'GLB',
          size: 'Size x: 1.3-2.3 . Size y: 2.0 - 3.9. Size z: 1.1 - 1.3',
          text: (
            <span>
              To test If you created the right size <br></br>
              (you can you blender or download a<br></br>
              ready glb file) you can upload the<br></br>
              image to: https://3dviewer.net/<br></br> Shop to buy images in
              <br></br>
              cheap prices:<br></br>
              https://www.cgtrader.com
            </span>
          ),
        }}
        accept="glb"
        defaultValue={game.options.cupsOptions?.modelGlb || cupsGlb[1]}
        onChange={(field: string) =>
          onChangeHandler({
            cupsOptions: {
              ...game.options.cupsOptions,
              modelGlb: field,
            },
          })
        }
        defaultImagesToChoose={cupsGlb}
      />
    </>
  );
};

export default GameDesignAssetsCups;
