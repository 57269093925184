import React, { FunctionComponent, useMemo, useState } from 'react';
import { GameDesignAssetsProps } from '.';
import EditColorCard from '../GameDesignCard/EditColorCard';
import EditTextCard from '../GameDesignCard/EditTextCard';
import EditOptionsCard from '../GameDesignCard/EditOptionsCard';
import { towerStage, towerBlock, towerHook } from '@wiply/utils';
import EditImageCard from '../GameDesignCard/EditImageCard/EditImageCard';
import CardItem from '../shared/CardItem';
import CardActionItem from '../shared/CardItem/CardActionItem';
import { CheckSquareOutlined, ReloadOutlined } from '@ant-design/icons';
import { Card, Radio, Space } from 'antd';
import rgbHex from 'rgb-hex';

interface GradientProps {
  onChangeHandler: (field: any) => void;
  gameOptions: any;
}

const ChooseGradient: FunctionComponent<GradientProps> = ({
  onChangeHandler,
  gameOptions,
}) => {
  const [value, setValue] = useState(0);

  const onChangeRadio = (e) => {
    setValue(e.target.value);
  };

  console.log(gameOptions);

  const value1 = 0;
  const value2 = 1;
  const gradientLayers = [0, 1, 2, 3, 4, 5, 6];

  const radioArray = gradientLayers.map((val, index) => (
    <Radio.Button key={`Radio ${index}`} value={val}>
      {val + 1}
    </Radio.Button>
  ));

  function convert(rgb) {
    try {
      console.log(rgb);
      return '#' + rgbHex(rgb[0], rgb[1], rgb[2]);
    } catch (err) {
      console.log(err);
    }
  }

  const colorPickers = gradientLayers.map((_, index) => (
    <EditColorCard
      key={index}
      returnRGB={true}
      hasAlpha={true}
      title="Cards background color"
      defaultValue={
        gameOptions?.gradientColors[index]
          ? convert(gameOptions?.gradientColors[index])
          : '#FFF'
      }
      onChange={(field: any) => {
        console.log(index, field);
        const temp = gameOptions?.gradientColors;
        temp[index] = [field.r, field.g, field.b];
        onChangeHandler({
          towerOptions: {
            ...gameOptions,
            gradientColors: temp,
          },
        });
      }}
    />
  ));

  return (
    <>
      <Card title={'Select 7 colors to add to your background'}>
        <div>
          <Radio.Group onChange={onChangeRadio} value={value} defaultValue={0}>
            <Space direction="horizontal">{radioArray}</Space>
          </Radio.Group>
          {colorPickers.filter((colorPicker) => value == colorPicker.key)}
        </div>
      </Card>
    </>
  );
};

export const GameDesignAssetsTower: FunctionComponent<
  GameDesignAssetsProps
> = ({ game, commonProps, onChangeHandler }) => {
  return (
    <div className="grid grid-cols-1 gap-5">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <EditOptionsCard
            {...commonProps}
            title="Number of lives"
            value1={1}
            value2={2}
            value3={3}
            value4={4}
            value5={5}
            defaultValue={game.options.towerOptions.numLives || 3}
            onChange={(field: number) =>
              onChangeHandler({
                towerOptions: {
                  ...game.options.towerOptions,
                  numLives: field,
                },
              })
            }
          />
          <EditOptionsCard
            {...commonProps}
            title="Number of coupons"
            value1={1}
            value2={2}
            defaultValue={game.options.towerOptions.numCoupons || 1}
            onChange={(field: number) =>
              onChangeHandler({
                towerOptions: {
                  ...game.options.towerOptions,
                  numCoupons: field,
                },
              })
            }
          />
        </div>
        <ChooseGradient
          onChangeHandler={onChangeHandler}
          gameOptions={game.options.towerOptions}
        />
      </div>

      <EditTextCard
        type="text"
        {...commonProps}
        title="Number of floors to win first coupon"
        onSave={(fields: any) => {
          onChangeHandler({
            towerOptions: {
              ...game.options.towerOptions,
              floorsForFirstCoupon: parseInt(fields.floorsForFirstCoupon),
            },
          });
        }}
        items={{
          floorsForFirstCoupon:
            game.options?.towerOptions?.floorsForFirstCoupon || 10,
        }}
        formItems={[
          {
            name: 'floorsForFirstCoupon',
            title: '',
          },
        ]}
      />
      {game.options.towerOptions.numCoupons === 2 ? (
        <EditTextCard
          type="text"
          {...commonProps}
          title="Number of floors to win second coupon"
          onSave={(fields: any) => {
            onChangeHandler({
              towerOptions: {
                ...game.options.towerOptions,
                floorsForSecondCoupon: parseInt(fields.floorsForSecondCoupon),
              },
            });
          }}
          items={{
            floorsForSecondCoupon:
              game.options?.towerOptions?.floorsForSecondCoupon || 20,
          }}
          formItems={[
            {
              name: 'floorsForSecondCoupon',
              title: '',
            },
          ]}
        />
      ) : null}
      <EditImageCard
        {...commonProps}
        title="Select Stage"
        description={{
          type: 'PNG',
          size: '750x1050',
          aspectRatio: Number(750 / 1050),
          text: (
            <span>
              A rect container for the game <br></br>is part of this svg and the
              <br></br> id name has to be: <strong>"game-container"</strong>
            </span>
          ),
        }}
        accept="image/png"
        defaultValue={game.options.towerOptions.stage}
        onChange={(field: string) => {
          onChangeHandler({
            towerOptions: {
              ...game.options.towerOptions,
              stage: field,
            },
          });
        }}
        defaultImagesToChoose={towerStage}
        previewCss=" max-h- text-xs"
      />
      <EditImageCard
        {...commonProps}
        title="Select Block"
        description={{
          type: 'PNG',
          size: '188x134',
          aspectRatio: Number(188 / 134),
          text: (
            <span>
              A rect container for the game <br></br>is part of this svg and the
              <br></br> id name has to be: <strong>"game-container"</strong>
            </span>
          ),
        }}
        accept="image/png"
        defaultValue={game.options.towerOptions.block}
        onChange={(field: string) => {
          onChangeHandler({
            towerOptions: {
              ...game.options.towerOptions,
              block: field,
            },
          });
        }}
        defaultImagesToChoose={towerBlock}
        previewCss=" max-h- text-xs"
      />
      <EditImageCard
        {...commonProps}
        title="Select Roped Block"
        description={{
          type: 'PNG',
          size: '188x134',
          aspectRatio: Number(188 / 134),
          text: (
            <span>
              A rect container for the game <br></br>is part of this svg and the
              <br></br> id name has to be: <strong>"game-container"</strong>
            </span>
          ),
        }}
        accept="image/png"
        defaultValue={game.options.towerOptions.ropedBlock}
        onChange={(field: string) => {
          onChangeHandler({
            towerOptions: {
              ...game.options.towerOptions,
              ropedBlock: field,
            },
          });
        }}
        defaultImagesToChoose={towerBlock}
        previewCss=" max-h- text-xs"
      />
      <EditImageCard
        {...commonProps}
        title="Select Perfect Block"
        description={{
          type: 'PNG',
          size: '188x134',
          aspectRatio: Number(188 / 134),
          text: (
            <span>
              A rect container for the game <br></br>is part of this svg and the
              <br></br> id name has to be: <strong>"game-container"</strong>
            </span>
          ),
        }}
        accept="image/png"
        defaultValue={game.options.towerOptions.perfectBlock}
        onChange={(field: string) => {
          onChangeHandler({
            towerOptions: {
              ...game.options.towerOptions,
              perfectBlock: field,
            },
          });
        }}
        defaultImagesToChoose={towerBlock}
        previewCss=" max-h- text-xs"
      />
      <EditImageCard
        {...commonProps}
        title="Select Hook"
        description={{
          type: 'PNG',
          size: '50x507',
          aspectRatio: Number(50 / 507),
          text: (
            <span>
              A rect container for the game <br></br>is part of this svg and the
              <br></br> id name has to be: <strong>"game-container"</strong>
            </span>
          ),
        }}
        accept="image/png"
        defaultValue={game.options.towerOptions.hook}
        onChange={(field: string) => {
          onChangeHandler({
            towerOptions: {
              ...game.options.towerOptions,
              hook: field,
            },
          });
        }}
        defaultImagesToChoose={towerHook}
        previewCss=" max-h- text-xs"
      />
    </div>
  );
};

export default GameDesignAssetsTower;
