import React, {
  useContext,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import GamesContext from '../../context/games';
import { useNavigate } from 'react-router-dom';
import { gamesList } from '@wiply/utils';
import NewGame from './NewGame';
import SearchBar from './../shared/SearchBar';
import Title from './../shared/Title';
import Spinner from './../shared/Spinner';
import GameCard from './../games/GameCard';
import ClientsContext from '../../context/clients';

interface Props {
  clientId?: string;
}

const GameList: FunctionComponent<Props> = ({ clientId }) => {
  const { games, removeGame, getGames } = useContext(GamesContext);
  const navigate = useNavigate();
  const [filteredGame, setFiteredGame] = useState<string>(null);
  const { updateGamesAmount } = useContext(ClientsContext);

  useEffect(() => {
    const unsubscribe = getGames(clientId);
    return () => unsubscribe();
  }, []);

  const removeGameHandler = (id) => {
    removeGame(id);
    updateGamesAmount(clientId, false)
  }

  if (!games) return <Spinner />;

  const filteredGamesList = filteredGame
    ? games.filter(({ id }) => id === filteredGame)
    : games;

  return (
    <>
      <div className="flex items-center justify-around px-2 w-full">
        <NewGame
          games={gamesList}
          hasGames={games.length > 0}
          clientId={clientId}
        />
      </div>

      {games.length > 0 && (
        <>
          {clientId ? (
            <div className="mt-12">
              <Title text="My Games" />
            </div>
          ) : (
            <div className="flex items-center justify-center my-8">
              <SearchBar
                values={games}
                onSelect={(value: string) => setFiteredGame(value)}
                onReset={() => setFiteredGame(null)}
              />
            </div>
          )}
          <div
            className={`my-8 ${
              games.length > 2
                ? 'flex flex-wrap justify-startss'
                : 'flex flex-col md:flex-row justify-start'
            } gap-x-6 gap-y-9`}
          >
            {filteredGamesList.map((game) => (
              <GameCard
                game={game}
                navigate={navigate}
                removeGame={removeGameHandler}
                key={game.id}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default GameList;
