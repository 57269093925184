import { environment } from '@env/environment';
import firebase from 'firebase/app';
import 'firebase/auth';

// const PERSISTENCE = environment.development ? firebase.auth.Auth.Persistence.NONE : firebase.auth.Auth.Persistence.LOCAL
const PERSISTENCE = firebase.auth.Auth.Persistence.SESSION;

export class AuthService {
  private auth: firebase.auth.Auth;
  user: firebase.User;

  constructor() {
    this.auth = firebase.auth();
    this.auth.setPersistence(PERSISTENCE);
    this.auth.onAuthStateChanged((user) => {
      this.user = user;
    });
  }

  async signInAnonymously() {
    try {
      const { user } = await this.auth.signInAnonymously();
      console.log(user);
      return user;
    } catch (e) {
      console.log(e);
    }
  }

  async signInWithToken(token) {
    try {
      const { user } = await this.auth.signInWithCustomToken(token);
      console.log(user);
      return user;
    } catch (e) {
      console.log(e);
    }
  }

  async signInWithEmailAndPassword(email, password) {
    try {
      const { user } = await this.auth.signInWithEmailAndPassword(
        email,
        password
      );
      return user;
    } catch (e) {
      return e;
    }
  }

  async resetEmail(email: string) {
    try {
      await this.auth.sendPasswordResetEmail(email, {
        url: environment.appsUrls.designerDashboard,
      });
      return { status: 200 };
    } catch (error) {
      return { status: 400 };
    }
  }

  async signinWithSocial(provider, rememberMe = true) {
    const persistence = rememberMe
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;

    await firebase.auth().setPersistence(persistence);

    const providers = {
      google: firebase.auth.GoogleAuthProvider,
      facebook: firebase.auth.FacebookAuthProvider,
      twitter: firebase.auth.TwitterAuthProvider,
    };

    return firebase.auth().signInWithPopup(new providers[provider]());
  }

  async logOut() {
    try {
      return this.auth.signOut();
    } catch (e) {
      console.log(e);
    }
  }

  get isLoggedIn() {
    return !!this.user;
  }

  onAuthStateChange(callback) {
    return this.auth.onAuthStateChanged((user) => {
      if (user) {
        callback(this.user);
      } else {
        callback(null);
      }
    });
  }
}
