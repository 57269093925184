import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Upload, message, Tooltip } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

interface Props {
  updateImage: (file) => Promise<void>;
}

const couponNote = (
  <div>
    <p>
      The coupon must have a button link with the ID name of: 'claim- button'
    </p>
    <p> Type: SVG </p>
    <p>Size: 1080X1920</p>
    <p> Aspect Ratio: {1080 / 1920}</p>
  </div>
);

const UploadCoupon: FunctionComponent<Props> = ({ updateImage }) => {
  return (
    <div className="py-2 flex items-center justify-center">
      <Upload
        showUploadList={false}
        customRequest={(file) => updateImage(file)}
        accept="image/svg+xml"
      >
        <Tooltip title={couponNote} placement="bottom">
          <div className="flex flex-col justify-center items-center">
            <UploadOutlined className="text-white p-2 gradient shadow-3xl rounded-full h-14 w-14 flex items-center justify-center" />
            <span className="text-violet my-2">Upload your own Coupon</span>
          </div>
        </Tooltip>
      </Upload>
    </div>
  );
};

export default UploadCoupon;
