import React, { FunctionComponent, useMemo } from 'react';
import EditImageCard from '../GameDesignCard/EditImageCard/EditImageCard';
import { brandLogoImages, defaultDropitCouponsBottom, defaultDropitSvg } from '@wiply/utils';
import { GameDesignAssetsProps } from '.';
import EditPrizesCard from '../GameDesignCard/EditPrizesCard';
import EditColorCard from '../GameDesignCard/EditColorCard';
import { EditColorAction } from '../GameDesignCard/EditColorAction';

export const GameDesignAssetsDropit: FunctionComponent<GameDesignAssetsProps> = ({
  game,
  commonProps,
  onChangeHandler,
}) => {
  const extraAction = useMemo(() => {
    return (
      <>
        <EditColorAction
          title="Change Color"
          onChangeHandler={(field) => {
            let colors = [...game.options.colors];
            colors[0] = field;
            onChangeHandler({ colors });
          }}
          defaultValue={game.options.colors[0]}
        />
      </>
    );
  }, [game]);

  const extraAction2 = useMemo(() => {
    return (
      <>
        <EditColorAction
          title="Change Color"
          onChangeHandler={(field) => {
            let colors = [...game.options.colors];
            colors[1] = field;
            onChangeHandler({ colors });
          }}
          defaultValue={game.options.colors[1]}
        />
      </>
    );
  }, [game]);
  return (
    <>
      {/* Dropit Logo */}
      <EditImageCard
        {...commonProps}
        title="Add your Brand Logo"
        description={{
          type: 'PNG',
          size: 'Width: 250px',
        }}
        accept="image/png"
        defaultValue={game.options.logoImgUrl}
        onChange={(field: string) => onChangeHandler({ logoImgUrl: field })}
        defaultImagesToChoose={brandLogoImages}
      />
      {/* Dropit Game Background (Desktop) */}
      <EditImageCard
        {...commonProps}
        title="Choose Game Shape (Desktop)"
        description={{
          type: 'SVG',
          size: 'Auto-Sizing(design dependent). Example: (1200X628)',
          aspectRatio: Number(1200 / 628),
          text: (
            <span>
              For the mobile background<br></br>
              you don’t need to put<br></br>
              anything it will stretch to the<br></br>
              whole screen
            </span>
          ),
        }}
        accept="image/svg+xml"
        defaultValue={game.options.dropItOptions.bgImgUrl}
        onChange={(field: string) =>
          onChangeHandler({
            dropItOptions: {
              ...game.options.dropItOptions,
              bgImgUrl: field,
            },
          })
        }
        defaultImagesToChoose={defaultDropitSvg}
      />

<EditImageCard
        {...commonProps}
        title="Ball color"
        description={{}}
        disableUpload={true}
        removeDelete={true}
        //deleteGallery={true}
        accept="image/png, image/jpg, image/jpeg"
        defaultValue="https://res.cloudinary.com/shulgirit/image/upload/v1639486760/wiply/Platform%20Default%20Images/dropit/ball.jpg"
        onChange={(field: string) =>
          onChangeHandler({
            basketballOptions: {
              ...game.options.basketballOptions,
              boardImg: field, 
           },
          })
        }
        
        extraActions={extraAction}
        previewCss="max-h-80"
      />

<EditImageCard
        {...commonProps}
        title="Obstacle color"
        description={{}}
        disableUpload={true}
        removeDelete={true}
        //deleteGallery={false}
        accept="image/png, image/jpg, image/jpeg"
        defaultValue="https://res.cloudinary.com/shulgirit/image/upload/v1639486759/wiply/Platform%20Default%20Images/dropit/obstacle.jpg"
        onChange={(field: string) =>
          onChangeHandler({
            basketballOptions: {
              ...game.options.basketballOptions,
              boardImg: field,
            },
          })
        }
        extraActions={extraAction2}
        previewCss="max-h-32"
      />

     

      <EditPrizesCard
        game={game}
        defaultImages={defaultDropitCouponsBottom}
        commonProps={commonProps}
      />
    </>
  );
};

export default GameDesignAssetsDropit;
