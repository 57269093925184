import React, { FunctionComponent, useContext, useState } from 'react';
import { environment } from '@env/environment';
import SVG from 'react-inlinesvg';
import { FetchedContentCard } from './../shared/CardItem/FetchedContentCard';
import GameActions from './GameActions';
import CardItem from '../shared/CardItem/index';
import { ClientGame } from '@wiply/repository';
import SubdomainContext from '../../context/subdomain';
import ShareLink from '../ShareLink';
import EditGameSettingsForm from '../EditGameSettingsForm';
import { Modal } from '../shared/Modal';
import EditFilled from '@ant-design/icons/lib/icons/EditFilled';

interface Props {
  removeGame: any;
  navigate: any;
  game: ClientGame;
}

const GameCard: FunctionComponent<Props> = ({ navigate, removeGame, game }) => {
  const { subdomains } = useContext(SubdomainContext);
  const subdomain = subdomains
    ? subdomains.filter(({ gameId }) => game.id === gameId)[0]?.id || game.id
    : null;
  const previewLink = `https://${subdomain}.${environment.appsUrls.clientNext}`;
  const [showEditModal, setShowEditModal] = useState(false);
  return (
    <CardItem
      actions={
        <GameActions
          navigate={navigate}
          removeGame={removeGame}
          id={game.id}
          previewLink={previewLink}
        />
      }
      bottomContent={
        <ShareLink previewLink={previewLink} wrapperClassName="gap-2 -ml-2" />
      }
    >
      <>
        <h3 className="text-lg text-gray-dark font-bold text-center w-64 md:w-32 flex justify-center items-center flex-wrap">
          <span
            className="cursor-pointer text-gray"
            style={{ fontSize: '0.6em' }}
            onClick={() => setShowEditModal(true)}
          >
            <EditFilled className="mr-2" />
          </span>
          <span>
            {game.name?.length > 10
              ? `${game.name?.substring(0, 10)}...`
              : game?.name}
          </span>
        </h3>
        <SVG
          src={`https://res.cloudinary.com/shulgirit/image/upload/v1640099101/wiply/Platform%20Default%20Images/games/${game.type}.svg`}
          className="m-auto py-2 w-64 md:w-28 h-28 cursor-pointer"
          onClick={() => navigate(`/${game.id}`)}
          loader={
            <img
              className="h-20 m-auto py-4 w-64 md:w-28 h-28"
              src="https://res.cloudinary.com/shulgirit/image/upload/v1635766453/wiply/Platform%20Default%20Images/1x1.png"
            />
          }
        />
        <FetchedContentCard
          apiUrl={`${environment.api}events?gameId=${game.id}&startDate=2021-11-03&endDate=today`}
          endpointInstance="gameView"
          warning={game?.exceededLimit || false}
        />
      </>
      <Modal
        title={
          <>
            <span className="block font-medium">Edit Game Name</span>
          </>
        }
        handleCancel={() => setShowEditModal(false)}
        isModalVisible={showEditModal}
      >
        <EditGameSettingsForm
          game={game}
          handleCancel={() => setShowEditModal(false)}
        />
      </Modal>
    </CardItem>
  );
};

export default GameCard;
