import React, { FunctionComponent, useContext, useState } from 'react';
import { Upload } from 'antd';
import GamesContext from '../../context/games';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import Button from './../shared/Button';

export const GameDataTab: FunctionComponent = () => {
  const { exportJSON, importJSON } = useContext(GamesContext);

  const uploadHandler = ({ file }) => importJSON(file);

  return (
    <div className="my-8 inline-grid grid-cols-2 gap-4">
      <Upload
        name="import"
        maxCount={1}
        accept="application/json"
        showUploadList={false}
        customRequest={uploadHandler}
      >
        <Button icon={<DownloadOutlined />} title="Import GAME" />
      </Upload>

      <Button
        className=""
        onClick={exportJSON}
        title="Export GAME"
        icon={<UploadOutlined />}
      />
    </div>
  );
};

export default GameDataTab;
