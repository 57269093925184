import firebase from 'firebase/app';
import 'firebase/auth';
import { environment } from '@env/environment';

if (!firebase.apps.length) {
  firebase.initializeApp(environment.firebaseConfig);
}

export class UserRepository {
  private fAuth: firebase.auth.Auth;
  constructor() {
    this.fAuth = firebase.auth();
  }

  async signInAnonymously() {
    try {
      const { user } = await this.fAuth.signInAnonymously();
      return user;
    } catch (e) {
      console.log(e);
    }
  }

  async logOut() {
    try {
      this.fAuth.signOut();
    } catch (e) {
      console.log(e);
    }
  }

  async createUser(email: string, password: string) {
    try {
      const user = await this.fAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      return { email: user.user.email, uid: user.user.uid };
    } catch (e) {
      return { email: null, uid: null, error: e.message };
    }
  }
}

export type User = firebase.User;
