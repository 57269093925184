import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { environment } from '@env/environment';
import axios from 'axios';
import DashboardCard from '../components/dashboard/DashboardCard';
import { GTMData, transformGTMData } from '@wiply/utils';
import { useParams } from 'react-router-dom';
import GamesContext from '../context/games';
import DashboardHeader from '../components/dashboard/Header';
const dateFormat = 'YYYY-MM-DD';

const Dashboard: FunctionComponent = () => {
  const [data, setData] = useState<any>(GTMData);
  const [isFetching, setIsFetching] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { game, getGame } = useContext(GamesContext);
  const [date, setDate] = useState(['yesterday', 'today']);

  useEffect(() => {
    getGame(id);
  }, []);

  useEffect(() => {
    const getAnalytics = async () => {
      setIsFetching(true);
      const response = await axios.get(
        `${environment.api}events?gameId=${id}&startDate=${date[0]}&endDate=${date[1]}`,
        {
          method: 'GET',
        }
      );
      if (response) {
        setData(transformGTMData(response.data));
        setIsFetching(false);
      }
    };
    getAnalytics();
  }, [date, id]);

  const onChangeDates = (dates: any) =>
    setDate([dates[0].format(dateFormat), dates[1].format(dateFormat)]);
  return (
    <div className="my-8">
      <DashboardHeader gameName={game?.name || id} onChange={onChangeDates} />
      <div className="grid md:grid-cols-3 gap-x-6 gap-y-12 justify-around">
        {data.map((item) => (
          <DashboardCard
            item={item}
            key={item.label}
            isFetching={isFetching}
            game={game}
          />
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
