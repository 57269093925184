import React, { FunctionComponent, useMemo, useState } from 'react';
import EditImageCard from '../GameDesignCard/EditImageCard/EditImageCard';
import { memoryGallery, memoryImages } from '@wiply/utils';
import { GameDesignAssetsProps } from '.';
import EditMultipleImagesCard from '../GameDesignCard/EditImageCard/EditMultipleImagesCard';
import EditColorCard from '../GameDesignCard/EditColorCard';
import { EditColorAction } from '../GameDesignCard/EditColorAction';
import { InputNumber } from 'antd';
import VideoModalButton from '../EmbedVideoModal';

export const GameDesignAssetsMemory: FunctionComponent<GameDesignAssetsProps> = ({
  game,
  commonProps,
  onChangeHandler,
}) => {
  const { memoryOptions } = game.options;
  const [amountOfCards, setAmountOfCards] = useState(6);
  const [turns, setTurns] = useState(14);
  const extraAction = useMemo(() => {
    return (
      <EditColorAction
        hasAlpha={true}
        title="Card Color"
        onChangeHandler={(field) => {
          onChangeHandler({
            memoryOptions: {
              ...memoryOptions,
              coverColor: field,
            },
          });
        }}
        defaultValue={game.options.memoryOptions?.coverColor}
      />
    );
  }, [game]);
  return (
    <>
      <div>
        <VideoModalButton videoSrc='https://res.cloudinary.com/shulgirit/video/upload/v1689511431/wiply/video%20tutorials/jesse-video-tutorials/Memory-Tutorial.mp4'></VideoModalButton>
        {/* Momory Cover Image */}
        <div className="mt-4">

        <EditImageCard
          {...commonProps}
          title="Cover Photo"
          description={{
            type: 'PNG',
            size: 'Ratio: 500x500',
            aspectRatio: 1,
          }}
          accept="image/png"
          defaultValue={memoryOptions?.cover || memoryImages.cover}
          onChange={(field: string) =>
            onChangeHandler({
              memoryOptions: {
                ...memoryOptions,
                cover: field,
              },
            })
          }
          //defaultImagesToChoose={[memoryImages.cover, memoryImages.cover]}
          defaultImagesToChoose={[memoryImages.cover]}
          previewCss="max-h-32"
          extraActions={extraAction}
        />
        </div>
      </div>

      {/* MEMORY CARDS IMAGES */}
      <EditMultipleImagesCard
        description={{
          type: 'PNG',
          size: 'Ratio: 400x400',
          aspectRatio: 1,
        }}
        commonProps={commonProps}
        options={memoryOptions}
        onChangeHandler={onChangeHandler}
        instances={[...new Array(amountOfCards)].map((_, i) => i)}
        title="Cards Images"
        instance="cardImages"
        groupInstance="memoryOptions"
        defaultImages={
          memoryOptions?.cardImages
            ? memoryOptions.cardImages
            : memoryImages.cardImages
        }
        gallery={memoryGallery}
        extraContent={
          <div className="px-2 flex justify-evenly">
            <div>
              <h4 className="place-self-center	">turns: </h4>
              <InputNumber
                defaultValue={memoryOptions?.turns || turns}
                min={2}
                max={20}
                step={1}
                onChange={(value) => {
                  setTurns(value);
                  onChangeHandler({
                    memoryOptions: {
                      ...memoryOptions,
                      turns: value,
                    },
                  });
                }}
              />
            </div>
            <div>
              <h4 className="place-self-center	">images: </h4>

              <InputNumber
                defaultValue={memoryOptions?.amountOfCards || amountOfCards}
                min={2}
                max={6}
                step={2}
                parser={(value) => {
                  const intValue = parseInt(value);
                  return intValue % 2 === 0 ? intValue : intValue + 1;
                }}
                onChange={(value) => {
                  setAmountOfCards(value);
                  if (value % 2 === 0) {
                    onChangeHandler({
                      memoryOptions: {
                        ...memoryOptions,
                        amountOfCards: value,
                      },
                    });
                  }
                }}
              />
            </div>
          </div>
        }
      />
      {/* Memory Card Color */}
      <EditColorCard
        hasAlpha={true}
        title="Cards background color"
        defaultValue={game.options.memoryOptions?.cardColor || '#FFF'}
        onChange={(field: string) => {
          onChangeHandler({
            memoryOptions: {
              ...memoryOptions,
              cardColor: field,
            },
          });
        }}
      />
      {/* Memory TITLE Color
      <EditColorCard
        title="Title color"
        defaultValue={game.options.memoryOptions?.titleColor || '#00000099'}
        onChange={(field: string) => {
          onChangeHandler({
            memoryOptions: {
              ...memoryOptions,
              titleColor: field,
            },
          });
        }}
      /> */}
    </>
  );
};

export default GameDesignAssetsMemory;
