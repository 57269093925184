import React, { FunctionComponent, useContext, useState } from 'react';
import { Row, Form, Input, Alert } from 'antd';
import GamesContext from '../../context/games';
import { SmileOutlined } from '@ant-design/icons';
import { FormProps } from 'antd/es/form';
import Button from './../shared/Button';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';

export const GameAnalyticsTab: FunctionComponent = () => {
  const { updateGame, game } = useContext(GamesContext);
  const [success, setSuccess] = useState(false);
  const [form] = Form.useForm();
  const currentUser = useSelector((state: RootState) => state.userReducer);

  const onFinish = async ({ analyticsId }) => {
    await updateGame({ options: { ...game.options, analyticsId } });
    setSuccess(true);
  };

  const onFinish2 = async ({ addCrm }) => {
    await updateGame({ options: { ...game.options, addCrm } });
    setSuccess(true);
  };

  const onCancel = async () => {
    game.options.addCrm = ' ';
    console.log(
      game.coupons.map((coupon) => {
        console.log(coupon, 'omer');
      })
    );
    game.coupons.map((coupon) => {
      if (coupon?.emailSettings?.formCode) {
        coupon.emailSettings.formCode = '';
      }
    });

    await updateGame({ options: { ...game.options } });
    form.resetFields();
    setSuccess(true);
  };

  const onFinishFailed: FormProps['onFinishFailed'] = (errorInfo) =>
    console.log('Failed:', errorInfo);

  return (
    <>
      <Row gutter={[16, 32]} className="my-8">
        <Form
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="mx-6"
          layout="inline"
        >
          <Form.Item label="Google Tag Manager ID" name="analyticsId">
            <Input
              placeholder={game.options.analyticsId}
              className="rounded-2xl"
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="mt-2 lg:mt-0"
              title="Submit"
              style="inverted"
              submit={true}
            />
          </Form.Item>
        </Form>
      </Row>
      <Row gutter={[16, 32]} className="my-8">
        <Form
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish2}
          onFinishFailed={onFinishFailed}
          className="mx-6"
          layout="inline"
        >
          {currentUser?.isAdmin ? (
            <>
              <Form.Item label="Add Crm Script" name="addCrm">
                <Input.TextArea
                  id="crmTtextArea"
                  placeholder={game.options.addCrm}
                  className="rounded-2xl"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="mt-2 lg:mt-0"
                  title="Submit"
                  style="inverted"
                  submit={true}
                />
                <Button
                  className="mt-2 lg:mt-0"
                  title="Reset"
                  style="inverted"
                  onClick={onCancel}
                />
              </Form.Item>{' '}
            </>
          ) : null}
        </Form>
        {success && (
          <Alert
            icon={<SmileOutlined />}
            type="success"
            message="Updated!"
            className="rounded-2xl"
          />
        )}
      </Row>
    </>
  );
};

export default GameAnalyticsTab;
