import React, { FunctionComponent, useMemo, useState } from 'react';
import EditImageCard from '../GameDesignCard/EditImageCard/EditImageCard';
import { assetImages, bagImages, memoryGallery, memoryImages } from '@wiply/utils';
import { GameDesignAssetsProps } from '.';
import EditMultipleImagesCard from '../GameDesignCard/EditImageCard/EditMultipleImagesCard';
import EditColorCard from '../GameDesignCard/EditColorCard';
import { EditColorAction } from '../GameDesignCard/EditColorAction';
import { InputNumber } from 'antd';
import EditOptionsCard from '../GameDesignCard/EditOptionsCard';

export const GameDesignAssetsGems: FunctionComponent<GameDesignAssetsProps> = ({
  game,
  commonProps,
  onChangeHandler,
}) => {
  const { memoryOptions } = game.options;
  const [amountOfCards, setAmountOfCards] = useState(6);
  const [turns, setTurns] = useState(14);

  return (
    <>
      {/* Gems options */}
      {/* <EditOptionsCard
        {...commonProps}
        title="Number of lives"
        value1={1}
        value2={2}
        value3={3}
        value4={4}
        value5={5}
        defaultValue={game.options.towerOptions.numLives || 3}
        onChange={(field: number) =>
          onChangeHandler({
            towerOptions: {
              ...game.options.towerOptions,
              numLives: field,
            },
          })
        }
      /> */}
      <EditImageCard
        {...commonProps}
        title="Choose or upload a bag"
        description={{
          type: 'PNG',
          size: '305x338',
          aspectRatio: Number(305 / 338),
          text: (
            <span>
              A rect container for the game <br></br>is part of this svg and the
              <br></br> id name has to be: <strong>"game-container"</strong>
            </span>
          ),
        }}
        accept="image/png"
        defaultValue={game.options.gemOptions.bagImage}
        onChange={(field: string) => {
          onChangeHandler({
            gemOptions: {
              ...game.options.gemOptions,
              bagImage: field,
            },
          });
        }}
        defaultImagesToChoose={bagImages}
        previewCss=" max-h- text-xs"
      />
      <EditImageCard
        {...commonProps}
        title="Select asset 1"
        description={{
          type: 'PNG',
          size: '156x185',
          aspectRatio: Number(158 / 185),
          text: (
            <span>
              A rect container for the game <br></br>is part of this svg and the
              <br></br> id name has to be: <strong>"game-container"</strong>
            </span>
          ),
        }}
        accept="image/png"
        defaultValue={game.options.gemOptions.dropsImages[0]}
        onChange={(field: string) => {
          let [a, ...rest] = game.options.gemOptions.dropsImages;
          onChangeHandler({
            gemOptions: {
              ...game.options.gemOptions,
              dropsImages: [field, ...rest],
            },
          });
        }}
        defaultImagesToChoose={assetImages}
        previewCss=" max-h- text-xs"
      />
      <EditImageCard
        {...commonProps}
        title="Select asset 2"
        description={{
          type: 'PNG',
          size: '156x185',
          aspectRatio: Number(158 / 185),
          text: (
            <span>
              A rect container for the game <br></br>is part of this svg and the
              <br></br> id name has to be: <strong>"game-container"</strong>
            </span>
          ),
        }}
        accept="image/png"
        defaultValue={
          game.options.gemOptions.dropsImages[1] ||
          game.options.gemOptions.dropsImages[0]
        }
        onChange={(field: string) => {
          let [a, b, ...rest] = game.options.gemOptions.dropsImages;
          onChangeHandler({
            gemOptions: {
              ...game.options.gemOptions,
              dropsImages: [a, field, ...rest],
            },
          });
        }}
        defaultImagesToChoose={assetImages}
        previewCss=" max-h- text-xs"
      />
      <EditImageCard
        {...commonProps}
        title="Select asset 3"
        description={{
          type: 'PNG',
          size: '156x185',
          aspectRatio: Number(158 / 185),
          text: (
            <span>
              A rect container for the game <br></br>is part of this svg and the
              <br></br> id name has to be: <strong>"game-container"</strong>
            </span>
          ),
        }}
        accept="image/png"
        defaultValue={
          game.options.gemOptions.dropsImages[2] ||
          game.options.gemOptions.dropsImages[0]
        }
        onChange={(field: string) => {
          let [a, b] = game.options.gemOptions.dropsImages;
          onChangeHandler({
            gemOptions: {
              ...game.options.gemOptions,
              dropsImages: [a, b, field],
            },
          });
        }}
        defaultImagesToChoose={assetImages}
        previewCss=" max-h- text-xs"
      />
    </>
  );
};

export default GameDesignAssetsGems;
