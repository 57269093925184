import { environment } from '@env/environment';
import {
  AuthService,
  ClientRepository,
  UserRepository,
} from '@wiply/firesbase';
import axios from 'axios';
import { userConstants } from '../reducers/user';
import { MailRepository } from '@wiply/firesbase/mail';


const userRepository = new UserRepository();
const clientRepository = new ClientRepository();
const authService = new AuthService();
const mailRepository = new MailRepository();


// Thunk function
export function createUser(email: string, password: string) {
  return async function saveNewUser(dispatch) {
    const response = await userRepository.createUser(email, password);
    if (response && response.uid) {
      const client = await clientRepository.createDefaultClient(
        response.uid,
        response.email
      );
      localStorage.setItem('user', JSON.stringify(client));
      dispatch({ type: userConstants.REGISTER_SUCCESS, payload: client });
      await mailRepository.sendEmail(response?.email);
      await axios.get(`${environment.api}hubspot?email=${email}`, {
        method: 'POST',
      });
      return client;
    } else {
      dispatch({
        type: userConstants.REGISTER_FAILURE,
        payload: response.error,
      });
      localStorage.removeItem('user');
    }
  };
}

export function loginUser(email: string, password: string) {
  return async function saveNewUser(dispatch) {
    const response = await authService.signInWithEmailAndPassword(
      email,
      password
    );
    if (response.uid) {
      const client = await clientRepository.fetch(response.uid.toLowerCase());
      let newClient;
      if (!client) {
        newClient = await clientRepository.createDefaultClient(
          response.uid,
          response.email
        );
      }
      localStorage.setItem('user', JSON.stringify(client || newClient));
      dispatch({
        type: userConstants.LOGIN_SUCCESS,
        payload: client || newClient,
      });
    } else {
      dispatch({
        type: userConstants.LOGIN_FAILURE,
        payload: response.message,
      });
      localStorage.removeItem('user');
    }
  };
}

// export const loginAnonymousUser = (token: string): ThunkAction<void, RootState, unknown, { type: typeof userConstants.LOGIN_SUCCESS, payload: Client }> => async (dispatch: Dispatch<{ type: typeof userConstants.LOGIN_SUCCESS, payload: Client }>) => {
export function loginAnonymousUser(token: string) {
  return async function saveNewUser(dispatch) {
    const response = await authService.signInWithToken(token);
    if (response.uid) {
      const client = await clientRepository.fetch(response.uid.toLowerCase());
      console.log(client);
      localStorage.setItem('user', JSON.stringify(client));
      dispatch({
        type: userConstants.LOGIN_SUCCESS,
        payload: client,
      });
    }
    localStorage.removeItem('user');
  };
}

export function loginUserBySocial(provider: string) {
  return async function saveNewUser(dispatch) {
    const response = await authService.signinWithSocial(provider);
    if (response?.user?.uid) {
      const { uid, email } = response.user;
      const client = await clientRepository.fetch(uid?.toLowerCase());
      let newClient;
      if (!client) {
        newClient = await clientRepository.createDefaultClient(uid, email);
      }
      localStorage.setItem('user', JSON.stringify(client || newClient));
      dispatch({
        type: userConstants.LOGIN_SUCCESS,
        payload: client || newClient,
      });

      if (email) {
        // mailRepository.sendEmail(email);
        await axios.get(
          `${environment.api}hubspot?email=${email}&provider=${provider}`,
          {
            method: 'POST',
          }
        );
      }
    } else {
      dispatch({
        type: userConstants.LOGIN_FAILURE,
        payload: 'Error',
      });
      localStorage.removeItem('user');
    }
  };
}

export function resetPassword(email: string) {
  return async function resetUserPassword(dispatch) {
    const response = await authService.resetEmail(email);
    dispatch({ type: userConstants.RESET_PASSWORD });
    return response;
  };
}

export function logOut() {
  return async function logOutUser(dispatch) {
    await authService.logOut();
    localStorage.removeItem('user');
    dispatch({ type: userConstants.LOGOUT });
    window.location.href = '/login';
  };
}

export function update(userId: string) {
  return async function updateUser(dispatch) {
    const client = await clientRepository.fetch(userId?.toLowerCase());
    localStorage.setItem('user', JSON.stringify(client));
    dispatch({ type: userConstants.UPDATE, payload: client });
  };
}

export function checkTrialStatus(dateCreated: string, id: string) {
  return async function updateUser(dispatch) {
    const ends = clientRepository.checkFreeTiralEnds(dateCreated);
    if (ends) clientRepository.update(id, { freePlan: false });
    dispatch({ type: userConstants.CHECK_TRIAL_STATUS, payload: !ends });
  };
}
