import React, { FormEvent, FunctionComponent, useState } from 'react';
import Modal from '../index';
import styles from '../modal.module.scss';
import { MailRepository } from '@wiply/firesbase/mail';
import { environment } from '@env/environment';

interface Props {
  onCloseHandler: VoidFunction;
  emailTemplateId?: string;
}
const mailRepository = new MailRepository();

export const SendEmailModal: FunctionComponent<Props> = ({
  emailTemplateId,
  onCloseHandler,
}) => {
  const [error, setError] = useState<string>(null);
  const [email, setEmail] = useState<string>(null);
  const [phone, setPhone] = useState<string>(null);
  const [name, setName] = useState<string>(null);

  // const test = {
  //   name: 'melanie',
  //   address: {
  //     rejob: 'aa',
  //     'city-id': 'tlv'
  //   }
  // }

  // test.address.rejob

  // test.address['city-id'] mealnie example

  const onSubmitHandler = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setError(null);
      mailRepository.sendEmail(
        email,
        environment.mail.templates[emailTemplateId],
        {
          coupon: phone,
          coupon_url: name,
        }
      );
      onCloseHandler();
    } else {
      setError('Introduce a valid email.');
    }
  };

  return (
    <Modal>
      <div className={styles.content}>
        <img
          className="mx-auto	"
          src="https://res.cloudinary.com/shulgirit/image/upload/v1638875619/wiply/wiply_small_logo_mzsghe.png"
        ></img>
        <div className={styles.header}>
          <span className={styles.close} onClick={onCloseHandler}>
            &times;
          </span>
          <h2 className={`text-white ${styles.title}`}>CONTACT US</h2>
        </div>
        <div className={styles.body}>
          <form>
            <input
              onChange={(e) => setName(e.target.value)}
              name="name"
              placeholder="Full Name"
            />
            <input
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              placeholder="Email Address"
            />
            <input
              onChange={(e) => setPhone(e.target.value)}
              name="phone"
              placeholder="Phone Number"
            />

            <button onClick={onSubmitHandler}>Submit</button>
          </form>
          {error && <p>{error}</p>}
        </div>
      </div>
    </Modal>
  );
};

export default SendEmailModal;
