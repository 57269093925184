import React, { FunctionComponent } from 'react';
import EditImageCard from './EditImageCard/EditImageCard';
import { ClientGame, GameCoupon } from '@wiply/repository';

interface EditFieldProps {
  commonProps: any;
  game: ClientGame;
  defaultImages: string[];
  previewCss?: string;
}

const EditPrizesCard: FunctionComponent<EditFieldProps> = ({
  game,
  commonProps,
  previewCss,
  defaultImages,
}) => (
  <article className="bg-white shadow-lg rounded-lg">
    <div className="pt-4">
      <h3 className="block text-md font-medium text-center">Prizes</h3>
      <ul className="text-xs text-center my-4">
        <li>Type: PNG</li>
        <li>Recommended size: 95x69</li>
        <li>Aspect Ratio: {Number(95 / 69).toFixed(2)}</li>
      </ul>
      <div className="grid grid-cols-2 gap-2 p-2">
        {game.coupons.map((coupon: GameCoupon, i) => {
          return (
            <EditImageCard
              key={i}
              previewCss={previewCss}
              size="auto"
              {...commonProps}
              title={`prize${i + 1}`}
              description={{}}
              accept="image/png"
              defaultValue={coupon.gameImgUrl || defaultImages[i]}
              onChange={(field: string) => {
                const updatedCoupons = [...game.coupons];
                const index = updatedCoupons.findIndex(
                  (c) => c.couponId == coupon.couponId
                );
                updatedCoupons[index] = {
                  ...coupon,
                  gameImgUrl: field,
                };
                commonProps.updateGame({
                  ...game,
                  coupons: updatedCoupons,
                });
              }}
              defaultImagesToChoose={defaultImages}
              multiple={true}
              removeDelete={true}
            />
          );
        })}
      </div>
    </div>
  </article>
);

export default EditPrizesCard;
