import React, { FunctionComponent, useContext, useState } from 'react';
import GamePreviewComponent from './GamePreviewComponent';
import SubdomainContext from '../context/subdomain';
import { DesktopOutlined, MobileFilled } from '@ant-design/icons';
import styles from './Game.module.css';
import GamesContext, { GamesContextProvider } from '../context/games';

const baseUrl =
  'https://res.cloudinary.com/shulgirit/image/upload/v1641732207/wiply/Platform%20Default%20Images/preloaders/';
const isMobile = window?.innerWidth <= 815;

interface Props {
  gameId: string;
  hideMobile?: boolean;
  gameType: string;
  iframeRef: any;
}

export const Game: FunctionComponent<Props> = ({
  gameId,
  hideMobile,
  gameType,
  iframeRef,
}) => {
  const { game } = useContext(GamesContext);
  const { subdomain } = useContext(SubdomainContext);
  const [desktopPreview, setDesktopPreview] = useState(true);
  const toggleDesktop = () => setDesktopPreview(!desktopPreview);
  return (
    <>
      <div className={styles.right}>
        <div
          className={` border-gray-light lg:sticky lg:w-6/12 mb-8 rounded-2xl px-4 ${styles.stickeypreview}`}
        >
          <div className="lg:ml-12">
            <div className=" pb-2">
              <h2 className="text-xl font-bold ml-4 text-center">
                Game Preview: <span className="capitalize">{game.name}</span>
              </h2>
            </div>
            {!hideMobile && (
              <div className="hidden md:flex gap-4 mb-8 justify-center">
                <span
                  onClick={toggleDesktop}
                  className="flex flex-col cursor-pointer border-2 p-4 border-gray-light rounded-lg "
                >
                  Desktop
                  <DesktopOutlined
                    className={`text-${
                      desktopPreview ? 'gray' : 'gray-light'
                    }  text-5xl  `}
                  />
                </span>
                <span
                  className="cursor-pointer flex flex-col cursor-pointer border-2 p-4 border-gray-light rounded-lg "
                  onClick={toggleDesktop}
                >
                  Mobile
                  <MobileFilled
                    className={`text-${
                      !desktopPreview ? 'gray' : 'gray-light'
                    } text-5xl`}
                  />
                </span>
              </div>
            )}
            {desktopPreview && (
              <div className="preview-desktop -mt-16">
                <div className="md:flex flex-col w-full h-full">
                  <GamePreviewComponent
                    id={subdomain || gameId}
                    background="desktop-preview"
                    className="md:pb-4 md:px-8"
                    placeholderBackground={`${baseUrl}/${gameType}.jpg`}
                    iframeRef={iframeRef}
                  />
                </div>
              </div>
            )}

            {!desktopPreview && !hideMobile && !isMobile && (
              <div
                style={{ width: '12vw', height: '27vw' }}
                className="hidden lg:flex mt-12 lg:mt-0 m-auto"
              >
                <div className="flex flex-col w-full h-full">
                  <GamePreviewComponent
                    id={subdomain || gameId}
                    background="mobile-preview"
                    placeholderBackground={`${baseUrl}/${gameType}-mobile.jpg`}
                    iframeRef={iframeRef}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Game;
