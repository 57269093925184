import { environment } from '@env/environment';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? {
      loggedIn: true,
      user,
      errors: null,
      isAdmin: user?.name?.toLowerCase() === environment.adminUID,
    }
  : {};

export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  RESET_PASSWORD: 'RESET_PASSWORD',
  LOGOUT: 'USERS_LOGOUT',
  UPDATE: 'USERS_UPDATE',
  CHECK_TRIAL_STATUS: 'USERS_CHECK_TRIAL_STATUS',
};

export const userReducer = (state = initialState, action) => {
  const isAdmin =
    (action.payload?.id?.toLowerCase() || user?.id?.toLowerCase()) ===
    environment.adminUID;

  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        errors: null,
        loggedIn: true,
        user: action.payload,
        isAdmin,
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.payload,
        errors: null,
        isAdmin,
      };
    case userConstants.REGISTER_FAILURE:
      return {
        ...state,
        loggedIn: false,
        user: null,
        errors: {
          register: action.payload,
          login: null,
        },
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        errors: {
          login: action.payload,
          register: null,
        },
        ...state,
        user: null,
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        loggedIn: false,
        error: null,
        user: null,
        isAdmin: false,
      };
    case userConstants.RESET_PASSWORD:
      return {
        ...state,
        loggedIn: false,
        error: null,
        user: action.payload,
      };
    case userConstants.UPDATE:
      return {
        ...state,
        user: action.payload,
      };
    case userConstants.CHECK_TRIAL_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          freePlan: action.payload,
        },
      };
    default:
      return state;
  }
};
