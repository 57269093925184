import React, { FunctionComponent, useMemo, useRef, useState } from 'react';
import GameListItem from './GameListItem';
import Modal from '../shared/Modal';
import NewGameForm from './NewGameForm';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import Title from '../shared/Title';
import SVG from 'react-inlinesvg';

interface Props {
  games: any[];
  hasGames?: boolean;
  clientId?: string;
  title?: string;
  dateOfGame?: any;
}

const scroll = (scrollOffset, ref) => {
  ref.current.scrollLeft += scrollOffset;
};

export const NewGame: FunctionComponent<Props> = ({
  games,
  hasGames,
  clientId,
  title,
  dateOfGame,
}) => {
  const [gameType, setGameType] = React.useState(games[0].id);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const carouselGames = useRef();

  const handleCancel = () => setIsModalVisible(false);

  const ListOfGames = useMemo(() => {
    return games.map(({ id, name, unActive }) => (
      <div key={id} className={`my-2 m-auto sm:m-0`}>
        <GameListItem
          id={id}
          unActive={unActive}
          title={unActive ? 'Coming soon' : name}
          onClickHandler={() => {
            setIsModalVisible(true);
            setGameType(id);
          }}
          size="32"
        />
      </div>
    ));
  }, []);

  const NewGameModal = useMemo(() => {
    if (!isModalVisible) return null;
    return (
      <Modal
        title={
          <>
            <span className="font-medium">New Game</span> -{' '}
            <span className="capitalize font-medium">{gameType}</span>
          </>
        }
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        width={600}
      >
        <div className="flex gap-4">
          <SVG
            src={`https://res.cloudinary.com/shulgirit/image/upload/v1641469847/wiply/Platform%20Default%20Images/games/${gameType}.svg`}
            className="w-44 hidden lg:flex"
          />
          <NewGameForm
            handleCancel={handleCancel}
            gameType={gameType}
            clientId={clientId}
            date={dateOfGame}
          />
        </div>
      </Modal>
    );
  }, [isModalVisible]);

  return (
    <div className="w-full">
      <Title text={title || 'Create a new game'} />
      <div className="flex justify-center items-center">
        {hasGames && (
          <button onClick={() => scroll(-100, carouselGames)}>
            <LeftOutlined className="text-5xl text-gray-light" />
          </button>
        )}
        <div
          ref={carouselGames}
          className={`flex mt-4 ${
            hasGames
              ? 'overflow-x-hidden px-4 pb-6 pt-0'
              : 'flex-wrap justify-center'
          }`}
        >
          {ListOfGames}
        </div>

        {hasGames && (
          <button onClick={() => scroll(100, carouselGames)}>
            <RightOutlined className="text-5xl text-gray-light" />
          </button>
        )}
      </div>
      {NewGameModal}
    </div>
  );
};

export default NewGame;
