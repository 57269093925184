import React, { FunctionComponent, memo } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import SVG from 'react-inlinesvg';

interface Props {
  item: any;
  isFetching?: boolean;
  game: any;
}
const svgUrl =
  'https://res.cloudinary.com/shulgirit/image/upload/v1676408150/wiply/dashboard/icons/';

export const DashboardCard: FunctionComponent<Props> = memo(
  ({ item, isFetching, game }) => {
    return (
      <article className="shadow-lg rounded-lg bg-gradient-to-t from-violet-lighter to-violet-light2 p-8 text-white">
        <h3 className="text-lg text-white border-b-2 pb-1 text-center">
          {item.label}
        </h3>
        <div className="flex flex-wrap items-center justify-around mt-6">
          <SVG className="text-7xl pr-4" src={`${svgUrl}${item.icon}.svg`} />
          {isFetching && (
            <LoadingOutlined className="text-xl block m-auto text-white" spin />
          )}
          {!isFetching && <p className="text-6xl">{item.value || 0}</p>}
        </div>
      </article>
    );
  }
);

export default DashboardCard;
