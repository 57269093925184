import React, { FunctionComponent, useContext, useState } from 'react';
import { Form, Input, Alert, Result, Switch, RadioChangeEvent } from 'antd';
import { RocketOutlined } from '@ant-design/icons';
import { FormProps } from 'antd/es/form';
import SubdomainContext, { ErrorInterface } from '../../context/subdomain';
import Button from './../shared/Button';
import { environment } from '@env/environment';
import ShareLink from '../ShareLink';
import GamesContext from '../../context/games';
import { ClientGame } from '@wiply/repository';
import { EmbedModal } from '../EmbedModal';
import {
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import QRCode from 'react-qr-code';
import { Canvg, presets } from 'canvg';

interface Props {
  game?: ClientGame;
}

export const GameSubdomainTab: FunctionComponent<Props> = ({ game }) => {
  const { updateSubdomain, subdomain } = useContext(SubdomainContext);
  const { updateGame, uploadImage } = useContext(GamesContext);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<ErrorInterface>(null);
  const [fields, setFields] = useState(game);

  const [loading, setLoading] = useState(false);

  const [disableGame, setDisableGame] = useState<boolean>(
    game.options.disableGame || false
  );

  const downloadQR = () => {
    const svgElement = document.getElementById('qrCode')
      ?.firstChild as SVGSVGElement;
    if (svgElement) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const v = Canvg.fromString(ctx, svgElement.outerHTML);
      v.render().then(() => {
        const pngUrl = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = 'qr-code.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
    }
  };

  const onFinish = async ({ newSubdomain }) => {
    setError(null);
    setSuccess(false);
    const response = updateSubdomain(newSubdomain || subdomain);
    if (response) {
      setError(response);
    } else {
      setSuccess(true);
      if (!game.options.labels?.updateSubdomain)
        updateGame({
          ...game,
          options: {
            ...game.options,
            labels: {
              ...game.options.labels,
              updateSubdomain: true,
            },
          },
        });
    }
  };

  const onFinishFailed: FormProps['onFinishFailed'] = (errorInfo) =>
    console.log('Failed:', errorInfo);

  // let url;
  // if (environment.development) {
  //   url = `http://localhost:4202/${game.id}`;
  // } else {
  //   url = `https://${subdomain || game.id}.${environment.appsUrls.clientNext}`;

  // }
  const url = `https://${subdomain || game.id}.${
    environment.appsUrls.clientNext
  }`;
  const disableGameHandler = () => {
    const updatedEntity = {
      options: {
        ...game.options,
        disableGame: !disableGame,
      },
    };
    setDisableGame(!disableGame);
    updateGame(updatedEntity);
    setFields({ ...fields, ...updatedEntity }); //maybe take this off!!!
  };

  return (
    <div className="my-8">
      <Result
        icon={<RocketOutlined className="text-violet-light" />}
        title={
          <div className="text-xs md:text-lg flex flex-col">
            Congratulations {`\u{1F604}`} , your game is ready.
            <br></br>
            Now you can implement it on your website
            <br></br>
            or share it with your costumers.
          </div>
        }
        extra={
          <>
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="flex justify-center"
              initialValues={{
                subdomain,
              }}
            >
              <Form.Item shouldUpdate name="newSubdomain" label="Game Title">
                <Input placeholder={subdomain} className="rounded-lg" />
              </Form.Item>

              <Form.Item>
                <Button title="Save" submit={true} className="ml-2 items-end" />
              </Form.Item>
            </Form>
            {success && (
              <Alert
                icon={<RocketOutlined />}
                type="success"
                message="Updated!"
                className="rounded-lg w-1/2 m-auto mb-2"
              />
            )}
            {error && (
              <Alert
                type="error"
                message={error.error}
                className="rounded-lg w-1/2 m-auto mb-2"
              />
            )}
            <a href={url} target="_blank" className="text-violet text-xl">
              {url}
            </a>
            <br />
            <br />
            <EmbedModal
              game={game}
              updateGame={updateGame}
              url={url}
              uploadImage={uploadImage}
            />
            <div
              className="flex justify-center mt-12"
              style={{ background: 'white' /*, display: 'none'*/ }}
              id="qrCode"
            >
              <QRCode value={url} size={200} />
            </div>
            <button
              type="button"
              onClick={downloadQR}
              className="justify-center mt-12 bg-gradient-to-r from-violet to-violet-light text-white px-5 py-3 rounded-lg"
            >
              Download QR
            </button>
            <div className="flex justify-center mt-8">
              <ShareLink
                previewLink={url}
                className="rounded-lg shadow-md"
                wrapperClassName="gap-12"
              />
            </div>
            <div>
              <Switch
                className="mt-8 mb-4"
                checkedChildren="Game is Enabled"
                unCheckedChildren="Game is Disabled"
                defaultChecked={!disableGame}
                onChange={disableGameHandler}
              />
              {!!disableGame && (
                <div>
                  <h1>Note:</h1>
                  <p>
                    <b>Pay attention</b> - the game is no longer working{' '}
                    <br></br>our tip is to Go to the Background section and
                    change<br></br> the images to something more clear to the
                    players<br></br> Explaining that:{' '}
                    <i>the game is Ended or the discount period is over</i>
                  </p>
                  <h2 style={{ color: 'red', paddingTop: '10px' }}>
                    If you want to reactivate the game just press the switch
                    again
                  </h2>
                </div>
              )}
            </div>
          </>
        }
      />
    </div>
  );
};

export default GameSubdomainTab;
