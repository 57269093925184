import emailjs from '@emailjs/browser';
import { environment } from '@env/environment';
import { Player } from '@wiply/repository';


export class MailRepository {
  constructor() {}

  async sendEmail(email: string, templateId?: string, params?: any) {
    
    const templateParams = {
      to_name: email,
      parsedEmail: parseEmailAddress(email),
      username: params?.username,
      ...params
    };
    const { serviceId, userId, templates } = environment.mail;
    try {
      const result = await emailjs
      .send(params?.serviceId || serviceId, templateId || templates.welcome, templateParams, userId)
      .then(
        (result) => {},
        (error) => {
          console.log(error.text);
        }
      );
      console.log(result)
    } catch (error) {
      console.log('This is a EmailJS Error', error)
    }
  
  }

  async updateSheets(sheetsAPI: string, data: Player){
    try{
      const result = await fetch(sheetsAPI, {
        method: "POST",
        mode: "cors",      
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
      console.log(result)
    } catch(error) {
      console.log('This is a sheetBest Error', error)
    }
  
  }
}

function parseEmailAddress(email: string): string | null {
  const atIndex = email.indexOf('@');
  if (atIndex !== -1) {
    const username = email.substring(0, atIndex);
    return username;
  } else {
    return null; // Invalid email format
  }
}