import React, { useState, FunctionComponent, useEffect } from 'react';
import { SubdomainRepository } from '@wiply/firesbase';
import { Subdomain } from '@wiply/repository';
import { slugify } from '@wiply/utils';

export interface ErrorInterface {
  error: string;
}

interface SubdomainContextInterface {
  subdomain: string;
  updateSubdomain: (name: string) => void | ErrorInterface;
  subdomains: Subdomain[];
}

interface SubdomainContexProviderInterface {
  children: React.ReactNode;
  gameId: string;
}

const SubdomainContext = React.createContext<SubdomainContextInterface>({
  subdomain: null,
  updateSubdomain: (name: string) => {},
  subdomains: null,
});

const subdomainRepository = new SubdomainRepository();

export const SubdomainContextProvider: FunctionComponent<SubdomainContexProviderInterface> = ({
  children,
  gameId,
}) => {
  const [subdomain, setSubdomain] = useState<string>(null);
  const [subdomains, setSubdomains] = useState(null);

  useEffect(() => {
    const unsubscribe = subdomainRepository.subscribeAll(setSubdomains);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (subdomains) getSubdomainHandler();
  }, [subdomains]);

  const getSubdomainHandler = async () => {
    const response = await subdomainRepository.getByGameId(gameId);
    setSubdomain(response?.id);
  };

  const updateSubdomainHandler = (name: string) => {
    if (subdomains.filter((s: Subdomain) => s.id === name).length > 0)
      return { error: 'Subdomain already taken.' };
    if (subdomain && subdomain !== name) subdomainRepository.remove(subdomain);
    if (subdomain !== name)
      subdomainRepository.create(slugify(name), {
        gameId,
      });
  };

  const context = {
    subdomain,
    updateSubdomain: updateSubdomainHandler,
    subdomains,
  };

  return (
    <SubdomainContext.Provider value={context}>
      {children}
    </SubdomainContext.Provider>
  );
};

export default SubdomainContext;
