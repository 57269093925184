import React, { FunctionComponent, useEffect, useRef, useState, useMemo } from 'react';
import {
  Input,
  Alert,
  Switch,
  Modal,
  Button as Buttonantd,
  message,
  Slider,
  Upload,
  Tabs,
  Dropdown,
  Menu,
  Radio
} from 'antd';
import { UploadOutlined, SwitcherOutlined } from '@ant-design/icons';
import Button from './shared/Button';
import { ClientGame } from '@wiply/repository';
import Tooltip from 'antd/es/tooltip';
import EditColorCard from './GameDesignCard/EditColorCard';
import { environment } from '@env/environment';
import { defaultEmbedImages } from '@wiply/utils';
import GameDesignAssets from './GameDesignAssets';
import EditImageCard, { DescriptionField } from './GameDesignCard/EditImageCard/EditImageCard';
import ModelViewer from './shared/ModelViewer';
import HtmlLogo from './htmlLogo.jpg';
import ShopifyLogo from './ShopifyLogo.jpg';
import WordpressLogo from './WordPressLogo.jpg';
import WPstep1 from './WordPressDashbordStep1.jpeg';
import WPstep2 from './WordPressDashbordStep2.png';
import SPstep1 from './ShopifyStep1.png';
import SPstep2 from './ShopifyStep2.png';
import './EmbedModal.css';

import VideoModalButton from './EmbedVideoModal';

const { TextArea } = Input;
const { TabPane } = Tabs;

interface Props {
  game: ClientGame;
  updateGame: (entity: any) => void;
  url: string;
  uploadImage: (
    file: File,
    path: string,
    couponImage?: boolean
  ) => Promise<string>;
}

export const EmbedModal: FunctionComponent<Props> = ({
  game,
  updateGame,
  url,
  uploadImage,
}) => {
  const copyRef = useRef(null);
  const defaultEmbed = 'https://res.cloudinary.com/shulgirit/image/upload/v1688550181/wiply/Platform%20Default%20Images/embed-gallery/5.png';
  const [widgetImage, setWidgetImage] = useState(
    game.embeddedScript?.widgetImage || defaultEmbed
  );

  const [image, setImage] = useState(null);
  const [defaultImageSelected, setDefaultImageSelected] =
    useState(widgetImage);

  //const defaultImagesToChoose: string[] = defaultEmbedImages;
  const galleryPreview: string = "";
  const previewCss: string = "";
  const description: DescriptionField = null;

  const [widgetLeft, setWidgetLeft] = useState(
    game.embeddedScript?.setWidgetLeft || 'false'
  );
  const [widgetOn, setWidgetOn] = useState(
    game.embeddedScript?.widgetOn || 'true'
  );
  const [updateFields, setUpdateFields] = useState(false);
  const [bWidth, bStyle, bColor] =
    game?.embeddedScript?.iframeBorder?.split(' ') || [];
  console.log(game?.embeddedScript);
  const [iframeBorderWidth, setIframeBorderWidth] = useState(
    Number(game.embeddedScript?.width.split('px')[0]) || 1200
  );
  const [iframeBorderHeight, setIframeBorderHeight] = useState(
    Number(game.embeddedScript?.height.split('px')[0]) || 600
  );
  const [iframeBorderMobileWidth, setIframeBorderMobileWidth] = useState(
    Number(game.embeddedScript?.mobileWidth?.split('vw')[0]) || 95
  );
  const [iframeBorderMobileHeight, setIframeBorderMobileHeight] = useState(
    Number(game.embeddedScript?.mobileHeight?.split('vh')[0]) || 95
  );
  const [r, g, b, opac] =
    game.embeddedScript?.backgroundDesign?.substring(4).split(',') || [];
  console.log(r, g, b, opac);
  const [backgroundRGB, setBackgroundRGB] = useState({
    r: Number(r),
    g: Number(g),
    b: Number(b),
  });
  const [opacity, setOpacity] = useState(
    Number(opac?.substring(0, opac.length - 1)) || 1 * 100
  );
  console.log(opacity);

  const [borderStyle, setBorderStyle] = useState(bStyle || '');
  const [borderColor, setBorderColor] = useState(bColor || '');
  const [borderWidth, setBorderWidth] = useState(
    Number(bWidth?.split('px')[0]) || 1 * 10
  );

  const embedString = `<link rel="stylesheet" href="https://cdnpageintegration.s3.amazonaws.com/style.css" crossorigin="anonymous">
  <script id="wiply-games" type="module" src="https://cdnpageintegration.s3.amazonaws.com/index.js" gameId="${game.id}" wiply-environment="${environment.development ? "development" : "production"}"></script>`;

  const embedStringShopify = `<link rel="stylesheet" href="https://cdnpageintegration.s3.amazonaws.com/style.css" crossorigin="anonymous">
  <script async id="wiply-games" type="module" src="https://cdnpageintegration.s3.amazonaws.com/index.js" gameId="${game.id}" wiply-environment="${environment.development ? "development" : "production" }" defer="defer"></script>`;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVisible, setIsModalVisible] = useState(false);
  const [alert, setAlert] = useState(false);

  const info = () => {
    copyRef.current.focus({ cursor: 'all' });
    message.info('Link copied to clipboard');
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModalGallery = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChangeTab = (key: string) => {
    console.log(key);
  };

  const gallery = useMemo(() => {
    if (!defaultEmbedImages) return null;
    const items = defaultEmbedImages.map((img, i) => (
      <div className="my-2 m-auto sm:m-0" key={i}>
        <Radio
          value={galleryPreview ? Object.keys(img)[0] : img}
          className="w-full"
        >
          {description?.type === 'GLB' && (
            <ModelViewer src={img} width="180px" />
          )}
          {!(description?.type === 'GLB') && (
            <img
              className={`w-full max-w-xs  ${previewCss}`}
              src={
                galleryPreview ? img[Object.keys(img)[0]][galleryPreview] : img
              }
              alt="preview"
            />
          )}
        </Radio>
      </div>
    ));
    return items;
  }, []);

  useEffect(() => {
    updateGame({
      ...game,
      embeddedScript: {
        widgetOn: widgetOn || 'true',
        gameURL: url,
        widgetImage: widgetImage || null,
        setWidgetLeft: widgetLeft || 'true',
        iframeBorder:
          borderWidth / 10 + 'px ' + borderStyle + ' ' + borderColor,
        backgroundDesign:
          'rgb(' +
          (backgroundRGB.r || 0) +
          ',' +
          (backgroundRGB.g || 0) +
          ',' +
          (backgroundRGB.b || 0) +
          ',' +
          opacity / 100 +
          ')',
        width: iframeBorderWidth + 'px',
        height: iframeBorderHeight + 'px',
        mobileWidth: iframeBorderMobileWidth + 'vw',
        mobileHeight: iframeBorderMobileHeight + 'vh',
        customCSS: JSON.stringify(customCSS)
      },
    });
  }, [updateFields]);

  function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
      : null;
  }
  function componentToHex(c) {
    const hex = c.toString(16);
    return hex.length == 1 ? '0' + hex : hex;
  }

  function rgbToHex(r, g, b) {
    return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Button
          title={'solid'}
          onClick={() => {
            setBorderStyle('solid');
            setAlert(true);
          }}
        ></Button>
      </Menu.Item>
      <Menu.Item key="1">
        <Button
          title={'dashed'}
          onClick={() => {
            setBorderStyle('dashed');
            setAlert(true);
          }}
        ></Button>
      </Menu.Item>
      <Menu.Item key="3">
        <Button
          title={'dotted'}
          onClick={() => {
            setBorderStyle('dotted');
            setAlert(true);
          }}
        ></Button>
      </Menu.Item>
      <Menu.Item key="4">
        <Button title={'none'} onClick={() => setBorderStyle('none')}></Button>
      </Menu.Item>
    </Menu>
  );

// toggles for code snippet / instruction displays

  const [isHTMLTextAreaVisible, setIsHTMLTextAreaVisible] = useState(false);
  const [isShopifyTextAreaVisible, setIsShopifyTextAreaVisible] = useState(false);
  const [isWordPressTextAreaVisible, setIsWordPressTextAreaVisible] = useState(false);

  const [customCSS, setCustomCSS] = useState(game.embeddedScript?.customCSS || ``)

  const toggleHTMLTextArea = () => {
    setIsHTMLTextAreaVisible(!isHTMLTextAreaVisible);
    setIsShopifyTextAreaVisible(false);
    setIsWordPressTextAreaVisible(false);
  }

  const toggleShopifyTextArea = () => {
    setIsHTMLTextAreaVisible(false);
    setIsShopifyTextAreaVisible(!isShopifyTextAreaVisible);
    setIsWordPressTextAreaVisible(false);
  }

  const toggleWordPressTextArea = () => {
    setIsHTMLTextAreaVisible(false);
    setIsShopifyTextAreaVisible(false);
    setIsWordPressTextAreaVisible(!isWordPressTextAreaVisible);
  }

  // zoom functionality on images

  const [zoomedSPstep1, setZoomedSPstep1] = useState(false);
  const [zoomedSPstep2, setZoomedSPstep2] = useState(false);
  const [zoomedWPstep1, setZoomedWPstep1] = useState(false);
  const [zoomedWPstep2, setZoomedWPstep2] = useState(false);

  // makes sure zoom doesnt cover other contents below
  const [zoomMarginSP1, setZoomMarginSP1] = useState(0);
  const [zoomMarginSP2, setZoomMarginSP2] = useState(0);
  const [zoomMarginWP1, setZoomMarginWP1] = useState(0);
  const [zoomMarginWP2, setZoomMarginWP2] = useState(0);

  const toggleZoomSPstep1 = () => {
    setZoomedSPstep1(!zoomedSPstep1);
    setZoomMarginSP1(zoomedSPstep1 ? 0 : calculateZoomMargin(SPstep1));
  };

  const toggleZoomSPstep2 = () => {
    setZoomedSPstep2(!zoomedSPstep2);
    setZoomMarginSP2(zoomedSPstep2 ? 0 : calculateZoomMargin(SPstep2));
  };

  const toggleZoomWPstep1 = () => {
    setZoomedWPstep1(!zoomedWPstep1);
    setZoomMarginWP1(zoomedWPstep1 ? 0 : calculateZoomMargin(WPstep1));
  };

  const toggleZoomWPstep2 = () => {
    setZoomedWPstep2(!zoomedWPstep2);
    setZoomMarginWP2(zoomedWPstep2 ? 0 : calculateZoomMargin(WPstep2));
  };
  
  const calculateZoomMargin = (img) => {
    const imgElement = document.createElement("img");
    imgElement.src = img;
    return imgElement.height * .15;  // scale factor
  };


  return (
    <div className="flex justify-center mt-12">
      <div>
        <Buttonantd onClick={showModal} shape={'circle'}>
          {' '}
          &lt;/&gt;
        </Buttonantd>
        <div>Embed</div>
      </div>

      <Modal
        title="Embed Code to website"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={820}
      ><div style={{position:"absolute",right:"1rem"}}>
          <VideoModalButton videoSrc='https://res.cloudinary.com/shulgirit/video/upload/v1689239492/wiply/video%20tutorials/jesse-video-tutorials/Widget-Tutorial.mp4'></VideoModalButton></div>
        <div>
          <div>
            <h1 className="text-2xl font-bold mt-0 mb-6">iframe size</h1>
          </div>
          <br />
          <div style={{ display: 'flex' }}>
            <label>
              Width Desktop:
              <Input
                value={iframeBorderWidth}
                onChange={(event) => {
                  if (!isNaN(+event.target.value)) {
                    setIframeBorderWidth(Number(event.target.value));
                    setAlert(true);
                  }
                }}
                style={{ width: '100px', borderRadius: '10px', color: 'black' }}
              />
              px
            </label>
            <label style={{ paddingLeft: '20px' }}>
              Height Desktop:
              <Input
                value={iframeBorderHeight}
                onChange={(event) => {
                  if (!isNaN(+event.target.value)) {
                    setIframeBorderHeight(Number(event.target.value));
                    setAlert(true);
                  }
                }}
                style={{ width: '100px', borderRadius: '10px' }}
              />
              px
            </label>
            <label>
              Width Mobile:
              <Input
                value={iframeBorderMobileWidth}
                onChange={(event) => {
                  if (!isNaN(+event.target.value)) {
                    setIframeBorderMobileWidth(Number(event.target.value));
                    setAlert(true);
                  }
                }}
                style={{ width: '100px', borderRadius: '10px', color: 'black' }}
              />
              %
            </label>
            <label style={{ paddingLeft: '20px' }}>
              Height Mobile:
              <Input
                value={iframeBorderMobileHeight}
                onChange={(event) => {
                  if (!isNaN(+event.target.value)) {
                    setIframeBorderMobileHeight(Number(event.target.value));
                    setAlert(true);
                  }
                }}
                style={{ width: '100px', borderRadius: '10px' }}
              />
              %
            </label>
          </div>
          <br />
          <hr />
          <br />
          <div>
            <h1 className="text-2xl font-bold mt-0 mb-6">
              Widget Button Design
            </h1>
          </div>
          <br />
          {/* <div className="py-2 flex items-center justify-center"> */}
          <div>
            <div style={{ display: 'flex' }}>
              <label>
                Widget On?{'      '}
                <Switch
                  defaultChecked={widgetOn === 'true'}
                  onChange={() => {
                    widgetOn === 'true'
                      ? setWidgetOn('false')
                      : setWidgetOn('true');
                    setAlert(true);
                  }}
                ></Switch>
              </label>
              {widgetOn === 'true' && (
                <label style={{ paddingLeft: '20px' }}>
                  Set Widget Left?{'      '}
                  <Switch
                    defaultChecked={widgetLeft === 'true'}
                    onChange={() => {
                      widgetLeft === 'true'
                        ? setWidgetLeft('false')
                        : setWidgetLeft('true');
                      setAlert(true);
                    }}
                  ></Switch>
                </label>
              )}
            </div>
            <br />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}
            >
              <Upload
                showUploadList={false}
                customRequest={async (fileUpload) => {
                  const { file }: { file: any } = fileUpload;

                  const uploadedFile: string = await uploadImage(
                    file,
                    `games/${game.id}`
                  );
                  setWidgetImage(uploadedFile);
                  setAlert(true);
                }}
                accept="image/*"
              >
                <Tooltip title={'Upload Widget Image'} placement="bottom">
                  <div className="flex flex-col justify-center items-center">
                    <UploadOutlined className="text-white p-2 gradient shadow-3xl rounded-full h-14 w-14 flex items-center justify-center" />
                    <span className="text-violet my-2">
                      Upload your Widget Image
                    </span>
                  </div>
                </Tooltip>
              </Upload>

              <button type='button' title='Gallery of Widget Images' onClick={showModalGallery}>
                <div className="flex flex-col justify-center items-center">
                  <SwitcherOutlined className="text-white p-2 gradient shadow-3xl rounded-full h-14 w-14 flex items-center justify-center" />
                  <span className="text-violet my-2">
                    Gallery
                  </span>
                </div>
              </button>
              <Modal
                title={<span className="block font-bold">Select your Image</span>}
                visible={modalVisible}
                onCancel={() => setIsModalVisible(false)}
                width={'70vw'}
              >
                <Radio.Group
                  onChange={(e) => {
                    setImage(null);
                    setDefaultImageSelected(e.target.value);
                    setWidgetImage(e.target.value);
                    setIsModalVisible(false);
                  }}
                  value={defaultImageSelected}
                //defaultImagesToChoose = {defaultEmbedImages}
                >
                  <div className="w-auto flex flex-wrap gap-4 m-auto place-content-center">
                    {gallery}
                  </div>
                </Radio.Group>
              </Modal>

              {widgetImage && (
                <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
                  <img
                    width={'175px'}
                    height={'150px'}
                    src={widgetImage}
                    alt=""
                  />
                  <label style={{ textAlign: 'center', marginTop: '15px' }}>
                    <ul className="text-xs self-center">
                      <li>Recommended size: 175x150</li>
                    </ul>
                  </label>
                </div>
              )}
            </div>
          </div>
          <br />
          <hr />
          <br />
          <div>
            <h1 className="text-2xl font-bold mt-0 mb-6">
              iframe Background Design
            </h1>
          </div>
          <br />
          <div>
            <Tabs defaultActiveKey="1" onChange={onChangeTab}>
              <TabPane tab="Border" key="1">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ float: 'left', paddingRight: '25px' }}>
                      Width
                    </label>
                    <Slider
                      style={{
                        float: 'right',
                        paddingRight: '25px',
                        width: '200px',
                      }}
                      defaultValue={borderWidth}
                      onChange={(field) => {
                        setBorderWidth(field);
                        setAlert(true);
                      }}
                    ></Slider>
                  </div>

                  <label style={{ paddingLeft: '250px' }}>
                    Style {'      '}
                    <Dropdown overlay={menu}>
                      <Buttonantd>{borderStyle} </Buttonantd>
                    </Dropdown>
                  </label>
                </div>
                <div>
                  <EditColorCard
                    className="float-left w-240"
                    title={'Edit border color'}
                    defaultValue={borderColor}
                    onChange={(field: string) => {
                      setBorderColor(field);
                      setAlert(true);
                    }}
                  />
                </div>
              </TabPane>
              <TabPane tab="Background Opacity" key="2">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ float: 'left', paddingRight: '25px' }}>
                    Opacity{'      '}
                  </label>
                  <Slider
                    style={{
                      float: 'right',
                      paddingRight: '25px',
                      width: '200px',
                    }}
                    defaultValue={opacity}
                    onChange={(field) => {
                      setOpacity(field);
                      setAlert(true);
                    }}
                  ></Slider>
                </div>

                <div>
                  <EditColorCard
                    className="float-left w-240"
                    title={'Edit background color'}
                    defaultValue={rgbToHex(
                      backgroundRGB.r,
                      backgroundRGB.g,
                      backgroundRGB.b
                    )}
                    onChange={(field: string) => {
                      setBackgroundRGB(hexToRgb(field));
                      setAlert(true);
                    }}
                  />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
        <br />
        <hr />
        <br />

        <h1 className="text-2xl font-bold mt-0 mb-6">
          Custom CSS
        </h1>
        <TextArea
              rows={6}
              onChange={(e) => {setCustomCSS(e.target.value)
                setAlert(true);
              }}
              value={customCSS}
           
            ></TextArea>
                    <br />

                    <hr />

        <br />
        <h1 className="text-2xl font-bold mt-0 mb-6">
          Integrate Widget Into Your Website
        </h1>
        {/* Below are the buttons to display the options on how to embed your widget into desired site. Order is: WordPress, Shopify, HTML */}
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div style={{
            display: 'flex', alignItems: 'center', cursor: 'pointer', borderRadius: '5px', padding: '0px 25px 5px', boxShadow: '0 2.8px 2.2px rgba(0,0,0,.024), 0 6.7px 5.3px rgba(0,0,0,.036), 0 12.5px 10px rgba(0,0,0,.05), 0 22.3px 17.9px rgba(0,0,0,.06), 0 41.8px 33.4px rgba(0,0,0,.07), 0 100px 80px rgba(0,0,0,.10)'
            , marginBottom: '10px'
          }} onClick={toggleWordPressTextArea}>
            <img
              src={WordpressLogo}
              alt="Wordpress Logo"
              style={{ maxHeight: '60px', borderRadius: '5px', marginRight: '10px' }}
              onClick={toggleWordPressTextArea}
            />
            <p style={{ color: 'rgb(124 124 124)', fontSize: '1.5em', fontFamily: 'inherit' }}>WordPress</p>
          </div>
          <div style={{
            display: 'flex', alignItems: 'center', cursor: 'pointer', borderRadius: '5px', padding: '0px 11px 5px 0px', boxShadow: '0 2.8px 2.2px rgba(0,0,0,.024), 0 6.7px 5.3px rgba(0,0,0,.036), 0 12.5px 10px rgba(0,0,0,.05), 0 22.3px 17.9px rgba(0,0,0,.06), 0 41.8px 33.4px rgba(0,0,0,.07), 0 100px 80px rgba(0,0,0,.10)'
            , marginBottom: '10px'
          }} onClick={toggleShopifyTextArea}>
            <img
              src={ShopifyLogo}
              alt="Shopify Logo"
              style={{ maxHeight: '60px', borderRadius: '5px', marginRight: '-15px' }}
              onClick={toggleShopifyTextArea}
            />
            <p style={{ color: 'rgb(124 124 124)', fontSize: '1.5em', fontFamily: 'inherit' }}>Shopify</p>
          </div>
          <div style={{
            display: 'flex', alignItems: 'center', cursor: 'pointer', borderRadius: '5px', padding: '0px 17px 5px', boxShadow: '0 2.8px 2.2px rgba(0,0,0,.024), 0 6.7px 5.3px rgba(0,0,0,.036), 0 12.5px 10px rgba(0,0,0,.05), 0 22.3px 17.9px rgba(0,0,0,.06), 0 41.8px 33.4px rgba(0,0,0,.07), 0 100px 80px rgba(0,0,0,.10)'
            , marginBottom: '10px'
          }} onClick={toggleHTMLTextArea}>
            <img
              src={HtmlLogo}
              alt="HTML Logo"
              style={{ maxHeight: '60px', borderRadius: '5px', marginRight: '10px' }}
              onClick={toggleHTMLTextArea}
            />
            <p style={{ color: 'rgb(124 124 124)', fontSize: '1.5em', fontFamily: 'inherit' }}>HTML</p>
          </div>
        </div>
        <br />
        {/* Here the instructions / code is displayed based on what button is pressed/unpressed */}
        <div className="mainFlex" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="instructions" style={{ display: 'flex', flexDirection: 'row' }}>
            {/* left side where code / instructions are when pressed on integration option */}
            <div className="htmlCode" style={{ display: 'flex', flexDirection: 'column', flex: '1', marginRight: '10px' }}>
              {isHTMLTextAreaVisible && (
                <div>
                  <h2 style={{ fontSize: 'large' }}>Paste this code below the <i>Body</i> tag of your website's HTML file:</h2>
                  <br />
                  <TextArea
                    rows={6}
                    readOnly={true}
                    ref={copyRef}
                    value={embedString}
                  ></TextArea>
                  <br />
                  <br />
                  <Button
                    title={'COPY'}
                    onClick={() => {
                      navigator.clipboard.writeText(embedString);
                      info();
                    }}
                  ></Button>
                </div>
              )}
              {isShopifyTextAreaVisible && (
                  <div>
                    <h2 style={{ fontSize: 'large' }}>1. Navigate to your Shopify Online Store and selecte Themes. Click the 3 dots next to the Customize button of your theme and select "Edit code"</h2>
                    <br />
                    <img 
                      style={{ marginTop: zoomMarginSP1, marginBottom: zoomMarginSP1 }}
                      src={SPstep1} 
                      alt='step 1' 
                      className={`img-zoom ${zoomedSPstep1 ? 'zoomed' : ''}`} 
                      onClick={toggleZoomSPstep1}></img>
                    <br />
                    <h2 style={{ fontSize: 'large' }}>2. Navigate to your theme.liquid file</h2>
                    <br />
                    <img 
                      style={{ marginTop: zoomMarginSP2, marginBottom: zoomMarginSP2 }}
                      src={SPstep2} 
                      alt='step 2' 
                      className={`img-zoom ${zoomedSPstep2 ? 'zoomed' : ''}`} 
                      onClick={toggleZoomSPstep2}></img>
                    <br />
                    {/* BELOW IS CODE SNIPPET AND COPY BUTTON FOR THE LEFT SIDE, COMMENTED OUT AND HAS BEEN MOVED UNDER VIDEO */}
                    {/*  */}
                    {/* <h2 style={{ fontSize: 'large' }}>3. Paste this code below the <i>Body</i> tag of your website's theme.liquid file:</h2>
                    <br />
                    <TextArea
                      rows={6}
                      readOnly={true}
                      ref={copyRef}
                      value={embedStringShopify}
                    ></TextArea>
                    <br />
                    <br />
                    <Button
                      title={'COPY'}
                      onClick={() => {
                        navigator.clipboard.writeText(embedStringShopify);
                        info();
                      }}
                    ></Button> */}
                  </div>
                )}
                {isWordPressTextAreaVisible && (
                    <div>
                      <h2 style={{ fontSize: 'large' }}>1. Navigate to your WordPress dashboard. From there go to the "Code Snippets" section and select "Header & Footer"</h2>
                      <br />
                      <img 
                        className={`img-zoom ${zoomedWPstep1 ? 'zoomed' : ''}`} 
                        onClick={toggleZoomWPstep1} 
                        src={WPstep1} 
                        alt='step 1' 
                        style={{ marginTop: zoomMarginWP1, marginBottom: zoomMarginWP1 }}></img>
                      <br />
                      <h2 style={{ fontSize: 'large' }}>2. Scroll down to the section titled "Footer"</h2>
                      <br />
                      <img 
                        className={`img-zoom ${zoomedWPstep2 ? 'zoomed' : ''}`} 
                        onClick={toggleZoomWPstep2} 
                        src={WPstep2} 
                        alt='step 2' 
                        style={{ marginTop: zoomMarginWP2, marginBottom: zoomMarginWP2 }}></img>
                      <br />
                      {/* BELOW IS CODE SNIPPET AND COPY BUTTON FOR THE LEFT SIDE, COMMENTED OUT AND HAS BEEN MOVED UNDER VIDEO */}
                      {/*  */}
                      {/* <h2 style={{ fontSize: 'large' }}>3. Lastly, copy the code below and paste it into the "Footer" text box.</h2>
                      <br />
                      <TextArea
                        rows={6}
                        readOnly={true}
                        ref={copyRef}
                        value={embedString}
                      ></TextArea>
                      <br />
                      <br />
                      <Button
                        title={'COPY'}
                        onClick={() => {
                          navigator.clipboard.writeText(embedString);
                          info();
                        }}
                      ></Button> */}
                    </div>
                  )}
            </div>
            {/* Divider line */}
            <div style={{borderLeft: '2px solid rgb(218 218 218 / 53%)', height: '100%', marginRight: '5px'}}></div>
            {/* Video on right side for all integrations */}
            <div className="htmlVideo" style={{ flex: '1', marginLeft: '10px' }}>
              {isHTMLTextAreaVisible && (
                <div>
                  <h2 style={{ fontSize: 'large', textAlign: 'center' }}>Video Instructions:</h2>
                  <br/>
                  <video controls style={{ width: '100%' }}>
                    {/* The video instructions */}
                    <source src='https://res.cloudinary.com/shulgirit/video/upload/v1689494818/wiply/video%20tutorials/jesse-video-tutorials/Embed-HTML-Tutorial.mp4' type="video/mp4"></source>
                  </video>
                  <br />
                </div>
              )}
              {isShopifyTextAreaVisible && (
                <div>
                  <h2 style={{ fontSize: 'large', textAlign: 'center' }}>Video Instructions:</h2>
                  <br/>
                  <video controls style={{ width: '100%' }}>
                    {/* The video instructions */}
                    <source src='https://res.cloudinary.com/shulgirit/video/upload/v1689514571/wiply/video%20tutorials/jesse-video-tutorials/Shopify-Tutorial.mp4' type="video/mp4"></source>
                  </video>
                  <br />
                  <br />
                  <br />
                  <br />
                  <h2 style={{ fontSize: 'large', marginTop: zoomMarginSP1 }}>3. Paste this code below the <i>Body</i> tag of your website's theme.liquid file:</h2>
                  <br />
                    <TextArea
                      rows={6}
                      readOnly={true}
                      ref={copyRef}
                      value={embedStringShopify}
                    ></TextArea>
                    <br />
                    <br />
                    <Button
                      title={'COPY'}
                      onClick={() => {
                        navigator.clipboard.writeText(embedStringShopify);
                        info();
                      }}
                    ></Button>
                </div>
              )}
              {isWordPressTextAreaVisible && (
                <div>
                  <h2 style={{ fontSize: 'large', textAlign: 'center' }}>Video Instructions:</h2>
                  <br/>
                  <video controls style={{ width: '100%' }}>
                    {/* The video instructions */}
                    <source src='https://res.cloudinary.com/shulgirit/video/upload/v1689584669/wiply/video%20tutorials/jesse-video-tutorials/Wordpress-Tutorial.mp4' type="video/mp4"></source>
                  </video>
                  <br />
                  <br />
                  <br />
                  <br />
                  <h2 style={{ fontSize: 'large', marginTop: zoomMarginWP1 }}>3. Lastly, copy the code below and paste it into the "Footer" text box.</h2>
                  <br />
                  <TextArea
                    rows={6}
                    readOnly={true}
                    ref={copyRef}
                    value={embedString}
                  ></TextArea>
                  <br />
                  <br />
                  <Button
                    title={'COPY'}
                    onClick={() => {
                      navigator.clipboard.writeText(embedString);
                      info();
                    }}
                  ></Button>   
                </div>
              )}
            </div>
          </div>
        </div>
        <br />
        {/* Save button is here */}
        <Tooltip
          title={'Make sure to refresh your website to see changes'}
          placement="bottom"
        >
          <Button
            title="Save"
            style="inverted"
            className={` w-2/5 m-auto mt-4  h-auto ${alert ? ' animate-bounce	 ' : ''
              }`}
            onClick={() => {
              setUpdateFields(!updateFields);
              setAlert(false);
            }}
          />
        </Tooltip>
        {alert && (
          <div className="mt-2">
            <Alert message="Dont forget to save your changes" type="error" />
          </div>
        )}
      </Modal>
    </div>
  );
};
