import React, { FunctionComponent } from 'react';
import Plan from './Plan';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { environment } from '@env/environment';
import styles from './Plan.module.css';
import { NavigationBar } from '../../components/shared/NavigationBar';

const initialOptions = {
  'client-id': environment.payPal.id,
  currency: 'USD',
  intent: 'subscription',
  vault: true,
};

const color = 'blue';
const color2 = 'red';

const plans = [
  {
    id: '0',
    type: 'Pro',
    title: '$199/Mo',
    description: [
      {
        name: 'Up to 10,000 Users',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
      {
        name: 'Full Game Library',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
      {
        name: 'Graphic Bank',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
      {
        name: 'Insight Dashboard',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
      {
        name: 'Up to 3 Games',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
      {
        name: 'Chat Support',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
      {
        name: 'Design Support',
        value: <CloseCircleTwoTone twoToneColor={color2} />,
      },
    ],
  },
  {
    id: '1',
    type: 'Elite',
    title: '$299/Mo',
    description: [
      { 
        name: 'Up to 50,000 Users', 
        value: <CheckCircleTwoTone twoToneColor={color} /> 
      },
      {
        name: 'Full Game Library',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
      {
        name: 'Graphic Bank',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
      {
        name: 'Insight Dashboard',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
      {
        name: 'Up to 8 Games',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
      {
        name: 'Chat Support',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
      {
        name: 'Design Support',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
    ],
  },
  {
    id: '2',
    type: 'Enterprise',
    title: 'Get a game of your own!',
    description: [
      { 
        name: 'Unlimited Users', 
        value: <CheckCircleTwoTone twoToneColor={color} /> },
      {
        name: 'Full Game Library',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
      {
        name: 'Graphic Bank',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
      {
        name: 'Insight Dashboard',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
      {
        name: 'Unlimited Games',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
      {
        name: 'Customer Support',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
      {
        name: 'Design Support',
        value: <CheckCircleTwoTone twoToneColor={color} />,
      },
    ],
    contact: true,
  },
];

export const Plans: FunctionComponent = () => {
  return (
    <PayPalScriptProvider options={initialOptions}>
      <div className={`${styles.bg_area} p-10 `}>
        <h2 className="text-center text-white text-4xl font-bold mt-4">
          Payment Plans
        </h2>
        <div
          className={`md:grid grid-cols-2 xl:grid-cols-3 gap-12 my-16 gap-y-4 xl:px-24 xl:py-24	rounded-2xl ${styles.cards_bg}`}
        >
          {plans.map((plan) => (
            <Plan key={plan.id} {...plan} />
          ))}
        </div>
      </div>
    </PayPalScriptProvider>
  );
};

export default Plans;
