import { Col, InputNumber, Row, Slider } from 'antd';
import React, { useState } from 'react';
import Button from '../shared/Button';

const SliderImageSize = ({ game, onChangeHandler }) => {
  const [inputValue, setInputValue] = useState(10);

  const onChange = (value: number) => {
    if (isNaN(value)) {
      return;
    }

    setInputValue(value);
  };

  return (
    <div>
      <Row>
        <h3>Adjust size of image to properly see on screen and don't forget to hit save!</h3>
      </Row>
      <Row className='container'>
        <img
          src={game.options.menuScreenOptions?.menuImage}
          alt=""
          style={{ width: `${inputValue}%`, height: `${inputValue}%`, }}
        />
      </Row>
      <Row>
        <Col span={12}>
          <Slider
            min={10}
            max={100}
            onChange={onChange}
            value={typeof inputValue === 'number' ? inputValue : 10}
            step={1}
          />
        </Col>
        <Col span={4}>
          <InputNumber
            min={10}
            max={100}
            style={{ margin: '0 16px' }}
            step={1}
            value={inputValue}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Button
          title={'Save'}
          onClick={() =>
            onChangeHandler({
              menuScreenOptions: {
                ...game.options.menuScreenOptions,
                imageWidth: `${inputValue}%`,
              },
            })
          }
        ></Button>
      </Row>
    </div>
  );
};

export default SliderImageSize;
