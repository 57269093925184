import React, { FunctionComponent } from 'react';
import { Modal as ModalAntd } from 'antd';
import './Modal.scss';
import { CloseOutlined } from '@ant-design/icons';

interface Props {
  isModalVisible: boolean;
  handleCancel: () => void;
  children: React.ReactNode;
  title?: React.ReactNode;
  width?: string | number;
}

export const Modal: FunctionComponent<Props> = ({
  isModalVisible,
  handleCancel,
  children,
  title,
  width,
}) => {
  return (
    <ModalAntd
      width={width || 800}
      title={
        <h2 className="font-bold text-center text-3xl pt-4">
          {title}
        </h2>
      }
      visible={isModalVisible}
      onCancel={handleCancel}
      closeIcon={<CloseOutlined className="text-gray-light" />}
      footer={null}
      className="rounded-2xl"
      bodyStyle={{borderRadius: '1rem'}}
    >
      {children}
    </ModalAntd>
  );
};

export default Modal;
