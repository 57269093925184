import React, { useState, FunctionComponent } from 'react';
import { Input, AutoComplete, SelectProps } from 'antd';

interface SearchProps {
  values: any[];
  onSelect: (value: string) => void;
  onReset: () => void;
}

const SearchBar: FunctionComponent<SearchProps> = ({
  values,
  onSelect,
  onReset,
}) => {
  const [options, setOptions] = useState<SelectProps<object>['options']>();
  const handleSearch = (value: string) => {
    onReset();
    let filtered = values.filter((val: any) =>
      val.name.toString().toLowerCase().includes(value.toLowerCase())
    );
    const filteredOptions = [];
    filtered.map((f: any) =>
      filteredOptions.push({
        value: f!.id,
        label: (
          <span>
            {f.name} (id: {f.id})
          </span>
        ),
      })
    );
    setOptions(filteredOptions);
  };

  return (
    <AutoComplete
      dropdownMatchSelectWidth={252}
      options={options}
      onSelect={onSelect}
      onSearch={handleSearch}
    >
      <Input.Search size="large" placeholder="Search" enterButton />
    </AutoComplete>
  );
};

export default SearchBar;
