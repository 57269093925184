import React, { FunctionComponent, useMemo, useState } from 'react';
import { GameDesignAssetsProps } from '.';
import EditColorCard from '../GameDesignCard/EditColorCard';
import EditTextCard from '../GameDesignCard/EditTextCard';
import EditOptionsCard from '../GameDesignCard/EditOptionsCard';
import { defaultOptions } from '@wiply/utils';

export const GameDesignAssetsWordle: FunctionComponent<GameDesignAssetsProps> = ({
  game,
  commonProps,
  onChangeHandler,
}) => {
  return (
    <div className="grid grid-cols-1 gap-5">
      <EditTextCard
        type="text"
        {...commonProps}
        title="Title"
        onSave={(fields: any) =>
          onChangeHandler({
            wordleOptions: {
              ...game.options.wordleOptions,
              ...fields,
            },
          })
        }
        items={{
          title: game.options.wordleOptions?.title || 'Guess the word',
        }}
        formItems={[
          {
            name: 'title',
            title: 'Enter Title for Wordle Game',
          },
        ]}
      />
      {/* <EditOptionsCard
            {...commonProps}
            title="Select language"
            value1="english"
            value2="spanish"
            defaultValue={game.options.wordleOptions?.language || 'english'}
            onChange={(field: string) =>
              onChangeHandler({
                wordleOptions: {
                  ...game.options.wordleOptions,
                  language: field,
                },
              })
            }
          /> */}
      <div className="flex flex-row gap-10 justify-center">
        <EditOptionsCard
          {...commonProps}
          title="Choose Difficulty"
          value1="easy"
          value2="normal"
          value3="hard"
          defaultValue={game.options.wordleOptions?.difficulty || 'normal'}
          onChange={(field: string) =>
            onChangeHandler({
              wordleOptions: {
                ...game.options.wordleOptions,
                difficulty: field,
              },
            })
          }
        />
        <EditColorCard
          hasAlpha={true}
          title="Wrong Tile Color"
          defaultValue={game.options.wordleOptions?.wrongTileColor || '#39393c'}
          onChange={(field: string) => {
            onChangeHandler({
              wordleOptions: {
                ...game.options.wordleOptions,
                wrongTileColor: field,
              },
            });
          }}
        />
        <EditColorCard
          hasAlpha={true}
          title="Empty Tile Color"
          defaultValue={game.options.wordleOptions?.emptyTileColor || '#bbbbbb'}
          onChange={(field: string) => {
            onChangeHandler({
              wordleOptions: {
                ...game.options.wordleOptions,
                emptyTileColor: field,
              },
            });
          }}
        />
      </div>
      <div className="flex flex-row gap-10 justify-center">
        <EditColorCard
          hasAlpha={true}
          title="Correct Tile Color"
          defaultValue={
            game.options.wordleOptions?.correctTileColor || '#538d4e'
          }
          onChange={(field: string) => {
            onChangeHandler({
              wordleOptions: {
                ...game.options.wordleOptions,
                correctTileColor: field,
              },
            });
          }}
        />
        <EditColorCard
          hasAlpha={true}
          title="Wrong Location Tile Color"
          defaultValue={
            game.options.wordleOptions?.wrongLocationTileColor || '#b59f3b'
          }
          onChange={(field: string) => {
            onChangeHandler({
              wordleOptions: {
                ...game.options.wordleOptions,
                wrongLocationTileColor: field,
              },
            });
          }}
        />
        <EditColorCard
          hasAlpha={true}
          title="Choose Active Tile Color"
          defaultValue={
            game.options.wordleOptions?.activeTileColor || '#545858'
          }
          onChange={(field: string) => {
            onChangeHandler({
              wordleOptions: {
                ...game.options.wordleOptions,
                activeTileColor: field,
              },
            });
          }}
        />
      </div>
    </div>
  );
};

export default GameDesignAssetsWordle;
