import React, {
  FunctionComponent,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import GamesContext from '../../context/games';
import EditColorCard from './../GameDesignCard/EditColorCard';
import EditImageCard from '../GameDesignCard/EditImageCard/EditImageCard';
import { Alert, Steps, message } from 'antd';
import { defaultBackgrounds, GameTypeEnum, hockeyFloor } from '@wiply/utils';
import GameDesignAssets from './../GameDesignAssets/index';
import Button from './../shared/Button';
import { BgColorsOutlined } from '@ant-design/icons';
import Modal from '../shared/Modal';
import GameCouponsTab from './GameCouponsTab';
import GameSubdomainTab from './GameSubdomainTab';
import { ClientGame } from '@wiply/repository';
import { CardActionItem } from '../shared/CardItem/CardActionItem';
import styles from './GameDesignTab.module.css';
import GameMenuTab from './GameMenuTab';
import { RootState } from '../../reducers';
import { useSelector } from 'react-redux';
import UploadVideo from '../GameDesignCard/UploadVideo';
import VideoModalButton from '../EmbedVideoModal';

const { Step } = Steps;

const success = () => {
  message.success({
    content: 'Saved!',
  });
};

interface Props {
  game?: ClientGame;
  iframeRef: any;
  refreshIframe: () => void;
}

export const GameDesignTab: FunctionComponent<Props> = ({ iframeRef, refreshIframe }) => {
  const { updateGame, uploadImage, game } = useContext(GamesContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [waring, setWaring] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(false);
  const currentUser = useSelector((state: RootState) => state.userReducer);

  const commonProps = {
    imagePath: `games/${game.id}`,
    uploadImage,
    updateGame,
    setUploadedImage,
  };

  useEffect(() => {
    setCurrentStep(0);
  }, [game.name]);

  useEffect(() => {
    if (uploadedImage && !game.options.labels?.uploadFile) {
      updateGame({
        ...game,
        options: {
          ...game.options,
          labels: {
            ...game.options.labels,
            uploadFile: true,
          },
        },
      });
      refreshIframe();
    }
  }, [uploadedImage, game]);

  const onChangeHandler = (field: any) => {
    updateGame({
      ...game,
      options: {
        ...game.options,
        ...field,
      },
    });
    success();
    refreshIframe();
  };

  const onChangeGameHandler = (field: any) => {
    updateGame({
      ...field,
    });
    success();
    refreshIframe();
  };

  const isHockey = game.type === GameTypeEnum.Hockey;

  const extraAction = useMemo(() => {
    if (isHockey) return null;
    return (
      <>
        <CardActionItem
          title="Color"
          icon={BgColorsOutlined}
          onClickHandler={() => setShowModal(true)}
          color="white"
        />
        <Modal
          title={
            <span className="block font-medium">Change Background Color</span>
          }
          handleCancel={() => setShowModal(false)}
          isModalVisible={showModal}
          width={300}
        >
          {waring && (
            <Alert
              message="Warning"
              description="The color was saved! But since you have a background image, you won't be able to see the background color. Remove the background image if you want to see it"
              type="warning"
              showIcon
              closable
              className="my-2 mb-6 rounded-3xl"
            />
          )}
          {/* Background Color */}
          <EditColorCard
            defaultValue={game.options.backgroundColor}
            onChange={(field: string) => {
              onChangeHandler({ backgroundColor: field });
              setWaring(!!game.options.bgImgUrl);
              setShowModal(false);
            }}
          />
        </Modal>
      </>
    );
  }, [showModal, setShowModal, game]);

  const BackgroundFields = useMemo(() => {
    const width = isHockey ? 1024 : 1920;
    const height = isHockey ? 1024 : 1080;
    return (
      <>
        {!game.options.bgVideoUrlDesktop && !game.options.bgVideoUrlMobile && (
          <>
            <div>
              <VideoModalButton videoSrc='https://res.cloudinary.com/shulgirit/video/upload/v1689503116/wiply/video%20tutorials/jesse-video-tutorials/Background-Tutorial.mp4'></VideoModalButton>
              <div className='mt-4'>

                <EditImageCard
                  {...commonProps}
                  title={isHockey ? 'Floor image' : 'Background'}
                  description={{
                    type: 'PNG/JPG/JPEG',
                    size: `${width}x${height}`,
                    aspectRatio: Number(width / height),
                    isOptional: true,
                    text: 'Background Image will step on the background color',
                  }}
                  accept="image/png, image/jpg, image/jpeg"
                  defaultValue={
                    isHockey
                      ? game.options.hockeyOptions?.floorImg
                      : game.options.bgImgUrl
                  }
                  onChange={(field: string) => {
                    const entity = isHockey
                      ? {
                        hockeyOptions: {
                          ...game.options.hockeyOptions,
                          floorImg: field,
                        },
                      }
                      : { bgImgUrl: field };
                    onChangeHandler(entity);
                  }}
                  defaultImagesToChoose={isHockey ? hockeyFloor : defaultBackgrounds}
                  extraActions={extraAction}
                  containerCSS="max-w-md"
                  setUploadedImage={setUploadedImage}
                />
              </div>
            </div>
          </>
        )}
        {!isHockey && (
          <>
            {!game.options.bgVideoUrlDesktop &&
              !game.options.bgVideoUrlMobile && (
                <EditImageCard
                  {...commonProps}
                  title={'Background Mobile'}
                  description={{
                    type: 'PNG/JPG/JPEG',
                    size: `${height}x${width}`,
                    aspectRatio: Number(height / width),
                    isOptional: true,
                    text: 'Background Image will step on the background color',
                  }}
                  accept="image/png, image/jpg, image/jpeg"
                  defaultValue={
                    game.options?.mobileBgImgUrl || game.options.bgImgUrl
                  }
                  onChange={(field: string) => {
                    const entity = { mobileBgImgUrl: field };
                    onChangeHandler(entity);
                  }}
                  defaultImagesToChoose={defaultBackgrounds}
                  extraActions={extraAction}
                  containerCSS="max-w-md"
                  setUploadedImage={setUploadedImage}
                  previewCss={styles.mobile}
                />
              )}
            {currentUser.isAdmin && (
              <EditImageCard
                {...commonProps}
                title={'Preloader Image'}
                description={{
                  type: 'PNG/JPG/JPEG/GIF',
                  size: `${width}x${height}`,
                  aspectRatio: Number(width / height),
                  isOptional: true,
                }}
                accept="image/png, image/jpg, image/jpeg, image/gif"
                defaultValue={
                  game?.preloader ||
                  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAANQTFRFAAAAp3o92gAAAAF0Uk5TAEDm2GYAAAAKSURBVHicY2AAAAACAAFIr6RxAAAAAElFTkSuQmCC'
                }
                onChange={(field: string) => {
                  const entity = { preloader: field };
                  onChangeGameHandler(entity);
                }}
                defaultImagesToChoose={defaultBackgrounds}
                containerCSS="max-w-md"
                setUploadedImage={setUploadedImage}
              />
            )}
            <UploadVideo
              title={'Upload Desktop Video'}
              uploadFile={commonProps.uploadImage}
              filePath={commonProps.imagePath}
              onChange={(field: string, isFileVideo?: boolean) => {
                // changes object property based on whether file is video or not
                var bg;
                if (isFileVideo) {
                  bg = { bgVideoUrlDesktop: field };
                } else {
                  bg = { bgImgUrl: field };
                }

                const entity = isHockey
                  ? {
                    hockeyOptions: {
                      ...game.options.hockeyOptions,
                      floorImg: field,
                    },
                  }
                  : bg;
                onChangeHandler(entity);
              }}
              defaultValue={game.options?.bgVideoUrlDesktop || null}
            />
            <UploadVideo
              title={'Upload Mobile Video'}
              uploadFile={commonProps.uploadImage}
              filePath={commonProps.imagePath}
              onChange={(field: string, isFileVideo?: boolean) => {
                // changes object property based on whether file is video or not
                var bg;
                if (isFileVideo) {
                  bg = { bgVideoUrlMobile: field };
                } else {
                  bg = { bgImgUrl: field };
                }

                const entity = isHockey
                  ? {
                    hockeyOptions: {
                      ...game.options.hockeyOptions,
                      floorImg: field,
                    },
                  }
                  : bg;
                onChangeHandler(entity);
              }}
              defaultValue={game.options?.bgVideoUrlMobile || null}
            />
          </>
        )}
      </>
    );
  }, [game, extraAction]);

  const amountSteps = 4;

  return (
    <div className="mt-6 lg:pr-10">
      <Steps
        current={currentStep}
        type="default"
        onChange={(current) => setCurrentStep(current)}
        responsive={true}
        initial={0}
        className="w-auto inline-flex "
      >
        <Step title="Background"></Step>
        <Step title="Menu Screen"></Step>
        <Step title="Game Design" />
        <Step title="Coupons"></Step>
        <Step title="Get link Url"></Step>
      </Steps>
      <div className="flex  mt-2  place-content-center   ">
        {currentStep > 0 && currentStep <= amountSteps && (
          <Button
            onClick={() => setCurrentStep(currentStep - 1)}
            title="< Previous"
          />
        )}
        {currentStep < amountSteps && (
          <Button
            title="Next >"
            className="ml-2"
            onClick={() => setCurrentStep(currentStep + 1)}
            style="inverted"
          />
        )}
      </div>
      {currentStep === 0 && (
        <div className="grid grid-auto-fit-23 gap-6 md:gap-12 mb-8 mt-16 rounded-2xl py-4 px-4	">
          {BackgroundFields}
        </div>
      )}
      {currentStep != 0 && (
        <div
          className={`grid grid-auto-fit-23 gap-6 md:gap-12 mb-8 mt-16 ${currentStep === 1 ||
            currentStep === 2 ||
            currentStep === 3 ||
            currentStep === 4
            ? 'lg:shadow-2xl'
            : ''
            } rounded-2xl	py-4 px-4`}
        >
          {currentStep === 1 && (
            <GameMenuTab
              commonProps={commonProps}
              game={game}
              onChangeHandler={onChangeHandler}
            />
          )}
          {currentStep === 2 && (
            <GameDesignAssets
              commonProps={commonProps}
              game={game}
              onChangeHandler={onChangeHandler}
            />
          )}
          {currentStep === 3 && <GameCouponsTab />}
          {currentStep === 4 && <GameSubdomainTab game={game} />}
        </div>
      )}
    </div>
  );
};

export default GameDesignTab;
