import React, { FunctionComponent, useContext, useState } from 'react';
import { Input } from 'antd';
import GamesContext from '../context/games';
import Button from './shared/Button';
import { ClientGame } from '@wiply/repository';

interface Props {
  game: ClientGame;
  handleCancel: () => void;
}

export const EditGameSettingsForm: FunctionComponent<Props> = React.memo(
  ({ game, handleCancel }) => {
    const { updateGame } = useContext(GamesContext);
    const [newName, setNewName] = useState(game.name);

    const updateGameHandler = async () => {
      updateGame({
        ...game,
        name: newName,
      });
      handleCancel();
    };

    return (
      <>
        <Input
          className="rounded-lg flex m-auto text-center h-16 text-xl font-bold"
          placeholder={game.name}
          onChange={(e) => setNewName(e.target.value)}
        />
        <div className="grid grid-cols-2 gap-4 m-auto mt-4">
          <Button title="Cancel" onClick={handleCancel} className="h-16" />
          <Button title="Save" onClick={updateGameHandler} style="inverted" className="h-16" />
        </div>
      </>
    );
  }
);

export default EditGameSettingsForm;
