import React, { FunctionComponent, useState, useContext } from 'react';
import { UploadOutlined, EyeOutlined } from '@ant-design/icons';
import { Upload, Space, message } from 'antd';
import Button from '../shared/Button';
import { ClientGame } from '@wiply/repository';
import GamesContext from '../../context/games';
import { FormTitles } from './EditImageCard/EditImageCard';
import { ninjaImages } from '@wiply/utils';

interface Props {
  setIsModalNinjaVisible?: any;
  formTitles?: FormTitles;
}

export const UploadFileForm: FunctionComponent<Props> = ({
  setIsModalNinjaVisible,
  formTitles,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { game, uploadImage, updateGame } = useContext(GamesContext);
  const [config, setConfig] = useState<ClientGame>(game);
  const success = () => {
    message.success({
      content: 'Saved!',
    });
  };

  const onChangeHandler = (field: any) => {
    updateGame({
      ...game,
      options: {
        ...game.options,
        ...field,
      },
    });
    success();
  };

  const updateImageHandler = async ({ file }, instance) => {
    setIsLoading(true);
    const uploadedFile: string = await uploadImage(file, `games/${game.id}`);

    if (uploadedFile) {
      const newConfig = { ...game };
      const options = { ...game.options.ninjaOptions };
      if (newConfig.options.ninjaOptions?.fruitsImages[instance])
        newConfig.options.ninjaOptions.fruitsImages[instance] = uploadedFile;
    //   else {
    //     onChangeHandler({
    //       ninjaOptions: {
    //         fruitsImages: {
    //           [instance]: uploadedFile,
    //         },
    //       },
    //     });
    //   }
      setConfig(newConfig);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Space
        direction="vertical"
        style={{ width: '100%' }}
        size="large"
        className="items-center"
      >
        {[...Array(5)].map((_, i) => {
          return (
            <div className="flex flex-row items-center gap-4" key={i}>
              <Upload
                name={formTitles[Object.keys(formTitles)[i]]}
                customRequest={(file) =>
                  updateImageHandler(
                    file,
                    formTitles[Object.keys(formTitles)[i]]
                  )
                }
                accept="application/json, .png"
                listType="picture"
                maxCount={1}
                showUploadList={false}
              >
                <Button
                  className="w-64"
                  title={`Upload ${
                    formTitles[Object.keys(formTitles)[i]]
                  } Image`}
                  icon={<UploadOutlined />}
                />
              </Upload>
              <img
                src={
                  game.options.ninjaOptions?.fruitsImages[
                    formTitles[Object.keys(formTitles)[i]]
                  ] ||
                  ninjaImages.fruitsImages[
                    formTitles[Object.keys(formTitles)[i]]
                  ]
                }
                width="60"
                height="60"
              ></img>
            </div>
          );
        })}

        <Button
          className={`w-full`}
          title={'Save'}
          style="inverted"
          onClick={() => {
            setIsModalNinjaVisible(false);
            updateGame(config);
          }}
        />
      </Space>
    </>
  );
};

export default UploadFileForm;
