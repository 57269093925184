export const url =
  'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/';

/*Generic*/
export const defaultBackgrounds = [...new Array(15)].map(
  (_, i) => `${url}background/${i}.png`
);

export const defalutCouponTemplates = [...new Array(3)].map(
  (_, i) => `${url}_Global/Template%20Coupons/${i}.svg`
);

/*Puzzle*/
export const puzzleImages = [...new Array(6)].map(
  (_, i) => `${url}puzzle/${i}.png`
);

/*Bricks*/
export const briksBallGallery = [...new Array(20)].map(
  (_, i) => `${url}Bricks/gallery/ball/${i}.png`
);
export const defalutBricksPrizes = [...new Array(20)].map(
  (_, i) => `${url}Bricks/gallery/prizes/${i}.png`
);
export const defaultBricksPaddle = [...new Array(8)].map(
  (_, i) => `${url}Bricks/gallery/paddle/${i}.png`
);
export const defaultBricksImage = [...new Array(6)].map(
  (_, i) => `${url}Bricks/gallery/brick/${i}.png`
);
export const bricksContainers = [...new Array(6)].map(
  (_, i) => `${url}Bricks/gallery/container/${i}.svg`
);

/*Tower*/
export const towerStage = [...new Array(2)].map(
  (_, i) => `${url}tower/stage/${i}.png`
);
export const towerBlock = [...new Array(2)].map(
  (_, i) => `${url}tower/main/${i}.png`
);

export const towerHook = [...new Array(2)].map(
  (_, i) => `${url}tower/hook/${i}.png`
);

/*Basketball*/
export const ballGallery = [...new Array(11)].map(
  (_, i) => `${url}basketball/gallery/ball/${i}.png`
);

export const boardImages = [...new Array(1)].map(
  (_, i) => `${url}games/unnamed_7_mnbjxi.png`
);

/*Hockey*/
export const hockeyFloor = [...new Array(3)].map(
  (_, i) => `${url}hockey/gallery/floor/${i}.jpg`
);
export const hockeyTables = [...new Array(7)].map(
  (_, i) => `${url}hockey/gallery/table/${i}.png`
);

/*Cups*/
export const cupsBoard = [...new Array(7)].map(
  (_, i) => `${url}cups/gallery/board/${i}.png`
);
export const cupsGlb = [...new Array(5)].map(
  (_, i) => `${url}cups/gallery/glb/${i}.glb`
);

/*Ninja*/
export const ninjaTitle = [...new Array(3)].map(
  (_, i) => `${url}ninja/gallery/title/${i}.png`
);
export const ninjaRing = [...new Array(2)].map(
  (_, i) => `${url}ninja/gallery/ring/${i}.png`
);
export const ninjaBomb = [...new Array(6)].map(
  (_, i) => `${url}ninja/gallery/bomb/${i}.png`
);
export const ninjaScoreBoard = {
  scoreBoard1: `${url}ninja/scoreBoard/scoreboard1_sdwmex.png`,
  scoreBoard2: `${url}ninja/scoreBoard/scoreboard2_hvaysg.png`,
  scoreBoard3: `${url}ninja/scoreBoard/scoreboard3_b0rqz1.png`,
  scoreBoard4: `${url}ninja/scoreBoard/scoreboard4_a9revg.png`,
};
export const ninjaScoreCoupon = [
  `${url}ninja/SCORE_0-10.png`,
  `${url}ninja/SCORE_10-20.png`,
  `${url}ninja/SCORE_20-30.png`,
  `${url}ninja/SCORE_30.png`,
];
export const ninjaStrikes = {
  gamelife0: `${url}ninja/strikes/1.png`,
  gamelife1: `${url}ninja/strikes/2.png`,
  gamelife2: `${url}ninja/strikes/3.png`,
  gamelife3: `${url}ninja/strikes/4.png`,
};

export const getNinjaGalleryByType = (type: string) => {
  const galleryUrl = `${url}ninja/gallery/fruits/${type}/`;
  return {
    particles: `${galleryUrl}particles.png`,
    sliceA: `${galleryUrl}sliceA.png`,
    sliceB: `${galleryUrl}sliceB.png`,
    splash: `${galleryUrl}splash.png`,
    whole: `${galleryUrl}whole.png`,
  };
};

export const ninjaFruitsGallery = [
  { heart: { ...getNinjaGalleryByType('heart') } },
  { basketball: { ...getNinjaGalleryByType('basketball') } },
  { soccer: { ...getNinjaGalleryByType('soccer') } },
  { violet: { ...getNinjaGalleryByType('violet') } },
  { coffee: { ...getNinjaGalleryByType('coffee') } },
];

export const ninjaImages = {
  scoreBoard: ninjaScoreBoard,
  strikes: ninjaStrikes,
  newGameRingImg: ninjaRing[0],
  titleImg: ninjaTitle[0],
  bombImg: ninjaBomb[0],
  fruitsImages: { ...getNinjaGalleryByType('basketball') },
};

//Memory Game //
const getMemoryCardImagesByType = (type: string) =>
  [...new Array(6)].map((_, i) => `${url}memory/images/${type}/${i}.png`);

export const memoryImages = {
  cover: `${url}memory/cover.svg`,
  cardImages: getMemoryCardImagesByType('wiply'),
};

export const memoryGallery = [
  ...getMemoryCardImagesByType('wiply'),
  ...getMemoryCardImagesByType('sports'),
  ...getMemoryCardImagesByType('flowers'),
];
//Wheel//
export const defaultWheelPrizes = [
  `${url}wheel/free_shipping_ykt49k.png`,
  `${url}wheel/5__fg54he.png`,
  `${url}wheel/20__etivjy.png`,
  `${url}wheel/gift_u5aed9.png`,
  `${url}wheel/tax_free_ftvgua.png`,
  `${url}wheel/15__ydagrh.png`,
  `${url}wheel/free_shipping_ykt49k.png`,
  `${url}wheel/5__fg54he.png`,
  `${url}wheel/20__etivjy.png`,
  `${url}wheel/gift_u5aed9.png`,
];

export const defaultWheelColor = [
  `${url}/wheel/wheel-base-purple.png`,
  `${url}/wheel/wheel-base.png`,
  `${url}/wheel/wheel-base-orange.png`,
  `${url}/wheel/wheel-base-green.png`,
  `${url}/wheel/wheel-base-pink.png`,
  `${url}/wheel/wheel-base-yellow.png`,
  `${url}/wheel/wheel-base-red.png`,
];

export const wheelTitleImages = [...new Array(1)].map(
  (_, i) => `${url}games/logo_mrpiai_jdpj6f.png`
);

export const companyImages = [...new Array(1)].map(
  (_, i) => `${url}wheel/${i}.png`
);

//Quiz//
export const defaultQuizThemes = [
  `${url}/quiz/atlas/0.png`,
  `${url}/quiz/atlas/1.png`,
  `${url}quiz/atlas/2.png`,
  `${url}quiz/atlas/3.png`,
  `${url}quiz/atlas/4.png`,
  `${url}quiz/atlas/5.png`,
  `${url}quiz/atlas/6.png`,
  `${url}quiz/atlas/7.png`,
];

export const QuizQuestionImages = [...new Array(4)].map(
  (_, i) => `${url}quiz/questions/${i}.png`
);

export const QuizLivesImages = [...new Array(4)].map(
  (_, i) => `${url}quiz/hearts/${i}.png`
);

export const QuizAnswerBarImages = [...new Array(4)].map(
  (_, i) => `${url}quiz/clean/${i}.png`
);

export const QuizCorrectBarImages = [...new Array(4)].map(
  (_, i) => `${url}quiz/correct/${i}.png`
);

export const QuizIncorrectBarImages = [...new Array(4)].map(
  (_, i) => `${url}quiz/wrong/${i}.png`
);

//Gems//
export const bagImages = [...new Array(1)].map(
  (_, i) => `${url}/games/bag_fnr3qa_moterg.png`
);

export const assetImages = [...new Array(4)].map(
  (_, i) => `${url}games/unnamed_6_z2zu58.png`
);

//Dropit//
export const brandLogoImages = [...new Array(1)].map(
  (_, i) => `${url}games/unnamed_7_mnbjxi.png`
);

//Coupons//
export const defaultLostCoupons = [
  `${url}/_Global/Lost%20Coupon/LOST_CUPONS-06_fjvnzq.svg`,
  `${url}/_Global/Lost%20Coupon/LOST_CUPONS-07_hfuc77.svg`,
  `${url}/_Global/Lost%20Coupon/LOST_CUPONS-03_yco4ke.svg`,
  `${url}/_Global/Lost%20Coupon/LOST_CUPONS-02_mrs79j.svg`,
  `${url}/_Global/Lost%20Coupon/LOST_CUPONS-01_aqzouj.svg`,
  `${url}/_Global/Lost%20Coupon/LOST_CUPONS-04_geo7g7.svg`,
  `${url}/_Global/Lost%20Coupon/LOST_CUPONS-05_dhc0n4.svg`,
];

export const defaultWinningCoupons = [
  `${url}/_Global/Winning%20Coupon/Heart_free_shipping.svg`,
  `${url}/_Global/Winning%20Coupon/Heart_25.svg`,
  `${url}/_Global/Winning%20Coupon/Heart_20.svg`,
  `${url}/_Global/Winning%20Coupon/Heart_15.svg`,
  `${url}/_Global/Winning%20Coupon/Heart_10.svg`,
  `${url}/_Global/Winning%20Coupon/Gray_10.svg`,
  `${url}/_Global/Winning%20Coupon/Gray_15.svg`,
  `${url}/_Global/Winning%20Coupon/Gray_20.svg`,
  `${url}/_Global/Winning%20Coupon/Gray_25_C.svg`,
  `${url}/_Global/Winning%20Coupon/Gray_Free_Shipping.svg`,
  `${url}/_Global/Winning%20Coupon/milk_10.svg`,
  `${url}/_Global/Winning%20Coupon/milk_15.svg`,
  `${url}/_Global/Winning%20Coupon/milk_20.svg`,
  `${url}/_Global/Winning%20Coupon/milk_25.svg`,
  `${url}/_Global/Winning%20Coupon/milk_free_shipping.svg`,
];

export const defaultDropitCouponsBottom = [
  `${url}/dropit/TAX_FREE_chheek.png`,
  `${url}/dropit/20_qkmdee.png`,
  `${url}/dropit/10_sjh80q.png`,
];

export const defaultDropitSvg = [
  `${url}/dropit/Light-Blue-01_a0apaf.svg`,
  `${url}/dropit/Red-01_oc1eil.svg`,
  `${url}/dropit/Purple_iujuyl.svg`,
  `${url}/dropit/Pink_otk9g1.svg`,
  `${url}/dropit/Orange-01_pg0mgn.svg`,
  `${url}/dropit/Green-01_mpqqmt.svg`,
];

export const defaultWinningCoupon = `${url}defaultCoupon_win_new.svg`;
export const defaultLoosingCoupon = `${url}defaultCoupon_loose.svg`;

export const defaultEmbedImages = [...new Array(8)].map(
  (_, i) => `${url}embed-gallery/${i}.png`
);