import React, { FunctionComponent, useState } from 'react';
import { ShareAltOutlined, CopyFilled, CheckOutlined } from '@ant-design/icons';
import { ShareList } from './ShareList';
import Paragraph from 'antd/lib/typography/Paragraph';

interface Props {
  previewLink: string;
  className?: string;
  wrapperClassName?: string;
}

const ShareLink: FunctionComponent<Props> = ({
  previewLink,
  className,
  wrapperClassName,
}) => {
  const [showCopyLinkModal, setShowCopyLinkModal] = useState(false);
  return (
    <div className={`gradient p-3 ${className} share-widget`}>
      <div
        className={`${
          !showCopyLinkModal ? 'cursor-pointer' : 'flipped'
        } flex justify-around items-center ${wrapperClassName} text-white share-widget-sub`}
      >
        {!showCopyLinkModal && (
          <div
            className="flex items-center gap-2 share-widget-title"
            onClick={() => setShowCopyLinkModal(true)}
          >
            <ShareAltOutlined /> Share this game{' '}
          </div>
        )}
        {showCopyLinkModal && (
          <>
            <ShareList url={previewLink} />
            <Paragraph
              copyable={{
                text: previewLink,
                icon: [
                  <CopyFilled key="copy-icon" className="text-white" />,
                  <CheckOutlined key="copied-icon" className="text-white" />,
                ],
              }}
              className="flex gap-2 text-white"
            >
              <span className="mt-1">Copy link</span>
            </Paragraph>
          </>
        )}
      </div>
    </div>
  );
};

export default ShareLink;
