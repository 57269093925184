import React, { FunctionComponent } from 'react';

interface PropsCardAction {
  list: React.ReactNode;
  styles?: string;
}

const CardAction: FunctionComponent<PropsCardAction> = ({ list, styles }) => (
  <ul
    className={`bg-white divide-y divide-gray-light flex flex-col justify-center border-l border-gray-light ${styles}`}
  >
    {list}
  </ul>
);

export default CardAction;
