import { ClientGame } from '@wiply/repository';
import React, { FunctionComponent, memo } from 'react';
import Spinner from '../shared/Spinner';
import GamesTable from './GamesTable';

interface Props {
  games: ClientGame[];
}

const RowDetail: FunctionComponent<Props> = memo(({ games }) => {
  if (!games) return <Spinner />;
  if (games.length === 0) return <p>The client does not have games yet</p>;
  return <GamesTable games={games} />;
});

export default RowDetail;
