import React, { FunctionComponent, useEffect, useState } from 'react';
import { CouponSettings, GameCoupon } from '@wiply/repository';
import SVGCoupon from './Template/SVGCoupon';
import TemplateForm from './Forms/TemplateForm';
import Button from '../shared/Button';
import {
  couponTemplate,
  couponTemplateLoose,
  defalutCouponTemplates,
} from '@wiply/utils';
import { Divider, Radio, Alert } from 'antd';
import styles from './CustomCoupon.module.css';

interface Props {
  coupon: GameCoupon;
  updateImageHandler: ({ file }, config) => Promise<void>;
  loosingCoupon?: boolean;
}

export const CustomCoupon: FunctionComponent<Props> = ({
  coupon,
  updateImageHandler,
  loosingCoupon,
}) => {
  const [templateCoupon, setTemplateCoupon] = useState<SVGElement>();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<boolean>(false);
  const customTemplateDefault = loosingCoupon
    ? { ...couponTemplateLoose }
    : { ...couponTemplate };
  const [config, setConfig] = useState<CouponSettings>((coupon.background || coupon.title || coupon.discount || coupon.template || coupon.background) ?
    coupon : customTemplateDefault
  );


  const setDiscountColor = (color: string) =>
    setConfig({
      ...config,
      discount: {
        ...config.discount,
        color,
      },
    });

  useEffect(() => {
    if (
      config.template === 2 &&
      config.discount.color === '#ffffff'
    ) {
      setDiscountColor('#000000');
    }
  }, [config.template]);

  useEffect(() => {
    if (coupon.emailCoupon) {
      setConfig({ ...config, template: 2 });

    }
  }, [coupon.emailCoupon]);

  const createTemplateHandler = async () => {
    setIsLoading(true);
    if (templateCoupon) {
      const file = new File([templateCoupon.outerHTML], 'customSvg', {
        type: 'image/svg+xml',
      });
      await updateImageHandler({ file }, config);

      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={`flex-col lg:flex-row  ${styles.left}`}>
        <div className="w-full">
          <h2 className="text-center mt-4 mb-2">Design your custom Coupon</h2>
          <div className="bg-white rounded-xl p-4">
            <TemplateForm
              config={config}
              setConfig={setConfig}
              setAlert={setAlert}
            />
          </div>
        </div>

        <div className={`w-full ${styles.stickeypreview}`}>
          <h2 className="text-center mt-4 mb-2"> Coupon Preview</h2>

          <div className="bg-white p-4 rounded-xl">
            {coupon.gameImgUrl && (
              <>
                <h2 className="text-xs text-center mb-2">Coupon for prize</h2>
                <img
                  src={coupon.gameImgUrl}
                  className="m-auto filter drop-shadow-3xl"
                />
                <Divider />
              </>
            )}

            <SVGCoupon
              getSVG={(svg: SVGElement) => setTemplateCoupon(svg)}
              couponCode={coupon.code}
              config={config}
              loosingCoupon={loosingCoupon}
            />
            <div className={`flex justify-center gap-4 mt-4 `}>
              <Button
                className={`${alert ? ' animate-bounce	 ' : ''}`}
                title={isLoading ? 'Loading...' : 'Save'}
                style="inverted"
                onClick={() => {
                  createTemplateHandler();
                  setAlert(false);
                }}
              />
              <Button
                title="Reset"
                onClick={() => {
                  setAlert(false);
                  setConfig(customTemplateDefault);
                }}
              />
            </div>
            {alert && (
              <div className="mt-2">
                <Alert
                  message="Dont forget to save your changes"
                  type="error"
                />
              </div>
            )}
          </div>
        </div>
        {!coupon.emailCoupon && (
        <div className={`${styles.stickeypreview2}`}>
          <h2 className="text-center mt-4 mb-2">Template</h2>
          <Radio.Group
            onChange={(e) => {
              setConfig({ ...config, template: e.target.value });
              setAlert(true);
            }}
            value={config.template}
            className="flex flex-row lg:flex-col gap-4"
          >
            {defalutCouponTemplates.map((svg, i) => (
              <div className="my-2 m-auto sm:m-0" key={i}>
                <Radio value={i}>
                  <img
                    className={`max-w-xs `}
                    width="50"
                    src={svg}
                    alt="preview"
                  />
                </Radio>
              </div>
            ))}
          </Radio.Group>
          </div>)}
      </div>
    </>
  );
};

export default CustomCoupon;
