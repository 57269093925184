import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { DescriptionField } from './EditImageCard';
import { Switch } from 'antd';
import EditableTabs from '../../EditableTabs';

interface EditFieldProps {
  options: any;
  instance: any;
  instances: any[];
  commonProps: any;
  onChangeHandler: (field: object) => void;
  title: string;
  description: DescriptionField;
  defaultImages?: any;
  groupInstance: string;
  gallery?: any;
  extraContent?: React.ReactNode;
}

const EditMultipleImagesCard: FunctionComponent<EditFieldProps> = ({
  options,
  commonProps,
  onChangeHandler,
  instances,
  instance,
  title,
  description,
  defaultImages,
  groupInstance,
  gallery,
  extraContent,
}) => {
  const [differentPictures, setDifferentPictures] = useState(
    options?.differentPictures || false
  );

  const updateDifferentPictures = (differentPictures) => {
    onChangeHandler({
      memoryOptions: {
        ...options,
        differentPictures,
      },
    });
  };

  return (
    <div>
      <article className="bg-white shadow-lg rounded-lg">
        <div className="pt-4">
          <div className="flex items-center justify-evenly text-md font-medium ">
            <h3>{title}</h3>
            <Switch
              className="pl-px-2"
              unCheckedChildren="Same Pictures"
              checkedChildren="Different Pictures"
              onClick={() => {
                setDifferentPictures(!differentPictures)
                updateDifferentPictures(!differentPictures)
              }}
              defaultChecked={options?.differentPictures}
            />
          </div>
          {extraContent}

          {differentPictures ? (
            <EditableTabs
              options={options}
              commonProps={commonProps}
              onChangeHandler={onChangeHandler}
              groupInstance={groupInstance}
              instance={'differentPicsObject'}
              differentPictures={differentPictures}
            />
          ) : (
            <EditableTabs
              options={options}
              commonProps={commonProps}
              onChangeHandler={onChangeHandler}
              groupInstance={groupInstance}
              instance={'cardImages'}
              description={description}
              differentPictures={differentPictures}
              gallery={gallery}
            />
          )}
        </div>
      </article>
    </div>
  );
};

export default EditMultipleImagesCard;
