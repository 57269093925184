import React, { useState, useEffect } from 'react';
import { AuthService } from '@wiply/firesbase';
import firebase from 'firebase';

interface AuthContextInterface {
  user: firebase.User | null;
  login: (email: string, password: string) => Promise<any>;
  logout: () => void;
}

const userLocalStorage = JSON.parse(localStorage.getItem('user'));

const AuthContext = React.createContext<AuthContextInterface>({
  user: userLocalStorage || null,
  login: async (email: string, password: string) => {},
  logout: () => {},
});

const authService = new AuthService();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(userLocalStorage as firebase.User | null);

  useEffect(() => {
    const unsubscribe = authService.onAuthStateChange(setUser);
    return () => unsubscribe();
  }, []);

  const loginHandler = async (email: string, password: string) => {
    const login = await authService.signInWithEmailAndPassword(email, password);
    setUser(authService.user);
    localStorage.setItem('user', JSON.stringify(authService.user));
    return login;
  };

  const logoutHandler = async () => {
    await authService.logOut();
    setUser(null);
    localStorage.removeItem('user');
  };

  const context = {
    user,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
