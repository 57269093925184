import React, { FunctionComponent, useMemo } from 'react';
import EditImageCard from '../GameDesignCard/EditImageCard/EditImageCard';
import { GameDesignAssetsProps } from '.';
import EditTextCard from '../GameDesignCard/EditTextCard';
import EditPrizesCard from '../GameDesignCard/EditPrizesCard';
import { defaultWheelPrizes, defaultWheelColor, wheelTitleImages, companyImages } from '@wiply/utils';
import EditColorCard from '../GameDesignCard/EditColorCard';
import { EditColorAction } from '../GameDesignCard/EditColorAction';

export const GameDesignAssetsWheel: FunctionComponent<GameDesignAssetsProps> = ({
  game,
  commonProps,
  onChangeHandler,
}) => {
  const extraAction = useMemo(() => {
    return (
      <>
        <EditColorAction
          hasAlpha={true}
          title="Change Color1"
          onChangeHandler={(field) => {
            let wheelColors = [...game.options.wheelColors];
            wheelColors[0] = field;
            onChangeHandler({ wheelColors });
          }}
          defaultValue={game.options.wheelColors[0]}
        />
        <EditColorAction
          hasAlpha={true}
          title="Change Color2"
          onChangeHandler={(field) => {
            let wheelColors = [...game.options.wheelColors];
            wheelColors[1] = field;
            onChangeHandler({ wheelColors });
          }}
          defaultValue={game.options.wheelColors[1]}
        />
      </>
    );
  }, [game]);

  return (
    <>
      <EditTextCard
        {...commonProps}
        type="text"
        title="Text"
        onSave={(fields: any) =>
          onChangeHandler({
            wheelOptions: {
              ...game.options.wheelOptions,
              ...fields,
            },
          })
        }
        items={{
          headline: game.options.wheelOptions.headline || 'Fortune Wheel',
          subtitle: game.options.wheelOptions.subtitle || 'Spin and Win',
          buttonText: game.options.wheelOptions.buttonText || 'Press to Play',
        }}
        formItems={[
          {
            name: 'headline',
            title: 'Text for Headline Title',
          },
          {
            name: 'subtitle',
            title: 'Subtitle',
          },
          {
            name: 'buttonText',
            title: 'Call to Action Text',
          },
        ]}
      />
      <EditImageCard
        {...commonProps}
        title="Title Image"
        description={{
          type: 'JPG/PNG',
        }}
        accept="image/png, image/jpg"
        defaultValue={game.options.wheelOptions?.logo}
        onChange={(field: string) =>
          onChangeHandler({
            wheelOptions: {
              ...game.options.wheelOptions,
              logo: field,
            },
          })
        }
        defaultImagesToChoose={wheelTitleImages}
      />
      <EditImageCard
        {...commonProps}
        title="Company Image"
        description={{
          type: 'JPG/PNG',
        }}
        accept="image/png, image/jpg"
        defaultValue={game.options.wheelOptions?.image}
        onChange={(field: string) =>
          onChangeHandler({
            wheelOptions: {
              ...game.options.wheelOptions,
              image: field,
            },
          })
        }
        defaultImagesToChoose={companyImages}
      />
      <EditImageCard
        {...commonProps}
        title="Choose Wheel"
        description={{}}
        removeDelete={true}
        disableUpload={true}
        accept="image/png, image/jpg"
        defaultValue={game.options.wheelOptions?.base}
        onChange={(field: string) =>
          onChangeHandler({
            wheelOptions: {
              ...game.options.wheelOptions,
              base: field,
            },
          })
        }
        defaultImagesToChoose={defaultWheelColor}
        extraActions={extraAction}
      />
      <EditPrizesCard
        game={game}
        defaultImages={defaultWheelPrizes}
        commonProps={commonProps}
        previewCss={"filter brightness-50"}
      />
    </>
  );
};

export default GameDesignAssetsWheel;
