import React, { FunctionComponent, useState, useMemo } from 'react';
import CardActionItem from '../shared/CardItem/CardActionItem/index';
import CardItem from '../shared/CardItem/index';
import { CheckSquareOutlined } from '@ant-design/icons';
import { Input } from 'antd';

interface EditFieldProps {
  title: string;
  description?: string;
  onSave: (fields: any) => void;
  defaultValue?: string;
  formItems?: any[];
  items: any;
  type?: string;
  textArea?: boolean;
}

const EditTextCard: FunctionComponent<EditFieldProps> = ({
  title,
  onSave,
  defaultValue,
  formItems,
  items,
  type,
}) => {
  const [newItems, setNewItems] = useState(items);
  const { TextArea } = Input;

  const content = useMemo(() => {
    return (
      <div className="my-2">
        <h3 className="mt-2 text-md font-bold text-center">{title}</h3>
        <div>
          {formItems.map(({ name, title }) => (
            <div className="mx-8 my-2 w-4/5" key={name}>
              <label>{title}</label>
              {type === 'text' && (
                <Input
                  defaultValue={newItems[name]}
                  name={name}
                  className="rounded-xl my-1"
                  onChange={(e: { target: HTMLInputElement }) =>
                    setNewItems({
                      ...newItems,
                      [e.target.name]: e.target.value,
                    })
                  }
                  type={type || 'text'}
                />
              )}
              {type === 'TextArea' && (
                <TextArea
                  rows={4}
                  defaultValue={newItems[name]}
                  name={name}
                  className="rounded-xl my-1"
                  onChange={(e: { target: HTMLTextAreaElement }) => {
                    setNewItems({
                      ...newItems,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }, [defaultValue, newItems, setNewItems]);

  return (
    <CardItem
      actions={
        <CardActionItem
          title="Save"
          icon={CheckSquareOutlined}
          onClickHandler={() => onSave(newItems)}
          color="white"
        />
      }
      actionsStyle="gradient"
    >
      {content}
    </CardItem>
  );
};

export default EditTextCard;
