import React, { FunctionComponent, useEffect, useState } from 'react';

import { Player } from '@wiply/repository';
import { PlayerRepository } from '@wiply/firesbase';

interface PlayersContexProviderInterface {
  children: React.ReactNode;
  gameId: string;
}

interface PlayersContextInterface {
  players: Player[];
}

const PlayersContext = React.createContext<PlayersContextInterface>({
  players: [],
});

export const PlayersContextProvider: FunctionComponent<PlayersContexProviderInterface> = ({
  children,
  gameId,
}) => {
  const playerRepository = new PlayerRepository(gameId);
  const [players, setPlayers] = useState<Player[]>();

  useEffect(() => {
    const getPlayersHandler = async () => {
      // here should fetch player by batches to reduce read operations
      const players = await playerRepository.fetchAll();
      setPlayers(players);
    };

    getPlayersHandler();
  }, []);

  const context = {
    players,
  };

  return (
    <PlayersContext.Provider value={context}>
      {children}
    </PlayersContext.Provider>
  );
};

export default PlayersContext;
