import React, { FunctionComponent, memo } from 'react';

interface Props {
  text: string;
  className?: string;
}

export const Title: FunctionComponent<Props> = memo(({ text, className }) => (
  <h2
    className={`text-2xl font-bold border-b border-gray-light pb-2 ${className}`}
  >
    {text}
  </h2>
));

export default Title;
