import React, { FunctionComponent } from 'react';
import EditImageCard from '../GameDesignCard/EditImageCard/EditImageCard';
import { puzzleImages } from '@wiply/utils';
import { GameDesignAssetsProps } from '.';
import EditTextCard from '../GameDesignCard/EditTextCard';
import EditOptionsCard from '../GameDesignCard/EditOptionsCard';
import VideoModalButton from '../EmbedVideoModal';


export const GameDesignAssetsPuzzle: FunctionComponent<GameDesignAssetsProps> = ({
  game,
  commonProps,
  onChangeHandler,
}) => {
  return (
    <>
      <div>
        <VideoModalButton videoSrc='https://res.cloudinary.com/shulgirit/video/upload/v1689503784/wiply/video%20tutorials/jesse-video-tutorials/Puzzle-Tutorial.mp4'></VideoModalButton>
        <div className="mt-4">
          <EditImageCard
            {...commonProps}
            title="Puzzle Image"
            description={{
              type: 'JPG/PNG',
              size: '1620X1080',
              aspectRatio: Number(1620 / 1080),
            }}
            accept="image/png, image/jpg"
            defaultValue={game.options.puzzleOptions?.puzzleImg || puzzleImages[0]}
            onChange={(field: string) =>
              onChangeHandler({
                puzzleOptions: {
                  ...game.options.puzzleOptions,
                  puzzleImg: field,
                },
              })
            }
            defaultImagesToChoose={puzzleImages}
          />
        </div>
      </div>
      <EditTextCard
        type="text"
        {...commonProps}
        title="Difficulty"
        onSave={(fields: any) =>
          onChangeHandler({
            puzzleOptions: {
              ...game.options.puzzleOptions,
              ...fields,
            },
          })
        }
        items={{
          puzzleTime: game.options.puzzleOptions.puzzleTime || '50',
        }}
        formItems={[
          {
            name: 'puzzleTime',
            title: 'Puzzle CountDown Time (max 60 seconds)',
          },
        ]}
      />
      <EditOptionsCard
        {...commonProps}
        title="Choose Difficulty"
        value1="easy"
        value2="medium"
        value3="hard"
        defaultValue={game.options.puzzleOptions?.level || 'easy'}
        onChange={(field: string) =>
          onChangeHandler({
            puzzleOptions: {
              ...game.options.puzzleOptions,
              level: field,
            },
          })
        }
      />
    </>
  );
};

export default GameDesignAssetsPuzzle;
