import React, { FunctionComponent, useState, useMemo, useEffect } from 'react';
import CardActionItem from '../shared/CardItem/CardActionItem';
import CardItem from '../shared/CardItem/index';
import { CheckSquareOutlined, ReloadOutlined } from '@ant-design/icons';
import { ChromePicker, SketchPicker } from 'react-color';
import rgbHex from 'rgb-hex';

interface EditFieldProps {
  title?: string;
  description?: string;
  onChange: (field: string) => void;
  defaultValue?: string;
  className?: string;
  hasAlpha?: boolean;
  returnRGB?: boolean;
}

const EditColorCard: FunctionComponent<EditFieldProps> = ({
  title,
  onChange,
  defaultValue,
  className,
  hasAlpha,
  returnRGB,
}) => {
  const [color, setColor] = useState<any>(
    { hex: defaultValue } || { hex: '#FFFFFF' }
  );
  const [saveColor, setSaveColor] = useState(false);

  useEffect(() => {
    if (saveColor) onChange(returnRGB ? color.rgb : color.hex);
  }, [saveColor]);

  // const onChangeHandler = (color: string) => {
  //   setSaveColor(false);
  //   setColor(("#" + rgbHex(color, c.rgb.g, c.rgb.b, c.rgb.a));
  // };

  const resetHandler = () => {
    onChange(null);
    setColor('#FFFFFF');
  };

  const content = useMemo(() => {
    return (
      <div className="my-2">
        <h3 className="mt-2 text-md font-medium text-center">{title}</h3>
        <ChromePicker
          className="m-auto my-2"
          color={color.hex}
          onChange={(c) => {
            setSaveColor(false);
            {
              hasAlpha &&
                setColor({
                  ...c,
                  hex: '#' + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a),
                });
            }
            {
              !hasAlpha && setColor(c);
            }
          }}
          disableAlpha={hasAlpha ? 'false' : 'true'}
          style={{ width: '155px' }}
        />
      </div>
    );
  }, [defaultValue, color]);

  return (
    <CardItem
      actions={
        <>
          <CardActionItem
            title="Save"
            icon={CheckSquareOutlined}
            onClickHandler={() => setSaveColor(true)}
            color="white"
            justifyContent="justify-center"
          />
        </>
      }
      className={className}
      actionsStyle="gradient"
      column={true}
    >
      {content}
    </CardItem>
  );
};

export default EditColorCard;
