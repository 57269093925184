import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { environment } from '@env/environment';
import { ReloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import Button from './shared/Button';
import { useParams } from 'react-router-dom';

const isMobile = window?.innerWidth <= 815;

interface Props {
  id: string;
  background: string;
  placeholderBackground: string;
  className?: string;
  iframeRef: any;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const GamePreviewComponent: FunctionComponent<Props> = ({
  id,
  background,
  placeholderBackground,
  className,
  iframeRef,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [key, setKey] = useState(Date.now()); // new state for the key

  const handleLoad = () => {
    setIsLoading(false);
  };

  const refreshIframe = () => {
    setIsLoading(true);
    setKey(Date.now()); // change the key which will cause re-render

    // get loading to turn off after 2 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  useEffect(() => {
    // Simulating a delay for demonstration purposes
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(loadingTimer);
  }, []);

  return (
    <>
      <ReloadOutlined
        className="rounded-full p-2 shadow-2xl mb-4 place-self-end border-0	  mr-8 text-3xl text-violet"
        onClick={refreshIframe}
      />

      <div
        className="bg-no-repeat h-full w-full flex"
        style={
          !isMobile
            ? {
              backgroundImage: `url("https://res.cloudinary.com/shulgirit/image/upload/v1641733628/wiply/${background}.png")`,
              backgroundSize: '100% 100%',
            }
            : {}
        }
      >
        <div
          key={key} // using the key here
          className={`${className} m-auto`}
          style={{ width: '100%', height: '100%' }}
          id='game-preview'
        >
          {isLoading ? (
            <div className="loading-screen flex justify-center items-center w-full h-full">
              <Spin indicator={antIcon} />
            </div>
          ) : (
            <object
              id='game-preview-iframe'
              ref={iframeRef}
              data={`https://${id}.${environment.appsUrls.clientNext}`}
              className={`overflow-auto w-full h-full transform scale-90`}
              style={{
                backgroundSize: 'cover',
                backgroundColor: 'white',
              }}
              onLoad={handleLoad}
            ></object>
          )}
        </div>
      </div>
    </>
  );
};

export default GamePreviewComponent;
