import React, { FunctionComponent } from 'react';
import Icon from '@ant-design/icons';

const UserSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.895"
    height="13.594"
    viewBox="0 0 11.895 13.594"
  >
    <path
      data-name="Icon awesome-user"
      d="M5.947 6.8a3.4 3.4 0 1 0-3.4-3.4 3.4 3.4 0 0 0 3.4 3.4zm2.379.85h-.443a4.622 4.622 0 0 1-3.871 0h-.444A3.569 3.569 0 0 0 0 11.215v1.1a1.275 1.275 0 0 0 1.274 1.274h9.346a1.275 1.275 0 0 0 1.274-1.274v-1.1a3.569 3.569 0 0 0-3.568-3.569z"
      style={{ fill: '#8131c6' }}
    />
  </svg>
);

const UserSvgWarning = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.895"
    height="13.594"
    viewBox="0 0 11.895 13.594"
  >
    <path
      data-name="Icon awesome-user"
      d="M5.947 6.8a3.4 3.4 0 1 0-3.4-3.4 3.4 3.4 0 0 0 3.4 3.4zm2.379.85h-.443a4.622 4.622 0 0 1-3.871 0h-.444A3.569 3.569 0 0 0 0 11.215v1.1a1.275 1.275 0 0 0 1.274 1.274h9.346a1.275 1.275 0 0 0 1.274-1.274v-1.1a3.569 3.569 0 0 0-3.568-3.569z"
      style={{ fill: 'red' }}
    />
  </svg>
);

export const UserIcon = (props) => (
  <Icon
    component={props.warning === 'true' ? UserSvgWarning : UserSvg}
    {...props}
  />
);

export const UserDataLayout = ({ children, color }) => (
  <p
    className={`text-${
      color || 'violet-light'
    } text-sm text-center flex justify-center font-bold items-center gap-2`}
  >
    {children}
  </p>
);
