import React, { FunctionComponent, useState } from 'react';
import CardActionConfirmModal from './CardActionConfirmModal';
import CardActionIcon from './CardActionIcon';
import styles from './CardActionItem.module.scss';

interface Props {
  title?: string;
  icon: React.FunctionComponent;
  linkTo?: string;
  onClickHandler?: () => void;
  onConfirmHandler?: () => void;
  color?: string;
  justifyContent?: string;
}

export const CardActionItem: FunctionComponent<Props> = React.memo(
  ({
    icon,
    title,
    onClickHandler,
    onConfirmHandler,
    linkTo,
    color,
    justifyContent,
  }) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const iconProps = {
      icon,
      title,
      onClickHandler: onConfirmHandler
        ? () => setShowConfirmModal(true)
        : onClickHandler,
      color,
    };
    return (
      <>
        <li
          className={`flex items-center justify-center md:${
            justifyContent || 'justify-start'
          } ${
            !color
              ? `${styles.gradient} bg-gradient-to-r hover:from-violet hover:to-violet-light`
              : ''
          }`}
        >
          {linkTo && (
            <a
              href={linkTo}
              rel="noopener noreferrer"
              className="flex items-center w-full"
              target="_blank"
            >
              <CardActionIcon {...iconProps} />
            </a>
          )}
          {!linkTo && <CardActionIcon {...iconProps} />}
        </li>
        <CardActionConfirmModal
          showConfirmModal={showConfirmModal}
          setShowConfirmModal={setShowConfirmModal}
          onConfirmHandler={onConfirmHandler}
        />
      </>
    );
  }
);

export default CardActionItem;
