import React, { FunctionComponent } from 'react';
import EditImageCard from '../GameDesignCard/EditImageCard/EditImageCard';
import {
  ninjaBomb,
  ninjaImages,
  ninjaRing,
  ninjaTitle,
  ninjaFruitsGallery,
  getNinjaGalleryByType
} from '@wiply/utils';
import { GameDesignAssetsProps } from '.';

export const GameDesignAssetsNinja: FunctionComponent<GameDesignAssetsProps> = ({
  game,
  commonProps,
  onChangeHandler,
}) => {
  const { ninjaOptions } = game.options;
  return (
    <>
      {/* Ninja Title Image */}
      <EditImageCard
        {...commonProps}
        title="Ninja Title Image"
        description={{
          type: 'PNG',
          size: 'Ratio: 500x500',
          aspectRatio: 1,
        }}
        accept="image/png"
        defaultValue={ninjaOptions?.titleImg || ninjaTitle[0]}
        onChange={(field: string) =>
          onChangeHandler({
            ninjaOptions: {
              ...ninjaOptions,
              titleImg: field,
            },
          })
        }
        defaultImagesToChoose={ninjaTitle}
        previewCss="max-h-32"
      />

      {/* NINJA START NEW GAME IMAGE */}
      <EditImageCard
        {...commonProps}
        title="Start new Game Ring Image"
        description={{
          type: 'PNG',
          size: 'Ratio: 500x500',
          aspectRatio: 1,
        }}
        accept="image/png"
        defaultValue={ninjaOptions?.newGameRingImg || ninjaRing[0]}
        onChange={(field: string) =>
          onChangeHandler({
            ninjaOptions: {
              ...ninjaOptions,
              newGameRingImg: field,
            },
          })
        }
        defaultImagesToChoose={ninjaRing}
        previewCss=" max-h-32"
      />

      {/* NINJA  IMAGES FOR BOMB */}
      <EditImageCard
        {...commonProps}
        title="Bomb Image"
        description={{
          type: 'PNG',
          size: 'Ratio: 500x500',
          aspectRatio: 1
        }}
        accept="image/png"
        defaultValue={ninjaOptions?.bombImg || ninjaBomb[0]}
        onChange={(field: string) =>
          onChangeHandler({
            ninjaOptions: {
              ...ninjaOptions,
              bombImg: field,
            },
          })
        }
        defaultImagesToChoose={ninjaBomb}
      />

      {/* NINJA ELEMENT1 IMAGES */}
      <EditImageCard
        {...commonProps}
        title="Elements"
        description={{
          type: 'PNG',
          size: 'Ratio: 500x500',
          aspectRatio: 1,
        }}
        accept="image/png"
        defaultValue={ninjaOptions?.fruitsImages?.whole || ninjaImages.fruitsImages.whole}
        onChange={(field: string) =>
          onChangeHandler({
            ninjaOptions: {
              ...ninjaOptions,
              fruitsImages: getNinjaGalleryByType(field)
            },
          })
        }
        defaultImagesToChoose={ninjaFruitsGallery}
        disableUpload={false}
        galleryPreview="whole"
        uploadImagesModal={true}
       
        formTitles={{
          title1: 'whole',
          title2: 'sliceA',
          title3: 'sliceB',
          title4: 'particles',
          title5: 'splash',
         
        }}
      />
    </>
  );
};

export default GameDesignAssetsNinja;
