// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  development: true,
  firebaseConfig: {
    apiKey: 'AIzaSyBBJbg_hO7YgvGu5QGttp-SK0r7cNrehnM',
    authDomain: 'wiply-development.firebaseapp.com',
    projectId: 'wiply-development',
    storageBucket: 'wiply-development.appspot.com',
    messagingSenderId: '492387376670',
    appId: '1:492387376670:web:a2410571632a4db815180f',
    measurementId: 'G-FH9DWNVELV',
  },

  // this is a service account
  serviceAccount: {
    type: 'service_account',
    project_id: 'wiply-development',
    private_key_id: 'b52d371cc6e3070caeea800417c0246da25223fb',
    private_key:
      '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDK1ZAsqRnTw3ug\nXfCQgW4638FdwEqC/PIWHaC0bEEhnHakn//pIhseKE2gsOJeNIcB4rS5HsYTkut0\nX5c68NiYwlwbTdom7/vQ01OFCrhcOfc7DvGnmCw7DYGNE3EoBDOZhwhkpLI4+ZLo\nAM3FJJ66rF8mxCU0R7r8xrVCMq7O5Yyl3neZfyzoGkmvmI1x74q3xwDICcRt6bmu\nCh45jma/vS6xqvGY70omJRlzYYN7ngUQrfOXnz/7Im9iuqJUHTuV8h+I9xkJFzkw\naQyIIfcZYt7jXmkxQTrNnpq31Rkv3rG1RHBhh0fP/72YnKqjdfRWCa4fv7A49bRj\n0MjB2XYJAgMBAAECggEAJOMi5aRZIJRf8sGywqzvLYDMyy5xvpGeuxvnQniffavV\nPcolQbIkqV7eAze36zb1SIgAotvwXNXuwQqzV5I+acWK96I4ZrVlH8yW106kSY13\nI0Pr+bUochLaORxMi2D8O1L92UPEb60fpNaklVI8FanMYiHwvCnBXUAL7b6G4PQ2\nNlRA2zAaiiGBdKidsdrgvEvKY+0u5jIM8lw/u1FHy/aHQckpWYc6UbPoSPoXVcVa\naDFlV/5QDrg2eOM01LtNipRkD61oi8sMWNmPTQukJ+riLB7NooK4lOpLtaN5mhSA\noWay0v3M2EfZ8itODm/H8u/tsUI69MOFZ/qhpT2saQKBgQDkw3kLWGd2gb0qibaa\nXhIbvJsd0HsGN6gKrpq+M4vPp5yNBpfGNGnifQcGfVY2p/Fk2yXMoyvSuFAEptqm\ndksjeIhCSTqSq58WLa+J5YHpQZ0PbzICOzn7lQUmJKmG24wAoeTTJw2wl1NZjnKc\ncNtVjApqxbfceEifFrFkLVJzjQKBgQDi+8kM2wj2TmRY+iu+mADnjQwEpwqKzdYi\nVRRHnyVaE8rcPD5vvEXUaU8BBIjW4zBi381CwFmFkyX9CZJ4rvt9URVO7R4GYzwD\ncDpJIhWHwfLvrutsDj0M5zIjPsj/kkyOC48JEkug8yFXGRkBwYp4aWhhK+qW3rRc\naz8VMh0PbQKBgHwr0icjXD2KHJjbWV+fEis2C+h6Al0UlsT9k4cMSaZ0b1hpLLbs\nOlEy1j5iiApwzGmXPoQ+SYo+e0ibHPHgIdbmFOgOAZodR+Ps9mxeRE7NS6hWDgoC\nBe4WLE5Q9if6QZsSRTeL6jVhXl+tH27lH1jnA7yDB9LHA3w/ABjFNQz9AoGBAKoH\n3LpxlcwUOx1gAcbbTMonoVoW21HREVhUjUV9lNhePTcvNcIzsceFP3ZOtEbslwFq\nDvOy7RyfdvSO6BbZVXCxfZnLDBPS4FDfD/pauvAgPGCJkrc2D0uMIMXAMYcpS4mY\nZQRxnQ+rMU1ycoYbOiQrKdKouCleGJOmjvHMwXIdAoGAOcGkd53cQqCZtdirsqDj\nKT4LkPeFm7bsCXDcXcg9xpRv2VJpBHpRoSfu0GJNGxKKiJ9o+Dmdg6vleHEi2148\nN+2uFna7FZqHScjyPLFADGEEfFBbEp7ebU31O6Ba3zwJcMTdwQKKPIZDEwYq1Ugq\n+IaihKYwsnfNe5qWBOrsf5c=\n-----END PRIVATE KEY-----\n',
    client_email:
      'firebase-adminsdk-tjme3@wiply-development.iam.gserviceaccount.com',
    client_id: '104464015191681725904',
    auth_uri: 'https://accounts.google.com/o/oauth2/auth',
    token_uri: 'https://oauth2.googleapis.com/token',
    auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
    client_x509_cert_url:
      'https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-tjme3%40wiply-development.iam.gserviceaccount.com',
  },
  databaseURL:
    'https://wiply-development-default-rtdb.europe-west1.firebasedatabase.app',

  appsUrls: {
    client: 'https://dev.wiply.games/',
    designerDashboard: 'https://dashboard.wiply-dev.xyz',
    clientNext: 'wiply-dev.xyz',
  },
  payPal: {
    id: 'Aad_XMcRq0QwjlWyoW1VBPtiV_JroRDBoEYRNwwnbGwexNrseVj2MaTgl2nbrOxY9HEnBe2UOvLbLpzj',
    plans: ['P-1M532494FF758014JMINSZCY', 'P-0JB06206C3395092RMINURLA'],
    url: 'https://www.sandbox.paypal.com/',
  },
  gamesUrls: {
    puzzle: 'https://3dpuzzle.netlify.app/',
    cups: 'https://3cups.netlify.app/',
    hockey: 'https://table-hockey-wiply.netlify.app/',
    wheel: 'https://wheel-wiply.netlify.app/',
    ninja: 'https://ninja-game.netlify.app',
    memory: 'https://wiply-momory-game.netlify.app/',
    quiz: 'https://wiply-quiz.netlify.app/',
    wordle: 'https://wiply-wordle.netlify.app',
    tower: 'https://snazzy-kulfi-a0eb3e.netlify.app',
    gems: 'https://bucolic-sawine-dc39f9.netlify.app',
  },
  imagesUrls: {
    logo: 'https://www.wiply.com/assets/images/wiply/logo-white.png',
  },
  //api: 'http://localhost:4202/api/'
  api: 'https://api.wiply-dev.xyz/api/',
  adminUID: 'gmwo06khpcqfg2g3enrgvls8hxv2',
  // gaID: 'G-80699GSM9R',
  gaID: 'UA-211863956-1',
  mail: {
    templates: {
      welcome: 'template_np47fko11',
      getCoupon: 'template_u8kogob',
      contact: 'template_d71uly5',
      lost: 'template_h5nijys',
    },
    userId: 'user_jnDjIpEcTAF1mkiOa3k1d',
    serviceId: 'service_lcw4bdk',
  },
  inforyou:
    'Basic b21lcjExMTpiM2MwMjJkYi0zMjc4LTRjYTYtYmRhYy1kMjQyYzUzNTM5ZDQ=',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
