import React, { FunctionComponent, memo } from 'react';
import { Divider } from 'antd';
import { loginUserBySocial } from '../features/users';
import { GoogleOutlined, FacebookFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../reducers';

export const LoginWithSocial: FunctionComponent = memo(() => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();
  return (
    <>
      <Divider>
        <span className="text-gray">or</span>
      </Divider>
      <div className="flex gap-4 justify-center">
        <span
          onClick={() => dispatch(loginUserBySocial('facebook'))}
          className="transition duration-700 text-white p-3 flex items-center gap-2 cursor-pointer text-md rounded-lg bg-facebook hover:bg-facebook-hover"
        >
          <FacebookFilled className="text-lg text-white" /> Login with Facebook
        </span>
        <span
          onClick={() => dispatch(loginUserBySocial('google'))}
          className="transition duration-700 text-white p-3 flex items-center gap-2 cursor-pointer text-md rounded-lg bg-google hover:bg-google-hover"
        >
          <GoogleOutlined className="text-lg text-white" />
          <span>Login with Google</span>
        </span>
      </div>
    </>
  );
});

export default LoginWithSocial;
