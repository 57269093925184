import { Base } from './base.entity';
import { GameTypeEnum } from '@wiply/utils';
import { Player } from './player.entity';

interface QuizQuestions {
  image: string;
  question: string;
  correct_answer: string;
  incorrect_answers: string[];
}

interface QuizTimer {
  easy_questions: number;
  medium_questions: number;
  hard_questions: number;
}

interface CouponInstance {
  text?: string[];
  color?: string;
  backgroundColor?: string;
  maxLength?: number[];
  fontSize?: string;
}

export interface CouponSettings {
  template?: number;
  logo?: string;
  title?: CouponInstance;
  isScore?: boolean;
  discount?: CouponInstance;
  button?: CouponInstance;
  note?: CouponInstance;
  showCode?: boolean;
  background?: string;
  firework?: string;
  confetti?: string;
  fontSizeDiscount1?: string;
  fontSizeDiscount2?: string;
  fontSizeDiscount3?: string;
  couponLambda?: string;
  secondClickDisabled?: boolean;
  sheetsBestAPI?: string
}

export interface EmailSettings {
  inforyou: string;
  hideInputs?: string;
  serviceId?: string;
  templateId?: string;
  title?: string;
  description?: string;
  logo?: string;
  logoLink?: string;
  redirectUrl?: string;
  background?: string;
  discount1?: string;
  discount2?: string;
  backgroundPhoto?: string;
  formCode?: string;
  placeHolder1?: string;
  placeHolder2?: string;
  placeHolder3?: string;
  checkBoxText?: string;
  mailTo?: string;
  errorColor?: string;
  checkBoxErrorText?: string;
  spreadSheetUrl?: string;
  topEmail?: string;
  lambdaApi?: string;
  lambdaUrl?: string;
  sfx?: string;
  dynamic?: string;
  dynamicLink?: string;
  sendEmail?: string;
  countDown?: string;
  countDownText?: string;
  countDownTextBottom?: string;
  countDownTime?: number;
  sms?: string;
  select?: string;
  selectText?: string;
  selectColor?: string;
  select1?: string;
  select2?: string;
  select3?: string;
  select4?: string;
  select5?: string;
  finishText?: string;
  isHebrewMemory?: string;
  errorText?: string;
}

export interface GameCoupon extends CouponSettings {
  // id: string,
  couponId?: string;
  code?: string;
  html?: string;
  imgUrl?: string;
  gameImgUrl?: string;
  utm?: string;
  claimUrl?: string;
  index?: number;
  copyCode?: boolean;
  //  TODO: codes: string[]
  settings?: CouponSettings;
  emailCoupon?: boolean;
  emailSvg?: boolean;
  emailSettings?: EmailSettings;
  svgEmailSettings?: EmailSettings;
  isSocial?: boolean;
  input2?: boolean;
  input3?: boolean;
}

export interface GameLostPopUp {
  // id: string,
  couponId: string;
  code: string;
  html?: string;
  imgUrl?: string;
  gameImgUrl?: string;
  utm?: string;
  claimUrl?: string;
  index?: number;
  copyCode?: boolean;
  settings?: CouponSettings;
  emailCoupon?: boolean;

  //  TODO: codes: string[]
}

export interface EmbeddedScript {
  widgetOn?: string;
  gameURL?: string;
  widgetImage?: string;
  setWidgetLeft?: string;
  iframeBorder?: string;
  height?: string;
  width?: string;
  mobileWidth?: string;
  mobileHeight?: string;
  backgroundDesign?: string;
  aspectRatio?: number;
  customCSS?: string
}

export interface Leaderboards {
  first?: Player;
  second?: Player;
  third?: Player;
}
export interface GameOptions {
  dynamicName?: string;
  lambdaTrigger?: string;
  isLosable?: boolean;
  logoScale?: number;
  isCopyTextCoupon?: boolean;
  couponExpDurationSec?: number;
  congratsTitle?: string;
  congratsSubTitle?: string;
  claimCouponButtonText?: string;
  claimCouponButtonTextAfterClick?: string;
  // applyCouponUrl?: string,
  gtmId?: string;
  socialShare?: { title: string; url: string };
  takanonText?: string;
  takanonLink?: string;
  takanonColor?: string;
  // shareUrl: string,
  // shareTitle: string,
  // preloaderSvgUrl: string,
  colors?: string[];
  wheelColors?: string[];
  backgroundColor?: string;
  customCSS?: string;
  // claimCouponBaseUrl: string,
  // bgImgUrl?: string,
  logoImgUrl?: string;
  addCrm?: string;
  logoLink?: string;
  logoForm?: boolean;
  // basketballBoardImgUrl?: string,
  leaderboards: Leaderboards;
  redirectLeaderBoards?: boolean;
  termsMessageHtml?: string;
  analyticsId?: string;
  gaId?: string;
  facebookPixel?: string;
  facebookMeta?: string;
  bgImgUrl?: string;
  bgVideoUrlDesktop?: string;
  bgVideoUrlMobile?: string;
  mobileBgImgUrl?: string;
  gameLostPopUp?: GameCoupon;
  dashboardUrl?: string;
  enableCache?: boolean;
  bricksOptions?: {
    bgImgUrl?: string;
    mobileBgImgUrl: string;
    ballImgUrl?: string;
    paddleImgUrl?: string;
    brickImgUrl?: string;
    logo?: string;
    rows?: number;
    // position?: { top?: number, bottom?: number, lext?: number, right?: number }
  };
  basketballOptions?: BasketballOptions;
  bricks3dOptions?: { logoImg: string };
  dropItOptions?: {
    bgImgUrl?: string;
    mobileBgImgUrl?: string;
    Url?: string;
    logoDropit?: string;
  };
  cupsOptions?: {
    cupsTexture?: string;
    boardTexture?: string;
    modelGlb?: string;
    level?: string;
  };
  ninjaOptions?: {
    fruitsImages?: {
      whole?: string;
      sliceA?: string;
      sliceB?: string;
      splash?: string;
      particles?: string;
    };
    newGameRingImg?: string;
    titleImg?: string;
    bombImg?: string;
    strikes?: {
      gamelife0?: string;
      gamelife1?: string;
      gamelife2?: string;
      gamelife3?: string;
    };
    scoreBoard?: {
      scoreBoard1?: string;
      scoreBoard2?: string;
      scoreBoard3?: string;
      scoreBoard4?: string;
    };
  };
  memoryOptions?: {
    cover?: string;
    cardImages?: string[];
    cardColor?: string;
    coverColor?: string;
    titleColor?: string;
    amountOfCards?: number;
    turns?: number;
    differentPictures?: boolean;
    differentPicsObject: any[];
    discount: string;
    discountAmount: number;
    countUp: string;
    shekel: string;
  };
  puzzleOptions?: { puzzleImg?: string; puzzleTime?: string; level?: string };
  quizOptions?: QuizOptions;
  hockeyOptions?: { floorImg: string; tableImg: string };
  wheelOptions?: {
    base: string;
    prize1: string;
    prize2: string;
    prize3: string;
    prize4: string;
    prize5: string;
    prize6: string;
    headline: string;
    subtitle: string;
    logo: string;
    buttonText: string;
    colors?: {
      wheel1: string;
      wheel2: string;
      wheel3: string;
      wheel4: string;
      wheel5: string;
      wheel6: string;
    };
    image?: string;
  };
  wordleOptions?: {
    title?: string;
    activeTileColor?: string;
    correctTileColor?: string;
    wrongLocationTileColor?: string;
    wrongTileColor?: string;
    emptyTileColor?: string;
    difficulty?: string;
    language?: string;
  };
  towerOptions?: {
    floorsForFirstCoupon: number;
    floorsForSecondCoupon: number;
    numLives: number;
    numCoupons: number;
    stage: string;
    block: string;
    ropedBlock: string;
    perfectBlock: string;
    hook: string;
    gradientColors: number[][];
  };
  gemOptions?: {
    bagImage: string;
    dropsImages: string[];
  };
  menuScreenOptions?: MenuScreenOptions;
  labels?: GameLabels;
  disableCoupon?: boolean;
  disableShare?: boolean;
  disableGame?: boolean;
  disableMenu?: boolean;
}

export interface QuizOptions {
  easy_questions?: QuizQuestions[];
  medium_questions?: QuizQuestions[];
  hard_questions?: QuizQuestions[];
  timer?: QuizTimer;
  atlas_image?: string;
  questionsAmount?: string;
  startingDate?: string;
  totalDays?: number;
  numOfLives?: string;
  livesImage?: string;
  questionBackground?: string;
  answerBar?: string;
  correctAnswerBar?: string;
  incorrectAnswerBar?: string;
  answerA?: string;
  answerB?: string;
  answerC?: string;
  answerD?: string;
  answerColor?: string;
  isLeft?: boolean;
  showAmount?: boolean;
}

export interface BasketballOptions {
  boardImg: string;
  ballImg: string;
  level: string;
  balls: string;
  scoreColor: string;
  showScore: string;
  timesArray: number[];
  firstTime: number;
  shotClockTime: number;
  gameMode?: GameModes;
}

export enum GameModes {
  SHOTCLOCK = 'shotClock',
  SCOREBOARD = 'scoreboard',
  NORMAL = 'normal',
}
export interface FormField {
  name: string;
  email: string;
  number: string;
  organization: string;
}

export interface MetaTags {
  title: string;
  description: string;
  ogImage: string;
  ogUrl: string;
  ogDescription: string;
  ogTitle: string;
}

// New background should be part of the game and not game options
export interface Backgrounds {
  bgImgUrl?: string;
  bgVideoUrlDesktop?: string;
  bgVideoUrlMobile?: string;
  mobileBgImgUrl?: string;
}

export interface MenuScreenOptions {
  imageWidth?: string;
  textTitle?: string;
  textSubtitle?: string;
  textBottom?: string;
  buttonText?: string;
  bottomTextSize?: number;
  menuImage?: string;
  Logo?: string;
  formBefore: boolean;
  formData: FormField;
  logoMobile?: string;
  fullImage?: string;
  fullImageMobile?: string;
  instructions?: string;
  music?: string;
  disablePreGame?: boolean;
  disableMenuGame?: boolean;
  discount1?: string;
  discount2?: string;
  discount3?: string;
  buttonColor?: string;
  backgroundColor?: string;
  instructionsText?: string;
  checkBoxText?: string;
  checkBoxErrorText?: string;
  isDiscountTextVisible?: boolean;

}

export interface ClientGame extends Base {
  name: string;
  clientId: string;
  type: GameTypeEnum;
  coupons: GameCoupon[];
  background: Backgrounds;
  options: GameOptions;
  metatags?: MetaTags;
  exceededLimit?: boolean;
  preloader?: string;
  preloaderweb?: string;
  preloadermobile?: string;
  embeddedScript?: EmbeddedScript;
  companyName?: string;
  brandColor?: string;
  dateGameCreated?: string;
}

interface GameLabels {
  sharedUrl?: boolean;
  uploadFile?: boolean;
  uploadCoupon?: boolean;
  updateSubdomain: boolean;
}
