export enum GameTypeEnum {
  Basketball = 'basketball',
  Bricks = 'bricks',
  Bricks3D = 'bricks3d',
  DropIt = 'dropit',
  Puzzle = 'puzzle',
  Cups = 'cups',
  Ninja = 'ninja',
  Hockey = 'hockey',
  Wheel = 'wheel',
  Memory = 'memory',
  Quiz = 'quiz',
  Wordle = 'wordle',
  Tower = 'tower',
  Gems = 'gems',
}
