import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Form, Input, AutoComplete } from 'antd';
import GamesContext from '../../context/games';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProps } from 'antd/es/form';
import { GameTypeEnum } from '@wiply/utils';
import Button from '../shared/Button';
import ClientsContext from '../../context/clients';
import { RootState } from '../../reducers';
import { useSelector } from 'react-redux';
import { environment } from '@env/environment';
import moment from 'moment';

interface Props {
  gameType: GameTypeEnum;
  handleCancel: () => void;
  clientId?: string;
  date?: any;
}

export const NewGameForm: FunctionComponent<Props> = React.memo(
  ({ gameType, handleCancel, clientId }) => {
    const { createGame } = useContext(GamesContext);
    const { clients, subscribeClients, updateGamesAmount } = useContext(
      ClientsContext
    );
    const navigate = useNavigate();
    const [client, setClient] = useState(null);
    const currentUser = useSelector((state: RootState) => state.userReducer);
    let userId: string;
    if (currentUser?.isAdmin) {
      const { id } = useParams<{ id: string }>();
      userId = id || environment.adminUID;
    } else {
      userId = currentUser?.user?.id?.toLowerCase();
    }

    const date = moment().format('YYYYMMDDhhmmss');
    const onFinish = async ({ name }) => {
      const gameId = await createGame(name, client || userId, gameType, date);
      handleCancel();
      updateGamesAmount(userId, true);
      navigate(`/${gameId}`);
    };
    const onFinishFailed: FormProps['onFinishFailed'] = (errorInfo) =>
      console.log('Failed:', errorInfo);

    useEffect(() => {
      if (!clientId) {
        const unsubscribe = subscribeClients();
        return () => unsubscribe();
      }
    }, []);

    const clientList = useMemo(() => {
      const list = [];
      if (clients.length > 0)
        clients.map(({ id, name }) => list.push({ value: id, label: name }));
      return list;
    }, [clients]);
    return (
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="sm:w-2/3"
      >
        <Form.Item
          name="name"
          rules={[{ required: true, message: 'Please enter Name' }]}
        >
          <Input
            className="rounded-lg flex m-auto text-center h-16 text-xl font-bold"
            placeholder="What's the name of your game"
          />
        </Form.Item>
        {/* {!clientId && (
          <Form.Item
         
            name="clientid"
            rules={[{ required: true, message: 'Please select a client' }]}
          >
            <div className="autocomplete sm:w-2/6 m-auto">
              <AutoComplete
                className="rounded-3xl sm:w-2/6 flex m-auto text-center"
                options={clientList}
                placeholder="Select a client"
                filterOption={(inputValue, option) =>
                  option!.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                onSelect={(selected) => setClient(selected)}
              />
            </div>
          </Form.Item>
        )} */}
        <Form.Item className="mb-0">
          <div className="grid grid-cols-2 gap-4 m-auto mt-4">
            <Button title="Cancel" onClick={handleCancel} className="h-16" />
            <Button title="Create" submit style="inverted" className="h-16" />
          </div>
        </Form.Item>
      </Form>
    );
  }
);

export default NewGameForm;
