import React, { FunctionComponent } from 'react';
import Input from 'antd/lib/input';
import { CouponSettings } from '@wiply/repository';
import { Divider } from 'antd';

interface Props {
  config: CouponSettings;
  setConfig: (newConfig: CouponSettings) => VoidFunction;
  instance: string;
  lines?: number;
  setAlert: (boolean) => void;
}

const inputProps = {
  className: 'my-1 rounded-lg h-9',
  allowClear: true,
};

const inputColorProps = {
  className: 'my-1 rounded-lg h-9 w-9 cursor-pointer',
  type: 'color',
};

export const CouponSettingForm: FunctionComponent<Props> = ({
  config,
  setConfig,
  instance,
  lines,
  setAlert,
}) => (
  <>
    <label className="font-bold text-center capitalize">{instance}</label>
    <div className="grid grid-cols-3">
      <div className="place-self-center col-span-2">
        <Input
          onChange={(e) => {
            const newText = [...config[instance].text];
            newText[0] = e.target.value;
            const newConfig = {
              ...config,
              [instance]: {
                ...config[instance],
                text: newText,
              },
            };
            setAlert(true);
            setConfig(newConfig);
          }}
          {...inputProps}
          value={config[instance]?.text[0]}
          maxLength={config[instance]?.maxLength[0]}
        />
        {lines && lines > 1 && (
          <Input
            onChange={(e) => {
              const newText = [...config[instance].text];
              newText[1] = e.target.value;
              const newConfig = {
                ...config,
                [instance]: {
                  ...config[instance],
                  text: newText,
                },
              };
              setAlert(true);
              setConfig(newConfig);
            }}
            {...inputProps}
            value={config[instance].text[1]}
            maxLength={config[instance].maxLength[1]}
          />
        )}
        {lines && lines > 2 && (
          <Input
            onChange={(e) => {
              const newText = [...config[instance].text];
              newText[2] = e.target.value;
              const newConfig = {
                ...config,
                [instance]: {
                  ...config[instance],
                  text: newText,
                },
              };
              setAlert(true);
              setConfig(newConfig);
            }}
            {...inputProps}
            value={config[instance].text[2]}
            maxLength={config[instance].maxLength[1]}
          />
        )}
      </div>
      <div className="flex flex-col items-center  justify-center ">
        {config[instance]?.backgroundColor && config.template !== 2 && (
          <div className="flex  items-center justify-center">
            <input
              {...inputColorProps}
              onChange={(e) => {
                const newConfig = {
                  ...config,
                  [instance]: {
                    ...config[instance],
                    backgroundColor: e.target.value,
                  },
                };
                setAlert(true);
                setConfig(newConfig);
              }}
              value={config[instance].backgroundColor}
            />
          </div>
        )}
        {config[instance].backgroundColor &&
          instance == 'button' &&
          config.template == 2 && (
            <div className="flex  items-center justify-center">
              <input
                {...inputColorProps}
                onChange={(e) => {
                  const newConfig = {
                    ...config,
                    [instance]: {
                      ...config[instance],
                      backgroundColor: e.target.value,
                    },
                  };
                  setAlert(true);
                  setConfig(newConfig);
                }}
                value={config[instance].backgroundColor}
              />
            </div>
          )}
        <div className="flex  items-center justify-center">
          <input
            {...inputColorProps}
            onChange={(e) => {
              const newConfig = {
                ...config,
                [instance]: {
                  ...config[instance],
                  color: e.target.value,
                },
              };
              setAlert(true);
              setConfig(newConfig);
            }}
            value={config[instance].color}
          />
        </div>
      </div>
    </div>
    <Divider />
  </>
);

export default CouponSettingForm;
