import React, { FunctionComponent, useState } from 'react';
import { Form, Input, Alert, Image } from 'antd';
import { FormProps } from 'antd/es/form';
import Button from '../components/shared/Button';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../features/users';
import Spinner from '../components/shared/Spinner';
import { Link } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../reducers';
import { AnyAction } from 'redux';

const Login: FunctionComponent = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();
  const [response, setResponse] = useState(null);

  const onReset = async ({ username }) => {
    const res = await dispatch(resetPassword(username));
    setResponse(res);
  };

  const onFinishFailed: FormProps['onFinishFailed'] = (errorInfo) =>
    console.log('Failed:', errorInfo);

  return (
    <div className="w-full h-screen flex ">
      <div
        className="h-full items-center hidden lg:flex w-1/2  sm:pr-8  m-auto self-center my-12 sm:my-0"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/shulgirit/image/upload/v1641896455/wiply/Platform%20Default%20Images/background.jpg')",
        }}
      >
        <Image
          preview={false}
          className="max-w-full h-auto text-gray-600 align-middle box-border"
          src="https://res.cloudinary.com/shulgirit/image/upload/v1628066550/wiply/Asset_4_2x_dbfhiu.png"
        />
      </div>
      <div className="flex flex-shrink-0 justify-center items-center px-6 w-full max-w-full tracking-normal leading-5 text-gray-600 bg-white box-border lg:flex-none lg:p-16 lg:w-1/2">
        <div className="leading-5 xl:px-6 box-border md:flex-none lg:flex-none md:w-9/12">
          <h2 className="reset-password mt-0 mb-4 font-medium text-gray-700 xl:text-3xl box-border text-center	">
            FORGOT PASSWORD
          </h2>
          <div className="under-title-password">
            <p className="mt-0 mb-6 leading-6 box-border text-center">
              Enter your email address and we'll send you an email to reset your
              password
            </p>
            <Form
              initialValues={{ remember: true }}
              onFinish={onReset}
              onFinishFailed={onFinishFailed}
              className="mt-6 text-gray-600 box-border"
            >
              <div className="mb-4 box-border">
                <label
                  htmlFor="login-email"
                  className="inline-block mb-1 text-sm leading-4 text-gray-700 cursor-default box-border"
                >
                  Email
                </label>
                <Form.Item
                  name="username"
                  rules={[
                    { required: true, message: 'Please input your email' },
                  ]}
                >
                  <Input className="rounded-lg py-2" />
                </Form.Item>
              </div>

              <Form.Item>
                <Button
                  className="h-full bg-violet block overflow-hidden relative py-3 px-6 m-0 w-full text-base font-medium leading-none text-center text-white normal-case align-middle  rounded-md border border-indigo-500 border-solid shadow-none select-none box-border hover:bg-violet hover:border-indigo-700 hover:text-white focus:bg-indigo-600 focus:border-indigo-700 focus:shadow-none focus:text-white"
                  submit={true}
                  title="SUBMIT"
                  style="inverted"
                />
              </Form.Item>
              {response?.status === 200 && (
                <Alert
                  type="success"
                  message="Reset Password Email sent"
                  className="rounded-xl"
                />
              )}
              {response?.status === 400 && (
                <Alert
                  type="error"
                  message="Email does not exist"
                  className="rounded-xl"
                />
              )}
            </Form>
          </div>
          <p className="mt-6 mb-4 text-center box-border">
            <span className="mr-1 text-gray-600 box-border">
              New on our platform?
            </span>
            <Link
              to="/signup"
              className="text-violet no-underline box-border hover:text-indigo-600 hover:no-underline"
            >
              <span className="cursor-pointer box-border">
                Create an account
              </span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
