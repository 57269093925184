import React, { FunctionComponent, memo } from 'react';
import { Image } from 'antd';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

interface Props {
  gameName?: string;
  onChange: (dates: any) => void;
}

export const DashboardHeader: FunctionComponent<Props> = memo(
  ({ gameName, onChange }) => {
    return (
      <div
        className="mb-8 flex p-4"
        style={{
          backgroundImage: `url('https://res.cloudinary.com/shulgirit/image/upload/v1637248210/wiply/dashboard/header_bg.jpg')`,
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="flex-grow">
          <h1 className="text-xl text-violet mb-4">{gameName}</h1>
          <h2 className="text-black text-4xl md:text-6xl">
            Wiplify <br></br>
            <strong>Dashboard</strong>
          </h2>
          <RangePicker
            className="my-12 rounded-lg h-12"
            onChange={onChange}
            format="MM/DD/YYYY"
          />
        </div>
        <Image
          preview={false}
          className="max-w-xl"
          src="https://res.cloudinary.com/shulgirit/image/upload/v1628066550/wiply/Asset_4_2x_dbfhiu.png"
        />
      </div>
    );
  }
);

export default DashboardHeader;
