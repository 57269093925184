import { Card, Checkbox, Input, Radio, Space, Switch } from 'antd';
import React, { FunctionComponent, useContext, useMemo, useState } from 'react';

import CardActionItem from '../shared/CardItem/CardActionItem';
import CardItem from '../shared/CardItem';
import { CheckSquareOutlined } from '@ant-design/icons';
import { ClientGame } from '@wiply/repository';
import { EditColorAction } from '../GameDesignCard/EditColorAction';
import EditImageCard from '../GameDesignCard/EditImageCard/EditImageCard';
import EditOptionsCard from '../GameDesignCard/EditOptionsCard';
import EditTextCard from '../GameDesignCard/EditTextCard';
import SliderImageSize from './SliderImageSize';

interface Props {
  game?: ClientGame;
  commonProps?: any;
  onChangeHandler: (field: any) => void;
}

interface FromCardProps {
  game: ClientGame;
  onChangeHandler: (field: any) => void;
}
const FormCard: FunctionComponent<FromCardProps> = ({
  game,
  onChangeHandler,
}) => {
  const [disable, setDisable] = useState({
    name: true,
    email: true,
    number: true,
    organization: true,
  });

  const [name, setName] = useState(
    game.options.menuScreenOptions?.formData?.name
  );
  const [email, setEmail] = useState(
    game.options.menuScreenOptions?.formData?.email
  );
  const [number, setNumber] = useState(
    game.options.menuScreenOptions?.formData?.number
  );
    const [organization, setOrganization] = useState(
      game.options.menuScreenOptions?.formData?.organization
    );

  const onChange = (value) => {
    switch (value) {
      case 'name':
        setDisable({ ...disable, name: !disable.name });
        break;
      case 'email':
        setDisable({ ...disable, email: !disable.email });
        break;
      case 'number':
        setDisable({ ...disable, number: !disable.number });
        break;
      case 'organization':
        setDisable({ ...disable, organization: !disable.organization });
        break;
    }
  };
  return (
    <CardItem
      actions={
        <CardActionItem
          title="Save"
          icon={CheckSquareOutlined}
          onClickHandler={() => {
            console.log(name, email, number);
            onChangeHandler({
              menuScreenOptions: {
                ...game.options.menuScreenOptions,
                formData: {
                  name: !disable.name ? name : '',
                  email: !disable.email ? email : '',
                  number: !disable.number ? number : '',
                  organization: !disable.organization ? organization : '',
                },
              },
            });
          }}
          color="white"
        />
      }
      actionsStyle="gradient"
    >
      <h1>Select fields to disable or change text placeholders</h1>
      <Space direction="vertical">
        <div>
          <Checkbox onChange={() => onChange('name')}>Name</Checkbox>
          <Input
            disabled={disable.name}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <Checkbox onChange={() => onChange('email')}>Email</Checkbox>

          <Input
            disabled={disable.email}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <Checkbox onChange={() => onChange('number')}>Phone</Checkbox>

          <Input
            disabled={disable.number}
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
        </div>
        <div>
          <Checkbox onChange={() => onChange('organization')}>
            Organization
          </Checkbox>

          <Input
            disabled={disable.organization}
            value={organization}
            onChange={(e) => setOrganization(e.target.value)}
          />
        </div>
      </Space>
    </CardItem>
  );
};

export const GameMenuTab: FunctionComponent<Props> = ({
  game,
  commonProps,
  onChangeHandler,
}) => {
  const [disableMenu, setDisableMenu] = useState<boolean>(
    game.options.disableMenu || false
  );
  const [fields, setFields] = useState(game);
  const [allowSetSize, setAllowSetSize] = useState(
    game.options.menuScreenOptions?.menuImage ? true : false
  );
  const disableMenuHandler = () => {
    const updatedEntity = {
      disableMenu: !disableMenu,
    };
    setDisableMenu(!disableMenu);
    onChangeHandler(updatedEntity);
    setFields({ ...fields, ...updatedEntity }); //maybe take this off!!!
  };

  return (
    <>
      <div className="md:col-start-1 md:col-span-2	">
        <Switch
          className="w-52"
          checkedChildren="Menu screen is Enabled"
          unCheckedChildren="Menu screen is disabled"
          defaultChecked={disableMenu}
          onChange={disableMenuHandler}
        />
      </div>
      {!disableMenu && (
        <h1 style={{ color: 'red', whiteSpace: 'nowrap' }}>
          * Turn this switch ON if you want a menu, before the game starts *
        </h1>
      )}
      {disableMenu && (
        <>
          <EditOptionsCard
            title="Discount Text?"
            value1="Yes"
            value2="No"
            defaultValue={
              game.options.menuScreenOptions?.isDiscountTextVisible ? 'Yes' : 'No'
            }
            onChange={(value) => {
              console.log(value);
              onChangeHandler({
                menuScreenOptions: {
                  ...game.options.menuScreenOptions,
                  isDiscountTextVisible: value === 'Yes' ? true : false,
                },
              });
            }}
          />
          {game.options.menuScreenOptions?.isDiscountTextVisible && (
          <EditTextCard
            {...commonProps}
            type="text"
            title="Discount Text"
            onSave={(fields: any) =>
              onChangeHandler({
                menuScreenOptions: {
                  ...game.options.menuScreenOptions,
                  ...fields,
                },
              })
            }
            items={{
              discount1: game.options?.menuScreenOptions?.discount1 || '50%',
              discount2: game.options?.menuScreenOptions?.discount2 || 'OFF',
              discount3: game.options?.menuScreenOptions?.discount3 || null,
            }}
            formItems={[
              {
                name: 'discount1',
                title: '',
              },
              {
                name: 'discount2',
                title: '',
              },
              {
                name: 'discount3',
                title: '',
              },
            ]}
          />)}
          <EditOptionsCard
            title="Form Before?"
            value1="Yes"
            value2="No"
            defaultValue={
              game.options.menuScreenOptions?.formBefore ? 'Yes' : 'No'
            }
            onChange={(value) => {
              console.log(value);
              onChangeHandler({
                menuScreenOptions: {
                  ...game.options.menuScreenOptions,
                  formBefore: value === 'Yes' ? true : false,
                },
              });
            }}
          />
          {game.options.menuScreenOptions?.formBefore && (
            <FormCard game={game} onChangeHandler={onChangeHandler} />
          )}

          <EditTextCard
            {...commonProps}
            type="text"
            title="Titles"
            onSave={(fields: any) =>
              onChangeHandler({
                menuScreenOptions: {
                  ...game.options.menuScreenOptions,
                  ...fields,
                },
              })
            }
            items={{
              buttonText:
                game.options?.menuScreenOptions?.buttonText || 'START GAME',
              textTitle:
                game.options?.menuScreenOptions?.textTitle || 'PLAY OUR GAME',
              textSubtitle:
                game.options?.menuScreenOptions?.textSubtitle ||
                game?.type.toUpperCase(),
              textBottom:
                game.options?.menuScreenOptions?.textBottom || 'TO WIN:',
            }}
            formItems={[
              {
                name: 'textTitle',
                title: 'Text Title',
              },
              {
                name: 'textSubtitle',
                title: 'Subtitle',
              },
              {
                name: 'textBottom',
                title: 'Bottom Text',
              },
              {
                name: 'buttonText',
                title: 'Button Text',
              },
            ]}
          />
          <EditImageCard
            {...commonProps}
            fileName={game.options.menuScreenOptions?.music || ''}
            removeDelete={true}
            title="MUSIC"
            description={{
              type: 'mp3',
            }}
            defaultValue={game.options.menuScreenOptions?.music || ''}
            onChange={(field: string) =>
              onChangeHandler({
                menuScreenOptions: {
                  ...game.options.menuScreenOptions,
                  music: field,
                },
              })
            }
          />
          <EditImageCard
            {...commonProps}
            fileName={game.options.menuScreenOptions?.menuImage || ''}
            removeDelete={false}
            title="MENU IMAGE"
            description={{
              type: 'image',
            }}
            defaultValue={
              game.options.menuScreenOptions?.menuImage ||
              `https://res.cloudinary.com/shulgirit/image/upload/v1640099101/wiply/Platform%20Default%20Images/games/${game.type}.svg`
            }
            onChange={(field: string) => {
              setAllowSetSize(true);
              onChangeHandler({
                menuScreenOptions: {
                  ...game.options.menuScreenOptions,
                  menuImage: field,
                },
              });
            }}
          />
          {allowSetSize ? (
            <SliderImageSize game={game} onChangeHandler={onChangeHandler} />
          ) : null}
        </>
      )}
    </>
  );
};

export default GameMenuTab;
