import axios from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { UserDataLayout, UserIcon } from './UserIcon';

interface Props {
  apiUrl: string;
  endpointInstance?: string;
  warning?: boolean;
}

export const FetchedContentCard: FunctionComponent<Props> = React.memo(
  ({ apiUrl, endpointInstance, warning }) => {
    const [data, setData] = useState<string>();

    useEffect(() => {
      const getAnalytics = async () => {
        const response = await axios.get(apiUrl, {
          method: 'GET',
        });
        if (response) {
          setData(response.data[endpointInstance] || '0');
        }
      };
      getAnalytics();
    }, []);
    return (
      <>
        <UserDataLayout color={warning ? 'red' : ''}>
          {data && (
            <>
              <UserIcon warning={JSON.stringify(warning)} />
              {data}
            </>
          )}
          {!data && <SyncOutlined spin className="text-violet" />}
        </UserDataLayout>
        {warning && (
          <span className="text-red text-center font-bold text-xs mt-2 px-4">
            Limit Exceeded - Upgrade plan
          </span>
        )}
      </>
    );
  }
);

FetchedContentCard.displayName = 'FetchedContentCard';
export default FetchedContentCard;
