import { environment } from '@env/environment';
import { PayPalButtons } from '@paypal/react-paypal-js';
import moment from 'moment';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ClientsContext from '../../context/clients';
import { update } from '../../features/users';
import { RootState } from '../../reducers';
import Button from '../shared/Button';
import styles from './Plan.module.css';
import { CreditCardOutlined } from '@ant-design/icons';
import SendEmailModal from '../Modal/Contact/SendEmailModal';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

interface DescriptionInterface {
  name: string;
  value: string | React.ReactElement;
}

interface Props {
  type: string;
  title: string;
  description: DescriptionInterface[];
  amount?: string;
  id: string;
  contact?: boolean;
}

const Plan: FunctionComponent<Props> = ({
  type,
  title,
  description,
  amount,
  id,
  contact,
}) => {
  const currentUser = useSelector((state: RootState) => state.userReducer);
  const userPlanId = currentUser.user.planId;
  const { updateClient } = useContext(ClientsContext);
  const [showPayButton, setShowPayButton] = useState(false);
  const [showSubscribe, setShowSubscribe] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();
  return (
    <article
      className={`rounded-xl shadow-3xl bg-white flex flex-col items-center border-white border-2 pb-10 my-16 lg:my-0 ${styles.cards}`}
    >
      <span className="shadow-lg bg-gradient-to-r from-violet py-2 to-violet-light font-light px-12 text-xl lg:text-3xl rounded-md text-white relative -top-5">
        {type} {userPlanId === id ? '- Current Plan' : ''}
      </span>
      <h3 className="text-violet-light font-bold text-xl lg:text-2xl text-center pt-8">
        {title}
      </h3>
      <div className="grid gap-2 border-gray mt-2 mb-4 	lg:text-2xl leading-loose">
        <div className="p-2">
          {description.map(({ name, value }, i) => (
            <div key={i} className="p-1 pt-10">
              {value}
              {<span className="ml-4 text-xl 2xl:text-2xl">{name}</span>}
            </div>
          ))}
        </div>
      </div>
      {userPlanId !== id && !contact && showSubscribe && (
        <Button
          title={!userPlanId ? 'Subscribe' : 'Upgrade'}
          onClick={() => {
            if (!userPlanId) {
              setShowPayButton(true);
              setShowSubscribe(false);
            } else {
              setShowEmailModal(true);
            }
          }}
          className="text-2xl py-6 mt-4"
        />
      )}
      {userPlanId !== id && contact && (
        <Button
          title="Contact Us"
          onClick={() => setShowEmailModal(true)}
          className="text-2xl py-6 mt-4"
        />
      )}
      {showEmailModal && (
        <SendEmailModal
          emailTemplateId="contact"
          onCloseHandler={() => setShowEmailModal(false)}
        />
      )}
      {userPlanId !== id && contact && showForm && (
        <div className="mt-4">
          <Button
            title="form Us"
            onClick={() => setShowForm(true)}
            className="text-2xl py-6"
          />
        </div>
      )}
      {userPlanId === id && currentUser.user?.payment?.subscriptionId && (
        <div className="ant-btn bg-gradient-to-r from-violet to-violet-light hover:bg-violet hover:border-transparent rounded-lg shadow-md flex justify-center text-2xl text-2xl py-6 mt-4 items-center">
          <a
            href={`${environment.payPal.url}myaccount/autopay/connect/${currentUser.user?.payment?.subscriptionId}/cancel`}
            target="_blank"
            style={{ color: 'white' }}
          >
            Unsubscribe
          </a>
        </div>
      )}

      {showPayButton && (
        <div className="mt-4">
          <PayPalButtons
            disabled={false}
            forceReRender={[amount || 0, 'USD', null]}
            fundingSource={undefined}
            createSubscription={(data, actions) => {
              return actions.subscription
                .create({
                  plan_id: environment.payPal.plans[id],
                })
                .then((orderId) => {
                  return orderId;
                });
            }}
            style={{
              label: 'subscribe',
            }}
            onApprove={function (data, actions) {
              return actions.subscription.get().then(function () {
                // Your code here after capture the order
                const userId = currentUser.user.id || currentUser.user.uid;
                updateClient(userId, {
                  planId: id,
                  freePlan: false,
                  payment: {
                    date: moment().format('YYYYMMDDhhmmss'),
                    subscriptionId: data.subscriptionID,
                    orderId: data.orderID,
                  },
                });
                dispatch(update(userId));
                navigate('/');
              });
            }}
          />
        </div>
      )}
    </article>
  );
};

export default Plan;
