import React, { FunctionComponent } from 'react';
import SVG from 'react-inlinesvg';
import Button from '../shared/Button';
import { PlusCircleOutlined } from '@ant-design/icons';
import styles from './GameListItem.module.scss';

interface Props {
  title: string;
  id: string;
  onClickHandler: () => void;
  unActive: boolean;
  size?: string;
  hideButton?: boolean;
}

export const GameListItem: FunctionComponent<Props> = ({
  title,
  id,
  onClickHandler,
  unActive,
  size,
  hideButton,
}) => (
  <div className={styles.card}>
    <div className={styles.box}>
      <div
        onClick={unActive ? null : onClickHandler}
        className={`${styles.content} flex flex-col items-center ${
          !unActive ? 'cursor-pointer' : 'cursor-not-allowed'
        }`}
        style={{ padding: '12px' }}
      >
        <p
          className={`text-gray-dark font-bold ${
            hideButton ? 'text-xs' : 'text-lg'
          } mb-2`}
        >
          {title}
        </p>
        <SVG
          src={`https://res.cloudinary.com/shulgirit/image/upload/v1641469847/wiply/Platform%20Default%20Images/games/${id}.svg`}
          className={`mt-2 w-${size} h-${size}`}
          loader={
            <img
              className={`mt-2 w-${size} h-${size}`}
              src="../../assets/images/tinygif.gif"
            />
          }
        />
      </div>
      {!hideButton && (
        <Button
          title="Create Game"
          style="inverted"
          disableStyles={true}
          className="text-white hover:text-white w-full mt-4 lg:rounded-b-2xl rounded-t-none p-2 h-auto gradient border-0 text-md px-4 justify-center items-center flex"
          onClick={onClickHandler}
          disabled={unActive}
          icon={<PlusCircleOutlined />}
        />
      )}
    </div>
  </div>
);

export default GameListItem;
