import { BaseRepository } from './base.repository';
import { Player } from '@wiply/repository';
import 'firebase/firestore';

export class PlayerRepository extends BaseRepository<Player> {
  constructor(gameId: string) {
    super(`games/${gameId}/players`);
  }

  incrementRetriesCount(id: string) {
    this.increment(id, 'retriesCount', 1);
  }

  async create(id: string, entity: Player) {
    try {
      this.collectionRef.doc(id).set(entity, { merge: true });
      return id;
    } catch (e) {
      console.log(e);
    }
  }

  async fetchPlayerDetails(email: string) {
    try {
      const querySnapshot = await this.collectionRef
        .where('email', '==', email)
        .get();
      querySnapshot.docs.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, ' => ', doc.data());
      });
      return querySnapshot.docs[0];
    } catch (error) {
      console.log('Error getting documents: ', error);
      return null;
    }
  }
}
