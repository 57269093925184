import React, { FunctionComponent } from 'react';
import styles from './modal.module.scss';

interface Props {
  children: any;
}

export const Modal: FunctionComponent<Props> = ({ children }) => {
  return <div className={styles['modal-wrapper']}>{children}</div>;
};

export default Modal;
