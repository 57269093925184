import { Tabs } from 'antd';
import React, {
  useRef,
  useState,
  FunctionComponent,
  useEffect,
  useContext,
} from 'react';
import GamesContext from '../context/games';
import EditImageCardMemory from '../components/GameDesignCard/EditImageCard/EditImageCardMemory';
import EditImageCard, {
  DescriptionField,
} from '../components/GameDesignCard/EditImageCard/EditImageCard';
import { omitNull } from '@wiply/utils';

interface fields {
  keyT: number;
  index: number;
}

interface EditTabsProps {
  options: any;
  onChangeHandler;
  instance;
  groupInstance;
  commonProps;
  defaultImages?;
  gallery?: any;
  differentPictures: Boolean;
  description?: DescriptionField;
}

const EditableTab: FunctionComponent<EditTabsProps> = ({
  options,
  onChangeHandler,
  instance,
  groupInstance,
  commonProps,
  defaultImages,
  gallery,
  differentPictures,
  description,
}) => {
  const [activeKey, setActiveKey] = useState(`${0}`);
  const [items, setItems] = useState(null);
  let initialItems;
  if (differentPictures) {
    interface Values {
      key: string;
      children: any;
    }

    const temp: Array<Values> = options?.differentPicsObject
      ? Object.values(options?.differentPicsObject)
      : [];

    if (temp.length === 0) {
      initialItems = [
        {
          image1:
            'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/5.png',
          image2:
            'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/5.png',
        },
        {
          image1:
            'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/5.png',
          image2:
            'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/5.png',
        },
      ];
    } else {
      let formattedData = [];

      for (var i = 0; i < temp.length; i += 2) {
        formattedData.push({
          image1: options.differentPicsObject[i][0],
          image2: options.differentPicsObject[i + 1][0],
        });
      }

      initialItems = [...formattedData];
    }
  } else {
    const temp: Array<string> = options?.cardImages
      ? Object.values(options?.cardImages)
      : [];

    let formattedData = [];
    for (var i = 0; i < temp.length; i++) {
      formattedData.push({
        image1: temp[i],
      });
    }

    initialItems = [...formattedData];
  }

  useEffect(() => {
    setItems(initialItems);
  }, [differentPictures]);

  // this changes what tab you're on
  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };

  // this adds another tab to total tabs
  const add = () => {
    const newPanes = [...items];
    const newActiveKey = `${newPanes.length}`;
    if (differentPictures) {
      newPanes.push({
        image1:
          'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/5.png',
        image2:
          'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/5.png',
      });
    } else {
      newPanes.push({
        image1:
          'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/5.png',
      });
    }

    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  // this removes another tab to total tabs
  const remove = (targetKey: string) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (i == targetKey) {
        lastIndex = i - 1;
      }
    });
    console.log('items', items);
    console.log('targetkey', targetKey);
    const newPanes = items.filter((item, i) => i != targetKey);

    if (differentPictures) {
      // removes deleted tab images from local variable items
      var object = {};
      for (var i = 0; i < newPanes.length; i++) {
        object[i * 2] = [newPanes[i].image1, i];
        object[i * 2 + 1] = [newPanes[i].image2, i];
      }

      // pushes local changes to server
      onChangeHandler({
        [groupInstance]: {
          ...options,
          [instance]: object,
        },
      });
    } else {
      var object = {};
      for (var i = 0; i < newPanes.length; i++) {
        object[i] = newPanes[i].image1;
      }

      // pushes local changes to server
      onChangeHandler({
        [groupInstance]: {
          ...options,
          [instance]: object,
        },
      });
    }

    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  const onEdit = (targetKey: string, action: 'add' | 'remove') => {
    if (action === 'add') {
      add();
    } else {
      remove(targetKey);
    }
  };

  function checkClosable(number) {
    let result
    switch(number){
      case 1:
        result = false
        break
      case 2:
        result = false
        break
      case 3:
        result = false
        break
      case 4:
        result = false
        break
      case 5:
        result = false
        break
      case 6:
        result = false
       break
      default:
        result = true
    }
    return result

  }

  return (
    <Tabs
      type="editable-card"
      onChange={onChange}
      activeKey={activeKey}
      onEdit={onEdit}
    >
      {!differentPictures ? (
        <>
          {console.log(items)}
          {items &&
            items.map(({ image1 }, index) => {
              console.log(image1);
              return (
                <Tabs.TabPane tab={`Pair ${index + 1}`} closable={checkClosable(index + 1)} key={index}>
                  <EditImageCard
                    className="my-4"
                    {...commonProps}
                    key={index}
                    title={index}
                    accept="image/*"
                    defaultValue={image1}
                    multiple={false}
                    description={description}
                    onChange={(field: string) => {
                      console.log(field);
                      var newPanes = [...items];
                      newPanes[index]['image1'] = field;

                      setItems(newPanes);

                      onChangeHandler({
                        [groupInstance]: {
                          ...options,
                          [instance]: {
                            ...options.cardImages,
                            [index]: field,
                          },
                        },
                      });
                    }}
                    size="auto"
                    defaultImagesToChoose={gallery}
                  />
                </Tabs.TabPane>
              );
            })}
        </>
      ) : (
        <>
          {items &&
            items.map(({ image1, image2 }, index) => {
              let temp = index * 2;
              return (
                <Tabs.TabPane tab={`Pair ${index + 1}`} key={index} closable={checkClosable(index + 1)}>
                  <EditImageCardMemory
                    className="my-4"
                    {...commonProps}
                    title={index}
                    key={'M' + index}
                    removeDelete={true}
                    accept="image/*"
                    defaultValue={image1}
                    defaultValue2={image2 || image1}
                    multiple={true}
                    onChange={(field1: string, field2: string) => {
                      onChangeHandler({
                        [groupInstance]: {
                          ...options,
                          differentPicsObject: {
                            ...options.differentPicsObject,
                            [temp++]: [field1, index],
                            [temp]: [field2, index],
                          },
                        },
                      });
                    }}
                    size="auto"
                  />
                </Tabs.TabPane>
              );
            })}
        </>
      )}
    </Tabs>
  );
};

export default EditableTab;
