import React, { useContext, FunctionComponent, useEffect } from 'react';
import ClientsContext from '../context/clients';
import ClientsTable from '../components/clients/ClientsTable';
import Spinner from '../components/shared/Spinner';

const Clients: FunctionComponent = () => {
  const { clients, subscribeClients, removeClient } = useContext(
    ClientsContext
  );

  useEffect(() => {
    const unsubscribe = subscribeClients();
    return () => unsubscribe();
  }, []);

  if (clients.length === 0 ) return <Spinner />;
  return (
    <div className="my-12">
      <ClientsTable clients={clients} removeClient={removeClient} />
    </div>
  );
};

export default Clients;
