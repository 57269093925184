import React, { FunctionComponent, useEffect, useState } from 'react';
import Input from 'antd/lib/input';
import { GameCoupon } from '@wiply/repository';
import Button from '../../shared/Button';
import { Checkbox, Alert } from 'antd';
import EditOptionsCard from '../../GameDesignCard/EditOptionsCard';

interface Props {
  coupon: GameCoupon;
  loosingCoupon?: boolean;
  updateCoupon: (fields: GameCoupon) => void;
}

export const SettingsForm: FunctionComponent<Props> = ({
  coupon,
  loosingCoupon,
  updateCoupon,
}) => {
  const [fields, setFields] = useState(coupon);
  useEffect(() => {
    setFields({ ...coupon });
  }, [coupon]);

  const [alert, setAlert] = useState(false);

  return (
    <div className="p-4">



      <>
        <Input.Group compact>

          <Input
            name="claimUrl"
            placeholder='Destination url'
            defaultValue={
              coupon.claimUrl ? coupon.claimUrl.replace('https://', '') : ''
            }
            onChange={(e) => {
              const { name, value } = e.target;
              setAlert(true);
              const validateValue = value.replace('https://', '');
              setFields({ ...coupon, [name]: `https://${validateValue}` });

            }}
            className=" w-full lg:w-3/5 rounded-lg"
            allowClear={true}
            addonBefore="https://"
          />
          <span className='pl-4 mt-2 lg:mt-1 '> Code:</span>
          <Input
            name="claimUrl"

            defaultValue={coupon.code}
            onChange={(e) => {
              setAlert(true);
              setFields({ ...coupon, code: e.target.value });
            }}
            className="w-2/5 lg:w-1/5 rounded-lg ml-1 mt-2 lg:mt-0 "
            allowClear={true}
          />
        </Input.Group>

      </>

      <div className='flex flex-row'>
 

            <Input.Group compact className='mt-6'>
              <Input
                addonBefore="serviceId:"
                name="serviceId"
                placeholder='clientId'
                defaultValue={coupon?.emailSettings?.serviceId || ""}
                onChange={(e) => {
                  coupon.emailSettings.serviceId = e.target.value;
                  setAlert(true);
                  setFields({ ...coupon });
                }}
                className=" w-full lg:w-1/2 rounded-lg"
                allowClear={true}

              />
              <Input
                name="TemplateId"
                addonBefore="TemplateId:"
                placeholder='templateId'
                defaultValue={coupon?.emailSettings?.templateId || ""}
                onChange={(e) => {
                  coupon.emailSettings.templateId = e.target.value;
                  setAlert(true);
                  setFields({ ...coupon });
                }}
                className="w-full lg:w-1/2 rounded-lg ml-1 mt-2 lg:mt-0 "
                allowClear={true}
              />
            </Input.Group>

      </div>
      <div className='flex flex-row'>
        <label>Collect Data with google sheets <Input
                addonBefore="SheetsBest API:"
                name="SheetsBest API"
                placeholder='SheetsBest URL Here'
                defaultValue={coupon?.sheetsBestAPI || ""}
                onChange={(e) => {
                  coupon.sheetsBestAPI = e.target.value;
                  setAlert(true);
                  setFields({ ...coupon });
                }}
                className=" w-full lg:w-1/2 rounded-lg"
                allowClear={true}

              /> </label>
      </div>
        <div className='flex flex-row '>
        {!loosingCoupon && (
          <Checkbox
            onChange={(e) => {
              setAlert(true);
              setFields({ ...coupon, copyCode: e.target.checked })
            }
            }
            className="mt-4 w-3/5"
            defaultChecked={coupon.copyCode}
          >
            AutoCopy code
          </Checkbox>
        )}
          <Button
            title="Save"
            style="inverted"
          className={` w-2/5 m-auto mt-4  h-auto ${alert ? " animate-bounce	 " : ""}`}
            onClick={() => {
              updateCoupon(fields);
              setAlert(false);
            }}
          />
        </div>

        <EditOptionsCard
        title={'Winning effects'}
        value1="confetti"
        value2="firework"
        value3="off"
        defaultValue={coupon?.firework ? 'firework' : coupon?.confetti ? 'confetti' : 'off'}
        onChange={(field: string) => {
          let newSettings
          if(field === 'confetti'){
            newSettings = { confetti: 'true', firework: null}
          }else if(field === 'firework'){
            newSettings = { confetti: null, firework: 'true'}
          }else{
            newSettings = { confetti: null, firework: null}
          }
          setAlert(true);
          setFields({
            ...coupon,
            ...newSettings
          }) 

        }}
      ></EditOptionsCard>
      
    
      {alert && (
        <div className='mt-2'>
          <Alert message="Dont forget to save your changes" type="error" />
        </div>
      )}


    </div>
  );
};

export default SettingsForm;
