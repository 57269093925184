import React, { useContext, FunctionComponent, useEffect, useRef, useState } from 'react';
import GamesContext, { GamesContextProvider } from '../context/games';
import { Link, Navigate, useParams } from 'react-router-dom';
import { Game as GameComponent } from '../components/Game';
import Spinner from '../components/shared/Spinner';
import { SubdomainContextProvider } from '../context/subdomain';
import { gamesList, GameTypeEnum } from '@wiply/utils';
import { GameDesignTab } from '../components/editGameTabs/GameDesignTab';
import { Empty } from 'antd';
import Title from '../components/shared/Title';
import { NewGame } from '../components/games/NewGame';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import { PlayersContextProvider } from '../context/players';
import GameScores from '../components/games/GameScores';
import GameDataTab from '../components/editGameTabs/GameDataTab';
import GameAnalyticsTab from '../components/editGameTabs/GameAnalyticsTab';

const Game: FunctionComponent = () => {
  const [key, setKey] = useState(Date.now()); // new state for the key
  const { name } = useParams<{ name: string }>();
  const { game, subscribeGame } = useContext(GamesContext);
  const currentUser = useSelector((state: RootState) => state.userReducer);
  const iframeRef = useRef();
  console.log('In Game');

  
  const refreshIframe = () => {
    setKey(Date.now()); // change the key which will cause re-render
  };

  useEffect(() => {
    const unsubscribe = subscribeGame(name);
    return () => unsubscribe();
  }, [name]);
  if (game === undefined) return <Spinner />;
  const uid = currentUser.user.id;
  if (
    name === 'signup' ||
    name === 'login' ||
    (!currentUser?.isAdmin &&
      game?.clientId &&
      game?.clientId !== uid?.toLowerCase())
  )
    return <Navigate to="/" />;
  if (!game)
    return (
      <div className="flex justify-center items-center flex-col my-12">
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{
            height: 60,
          }}
          description={<span>Game Not Found</span>}
        >
          <Link to="/" className="text-violet">
            Back to the main page
          </Link>
        </Empty>
      </div>
    );
  const { id, type } = game;
  return (
    <div className="mt-6 md:mt-12">
      <SubdomainContextProvider gameId={id}>
        <div className="lg:grid grid-cols-2 lg:mt-10">
          <div>
            <GameDesignTab 
              game={game} 
              iframeRef={iframeRef} 
              refreshIframe={refreshIframe}  
            />
          </div>
          <div>
            <GameComponent
              key={key}
              gameId={id}
              hideMobile={type == GameTypeEnum.Ninja}
              gameType={type}
              iframeRef={iframeRef}
            />
          </div>
        </div>
        {currentUser.isAdmin && (
          <div className="my-24 lg:w-1/2">
            <PlayersContextProvider gameId={game.id}>
              <GameScores />
            </PlayersContextProvider>
          </div>
        )}
        <div className="lg:mt-12 lg:flex lg:flex-row lg:gap-12">
          <GameDataTab />
          <GameAnalyticsTab />
        </div>

        <div className="mt-12">
          <NewGame
            games={gamesList}
            hasGames={true}
            clientId={currentUser.user.uid}
            title="Create another game"
          />
        </div>
      </SubdomainContextProvider>
    </div>
  );
};

const GameContainer: FunctionComponent = () => {
  return (
    <GamesContextProvider>
      <Game />
    </GamesContextProvider>
  );
};

export default GameContainer;

// http://localhost:4200/login/?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTY4MDUyMTgyMywiZXhwIjoxNjgwNTI1NDIzLCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay10am1lM0B3aXBseS1kZXZlbG9wbWVudC5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInN1YiI6ImZpcmViYXNlLWFkbWluc2RrLXRqbWUzQHdpcGx5LWRldmVsb3BtZW50LmlhbS5nc2VydmljZWFjY291bnQuY29tIiwidWlkIjoidjBnY3BTb0lDMVZmbzFvSjFIS2QxaFo0c0w4MiJ9.NuOluryh3-SbnD_GLa4oriYGHDO3CJ0xwD8mfmQ367VVE4f41HptTMBVTUHDURllreByuxmojE_cJM4tm-5L4Bt8cn4VWx-oTaFXO8gJ-dBfy_tvHj_9vCxoWHeYz4AjJsxoUhg6O7jBaLxfJvlwGNcp6HjSNDuk7_VLnec2KfXKRmIQ7ZlnNubAE3_H25u3hTd-v1Xz5lnNaM0EBX8q61jKcTOOZCognLze3oWTLWqt965wFS7mMldUiCVeFFRtLhkNEKBMFhppPJVssfmBdCdpXF73uy35C9rTf2lWuUlAFr4ivT51o9TdZ9fiskOKVsQn3_dEbd5VlEHfD2AItw&gameId=ffd85fec-4e94-4edb-bcd2-27ecf4ee57b1

// http://localhost:4200/ffd85fec-4e94-4edb-bcd2-27ecf4ee57b1/?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTY4MDUyMTgyMywiZXhwIjoxNjgwNTI1NDIzLCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay10am1lM0B3aXBseS1kZXZlbG9wbWVudC5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInN1YiI6ImZpcmViYXNlLWFkbWluc2RrLXRqbWUzQHdpcGx5LWRldmVsb3BtZW50LmlhbS5nc2VydmljZWFjY291bnQuY29tIiwidWlkIjoidjBnY3BTb0lDMVZmbzFvSjFIS2QxaFo0c0w4MiJ9.NuOluryh3-SbnD_GLa4oriYGHDO3CJ0xwD8mfmQ367VVE4f41HptTMBVTUHDURllreByuxmojE_cJM4tm-5L4Bt8cn4VWx-oTaFXO8gJ-dBfy_tvHj_9vCxoWHeYz4AjJsxoUhg6O7jBaLxfJvlwGNcp6HjSNDuk7_VLnec2KfXKRmIQ7ZlnNubAE3_H25u3hTd-v1Xz5lnNaM0EBX8q61jKcTOOZCognLze3oWTLWqt965wFS7mMldUiCVeFFRtLhkNEKBMFhppPJVssfmBdCdpXF73uy35C9rTf2lWuUlAFr4ivT51o9TdZ9fiskOKVsQn3_dEbd5VlEHfD2AItw

// http://localhost:4200/aa6375ff-0649-4075-87c4-d36f9ed52996/?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTY4MDUyNjc1MiwiZXhwIjoxNjgwNTMwMzUyLCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay10am1lM0B3aXBseS1kZXZlbG9wbWVudC5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInN1YiI6ImZpcmViYXNlLWFkbWluc2RrLXRqbWUzQHdpcGx5LWRldmVsb3BtZW50LmlhbS5nc2VydmljZWFjY291bnQuY29tIiwidWlkIjoiUWFnNHFGTm5Zc01telIwNHlzd0lQbWQxdERjMiJ9.WYD-N5HiLxSUvfElLhyF4KGMo6jTFv-AMUgTFSv6y0fFU8GpVSixgXWSaCacPTlEhMHTicl4SRJLDkvAaLlI8MH6xHTPR-WT0HMsKPTvAWAvAM2h3-psiKU9UzdbJbwvqO3FqrjAuSmk9tXNaM2BE9rOj3XF-JnjkXMncHEqlPbfhPk53efMptpELhOraBG1VFJxmvq1u3zyKLFwWLDa5mfCDpCYeHk4_T-IV5fPvsSe71fPJ08NuQ3rP7QbFO8koLh_ZJiZazYaS7VEkWdRdZC-JLwBaHDhy-6W6BDwyQiMYAqNRxq6ZqCA620aki_f2ToDXec1u0oNHjI2tYqFXA
