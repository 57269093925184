import React, { FunctionComponent, useContext, useEffect } from 'react';
import { SubdomainContextProvider } from '../context/subdomain';
import GamesContext from '../context/games';
import Spinner from '../components/shared/Spinner';
import GamesTable from '../components/clients/GamesTable';

const Games: FunctionComponent = () => {
  const { getGames, games } = useContext(GamesContext);
  useEffect(() => {
    const unsubscribe = getGames();
    return () => unsubscribe();
  }, []);

  if (games?.length === 0) return <Spinner />;
  return (
    <div className="my-8">
      <h2 className="text-violet text-2xl font-meidum px-2 mb-4">All games</h2>
      <SubdomainContextProvider gameId={null}>
       <GamesTable games={games} pagination={true} />
      </SubdomainContextProvider>
    </div>
  );
};

export default Games;
