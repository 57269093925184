import React, { FunctionComponent, useMemo, useState } from 'react';

import { EditColorAction } from '../GameDesignCard/EditColorAction';
import EditColorCard from '../GameDesignCard/EditColorCard';
import EditImageCard from '../GameDesignCard/EditImageCard/EditImageCard';
import EditOptionsCard from '../GameDesignCard/EditOptionsCard';
import EditTextCard from '../GameDesignCard/EditTextCard';
import { GameDesignAssetsProps } from '.';
import { GameModes } from '@wiply/repository';
import { ballGallery, boardImages } from '@wiply/utils';
import VideoModalButton from '../EmbedVideoModal';

export const GameDesignAssetsBasketball: FunctionComponent<GameDesignAssetsProps> = ({
  game,
  commonProps,
  onChangeHandler,
}) => {
  // const [showScoreColor, setShowScoreColor] = useState(
  //   game.options.basketballOptions?.showScore === 'true'
  // );
  console.log(game.options.basketballOptions?.gameMode);
  const [gameMode, setGameMode] = useState(game.options.basketballOptions?.gameMode || GameModes.SHOTCLOCK);

  const extraAction = useMemo(() => {
    return (

      <EditColorAction
        title="Change Color"
        onChangeHandler={(field) => {
          let colors = [...game.options.colors];
          colors[0] = field;
          onChangeHandler({ colors });
        }}
        defaultValue={game.options.colors[0]}
      />
    );
  }, [game]);

  const extraAction2 = useMemo(() => {
    return (
      <EditColorAction
        title="Change Color"
        onChangeHandler={(field) => {
          let colors = [...game.options.colors];
          colors[1] = field;
          onChangeHandler({ colors });
        }}
        defaultValue={game.options.colors[1]}
      />
    );
  }, [game]);

  return (
    <>
      <div>

        <VideoModalButton videoSrc='https://res.cloudinary.com/shulgirit/video/upload/v1689502246/wiply/video%20tutorials/jesse-video-tutorials/Basketball-Tutorial.mp4'></VideoModalButton>
        {/* Basketball Ball Image */}
        <div className='mt-4'>
          <EditImageCard
            {...commonProps}
            title="Basketball Ball Image"
            description={{
              type: 'PNG',
              aspectRatio: Number(1080 / 1068),
              size: '1080x1068',
              text: (
                <span>
                  Flat image that covers the <br></br>3d ball element
                </span>
              ),
            }}
            accept="image/png"
            defaultValue={game.options.basketballOptions.ballImg}
            onChange={(field: string) =>
              onChangeHandler({
                basketballOptions: {
                  ...game.options.basketballOptions,
                  ballImg: field,
                },
              })
            }
            defaultImagesToChoose={ballGallery}
            previewCss="rounded-full h-24 w-24 flex items-center justify-center px-0 shadow-3xl"
            containerCSS="w-full mx-auto"
          />
        </div>
      </div>

      <EditImageCard
        {...commonProps}
        title="Base color"
        description={{}}
        deleteGallery={true}
        disableUpload={true}
        removeDelete={true}
        accept="image/png, image/jpg, image/jpeg"
        defaultValue="https://res.cloudinary.com/shulgirit/image/upload/v1635856551/wiply/Platform%20Default%20Images/basketball/basket-stand3_rthoea.jpg"
        onChange={(field: string) =>
          onChangeHandler({
            basketballOptions: {
              ...game.options.basketballOptions,
              boardImg: field,
            },
          })
        }
        extraActions={extraAction2}
        previewCss="max-h-36 my-0"
      />

      {/* Basketball Board Image */}

      <EditImageCard
        {...commonProps}
        title="Board Image"
        description={{
          type: 'PNG, JPG',
          size: '199x124',
          aspectRatio: Number(199 / 124),
          text: (
            <span>
              The best option is to use a <br></br> white color image and to
              change the <br></br> text color with the "Ball color" field
            </span>
          ),
        }}
        accept="image/png, image/jpg, image/jpeg"
        defaultValue={game.options.basketballOptions.boardImg}
        onChange={(field: string) =>
          onChangeHandler({
            basketballOptions: {
              ...game.options.basketballOptions,
              boardImg: field,
            },
          })
        }
        defaultImagesToChoose={boardImages}
        extraActions={extraAction}
        previewCss="shadow-lg"
      />
      <EditOptionsCard
        title={'Please select type of game mode'}
        defaultValue={
          game.options.basketballOptions?.gameMode || GameModes.SCOREBOARD
        }
        onChange={(field: string) => {

          switch (field) {
            case GameModes.SHOTCLOCK:
            case GameModes.SCOREBOARD:
            case GameModes.NORMAL:
              setGameMode(field)


          }
          onChangeHandler({
            basketballOptions: {
              ...game.options.basketballOptions,
              gameMode: field,
            },
          });
        }}
        value1={GameModes.SHOTCLOCK}
        value2={GameModes.SCOREBOARD}
        value3={GameModes.NORMAL}
      />
      {gameMode !== 'shotClock' && <EditTextCard
        type="text"
        {...commonProps}
        title="Enter number of Basketballs player has"
        onSave={(fields: any) => {
          onChangeHandler({
            basketballOptions: {
              ...game.options.basketballOptions,
              balls: parseInt(fields.numBasketBallPlayerHas),
            },
          });
        }}
        items={{
          numBasketBallPlayerHas: game.options.basketballOptions.balls || 6,
        }}
        formItems={[
          {
            name: 'numBasketBallPlayerHas',
            title: 'Number of Basketballs (max 10 balls)',
          },
        ]}
      />}
      {gameMode !== 'shotClock' && <EditTextCard
        type="text"
        {...commonProps}
        title="Enter number of baskets player must make"
        onSave={(fields: any) => {
          onChangeHandler({
            basketballOptions: {
              ...game.options.basketballOptions,
              level: parseInt(fields.numBaskestsToMake),
            },
          });
        }}
        items={{
          numBaskestsToMake: game.options.basketballOptions.level || 3,
        }}
        formItems={[
          {
            name: 'numBaskestsToMake',
            title: 'Number of Baskets to make (max 5 baskets)',
          },
        ]}
      />}

      {gameMode === 'scoreboard' && <EditTextCard
        type="text"
        {...commonProps}
        title="Enter Scoreboard mode options"
        onSave={(fields: any) => {
          console.log(fields)
          onChangeHandler({
            basketballOptions: {
              ...game.options.basketballOptions,
              timesArray: Array(parseInt(fields?.numBaskets)).fill(fields?.time * 1000),
            },
          });
        }}
        items={{
          numBaskets: game.options.basketballOptions.timesArray?.length || 3,
          time: (game.options.basketballOptions?.timesArray ? game.options.basketballOptions?.timesArray[0] : 1000) / 1000
        }}
        formItems={[
          {
            name: 'numBaskets',
            title: 'Number of away baskets opponent can make (more than 3)',
          },
          {
            name: 'time',
            title: 'Time it takes to make 1 basket (in seconds)',
          },
        ]}
      />

      }


      {gameMode === 'shotClock' &&
        <EditTextCard
          type="text"
          {...commonProps}
          title="Enter a time if you would like a shot clock"
          onSave={(fields: any) => {
            console.log('shotClockTime', fields);
            onChangeHandler({
              basketballOptions: {
                ...game.options.basketballOptions,
                shotClockTime: parseInt(fields.shotClockTime),
              },
            });
          }}
          items={{
            shotClockTime: game.options.basketballOptions.shotClockTime || 60,
          }}
          formItems={[
            {
              name: 'shotClockTime',
              title:
                'time must be a minute or under. enter 0 if you want this feature disabled',
            },
          ]}
        />}

      {/* <EditOptionsCard
        title={'Show Score'}
        defaultValue={game.options.basketballOptions?.showScore || 'true'}
        onChange={(field: string) => {
          setShowScoreColor(field === 'true');
          onChangeHandler({
            basketballOptions: {
              ...game.options.basketballOptions,
              showScore: field,
            },
          });
        }}
        value1={'true'}
        value2={'false'}
      /> */}

      {/* {showScoreColor && (
        <EditColorCard
          title={'Score Color'}
          defaultValue={
            game.options.basketballOptions?.scoreColor || 'purple'
          }
          onChange={(field: string) => {
            onChangeHandler({
              basketballOptions: {
                ...game.options.basketballOptions,
                scoreColor: field,
              },
            });
          }}
        />

      )} */}
    </>
  );
};

export default GameDesignAssetsBasketball;
