import React, { FunctionComponent, useState, useRef, useContext } from 'react';
import { Table, Input, Button, Space, InputRef } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import CardActionItem from '../shared/CardItem/CardActionItem';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import RowDetail from './RowDetail';
import GamesContext from '../../context/games';

interface Props {
  clients?: any[];
  removeClient: (id: string) => void;
}

const ClientsTable: FunctionComponent<Props> = ({ clients, removeClient }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const searchInput = useRef<InputRef>(null);
  const navigate = useNavigate();
  const { getGames, games } = useContext(GamesContext);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            size="small"
            type="primary"
            icon={<SearchOutlined />}
            style={{ width: 90 }}
            onClick={() => {
              confirm({ closeDropdown: true });
              setSearchText(String(selectedKeys[0]));
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'id',
      width: '20%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '20%',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Created At',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      width: '20%',
      ...getColumnSearchProps('dateCreated'),
      sorter: (a, b) => {
        if (a && a.dateCreated && b && b.dateCreated) {
          return a.dateCreated - b.dateCreated;
        } else if (a && a.dateCreated) {
          return 1;
        } else if (b && b.dateCreated) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['descend', 'ascend'] as any[],
      defaultSortOrder: 'descend' as 'descend',
      render: (text) => {
        moment.defaultFormat = 'DD.MM.YYYY HH:mm';
        if (text) {
          return moment(text, 'YYYYMMDDhhmmss').format('DD.MM.YYYY HH:mm');
        }
        return text;
      },
    },
    {
      title: 'Amount of games',
      dataIndex: 'games',
      key: 'id',
      width: '10%',
      ...getColumnSearchProps('games'),
      render: (text) => text || 0,
      sorter: (a, b) => {
        if (a && a.games && b && b.games) {
          return a.games - b.games;
        } else if (a && a.games) {
          return 1;
        } else if (b && b.games) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['descend', 'ascend'] as any[],
    },
    {
      title: 'Actions',
      key: 'operation',
      width: '20%',
      render: (text, row) => (
        <div className="flex">
          <CardActionItem
            title="View Games"
            icon={EyeOutlined}
            onClickHandler={() => navigate(`/client/${row.id}`)}
          />
          <CardActionItem
            title="Delete"
            icon={DeleteOutlined}
            onConfirmHandler={() => removeClient(row.id)}
          />
        </div>
      ),
    },
  ];
  const onTableRowExpand = (expanded, record) => {
    const keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRowKeys(keys);
    getGames(record.id);
  };

  return (
    <div className="p-12">
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: () => {
            return <RowDetail games={games} />;
          },
          expandedRowKeys: expandedRowKeys,
          onExpand: onTableRowExpand,
          rowExpandable: (record) => record.games > 0,
        }}
        dataSource={clients}
        rowKey={({ id }) => id}
        rowClassName={(record) => (record.games > 0 ? 'bg-violet-light3' : '')}
      />
    </div>
  );
};

export default ClientsTable;
