import React, { FunctionComponent, memo, useRef, useState } from 'react';
import { Button, Input, Space, Table, InputRef } from 'antd';
import { ClientGame } from '@wiply/repository';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import moment from 'moment';
import RowDetail from './RowDetail';
import GamesContext from '../../context/games';
import { useContext } from 'react';

interface Props {
  games: ClientGame[];
  pagination?: boolean;
}

const GamesTable: FunctionComponent<Props> = memo(({ games, pagination }) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const { getGames, game } = useContext(GamesContext);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            size="small"
            type="primary"
            icon={<SearchOutlined />}
            style={{ width: 90 }}
            onClick={() => {
              confirm({ closeDropdown: true });
              setSearchText(String(selectedKeys[0]));
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => text,
  });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'id',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'id',
    },
    {
      title: 'Shared Url',
      dataIndex: ['options', 'labels', 'sharedUrl'],
      key: 'id',
      render: (text: string) => (text ? 'Yes' : '-'),
    },
    {
      title: 'Created At',
      dataIndex: 'dateGameCreated',
      key: 'dateGameCreated',
      width: '15%',
      ...getColumnSearchProps('dateGameCreated'),
      sorter: (a, b) => {
        if (a && a.dateGameCreated && b && b.dateGameCreated) {
          return a.dateGameCreated - b.dateGameCreated;
        } else if (a && a.dateGameCreated) {
          return 1;
        } else if (b && b.dateGameCreated) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['descend', 'ascend'] as any[],
      defaultSortOrder: 'descend' as 'descend',
      render: (text) => {
        moment.defaultFormat = 'DD.MM.YYYY HH:mm';
        if (text) {
          return moment(text, 'YYYYMMDDhhmmss').format('DD.MM.YYYY HH:mm');
        }
        return text;
      },
    },
    {
      title: 'Upload File',
      dataIndex: ['options', 'labels', 'uploadFile'],
      key: 'id',
      render: (text: string) => (text ? 'Yes' : '-'),
    },
    {
      title: 'Upload Coupon',
      dataIndex: ['options', 'labels', 'uploadCoupon'],
      key: 'id',
      render: (text: string) => (text ? 'Yes' : '-'),
    },
    {
      title: 'Update Subdomain',
      dataIndex: ['options', 'labels', 'updateSubdomain'],
      key: 'id',
      render: (text: string) => (text ? 'Yes' : '-'),
    },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  
  const onTableRowExpand = (expanded, record) => {
    const keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRowKeys(keys);
    getGames(record.id);
  };

  return (
    <Table
      columns={columns}
      dataSource={games}
      rowKey={({ id }) => id}
      pagination={
        pagination ? { defaultPageSize: 15, showSizeChanger: true } : false
      }
      onRow={(record) => {
        return {
          onClick: (event) => navigate(`/${record.id}`),
        };
      }}
      rowClassName="cursor-pointer"
    />
  );
});

export default GamesTable;
