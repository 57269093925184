import { ClientGame, GameCoupon } from '@wiply/repository';
import { BaseRepository } from './base.repository';
import {
  ballGallery,
  bricksContainers,
  defalutBricksPrizes,
  defaultBackgrounds,
  defaultLoosingCoupon,
  defaultWheelPrizes,
  defaultWinningCoupon,
  GameTypeEnum,
  slugify,
  ninjaScoreCoupon,
  getNinjaGalleryByType,
  defaultOptions,
} from '@wiply/utils';
const getDefaultCoupons = (size: number, gameImgUrl?: string | string[]) =>
  new Array(size).fill({}).map(
    (c, i) =>
      ({
        couponId: i.toString(),
        code: i.toString(),
        imgUrl: defaultWinningCoupon,
        emailSettings: {},
        ...(gameImgUrl
          ? {
              gameImgUrl:
                typeof gameImgUrl === 'string' ? gameImgUrl : gameImgUrl[i],
              background: '#ffffff',
              button: {
                backgroundColor: '#000000',
                color: '#ffffff',
                maxLength: [20],
                text: ['SHOP NOW'],
              },
              discount: {
                backgroundColor: '#5929c7',
                color: '#ffffff',
                maxLength: [10, 12],
                text: ['5%', 'OFF'],
              },
              logo: null,
              note: {
                color: '#b3b3b3',
                maxLength: [50],
                text: ['Free Delivery'],
              },
              showCode: true,
              template: 0,
              title: {
                color: '#000000',
                maxLength: [20, 13],
                text: ['CONGRATULATIONS', 'YOU WON'],
              },
            }
          : {}),
      } as GameCoupon)
  );

export class DefaultClientGame implements ClientGame {
  coupons;
  lostCoupon;
  options;
  metatags;
  background;

  constructor(
    public clientId: string,
    public name: string,
    public type: GameTypeEnum,
    public id?: string,
    public dateGameCreated?: string,
  ) {
    const defaultGameImg =
      'https://res.cloudinary.com/drqznwkn6/image/upload/v1612099466/defaults/coupon-4_2x_s8vcoh.png';
    this.lostCoupon = {
      couponId: '0',
      imgUrl: defaultLoosingCoupon,
    };
    switch (type) {
      case GameTypeEnum.Bricks:
        this.coupons = getDefaultCoupons(4, defalutBricksPrizes[0]);
        this.lostCoupon = null;
        break;

      case GameTypeEnum.DropIt:
        this.coupons = getDefaultCoupons(3, defaultGameImg);
        this.lostCoupon = null;
        break;

      case GameTypeEnum.Wheel:
        this.coupons = getDefaultCoupons(6, defaultWheelPrizes);
        this.lostCoupon = null;
        break;

      case GameTypeEnum.Quiz:
        this.coupons = getDefaultCoupons(1);
        break;

      case GameTypeEnum.Ninja:
        this.coupons = getDefaultCoupons(4, ninjaScoreCoupon);
        this.lostCoupon = null;

        break;

      case GameTypeEnum.Tower:
        this.coupons = getDefaultCoupons(2, ninjaScoreCoupon);
        this.lostCoupon = null;

        break;

      default:
        this.coupons = getDefaultCoupons(1);
    }
    this.background = {
      bgImgUrl: defaultBackgrounds[0],
      mobileBgImgUrl: defaultBackgrounds[0],
    };
    this.options = defaultOptions;
    this.metatags = {
      title: 'Game',
      description: '',
      ogImage: '',
      ogUrl: '',
      ogDescription: '',
    };
    if (type === GameTypeEnum.Hockey) {
      this.options.bgImgUrl = null;
    }
  }
}

export class GameRepository extends BaseRepository<ClientGame> {
  constructor() {
    super('games');
  }

  async createDefaultGame(
    name: string,
    clientId: string,
    type = GameTypeEnum.Bricks,
    dateGameCreated: string,
  ) {
    try {
      const id = `${slugify(name.slice(0, 5))}-${this.getRandomString()}`;
      const entity = {
        name,
        clientId,
        type,
        dateGameCreated,
      };
      const newGame = this.create(
        id,
        JSON.parse(JSON.stringify(new DefaultClientGame(clientId, name, type, id, dateGameCreated)))
      );
      return newGame;
    } catch (e) {
      console.log(e);
    }
  }
}
