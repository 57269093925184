import React, { FunctionComponent } from 'react';

interface PropsCardAction {
  content: React.ReactNode;
  onClickHandler?: () => void;
}

const CardContent: FunctionComponent<PropsCardAction> = ({
  onClickHandler,
  content,
}) => (
  <div
    className={`${
      onClickHandler ? 'cursor-pointer' : ''
    } w-full bg-white flex flex-col justify-center py-4`}
    onClick={onClickHandler}
  >
    {content}
  </div>
);

export default CardContent;
