import React, { FunctionComponent } from 'react';
import CardAction from './CardAction';
import CardContent from './CardContent';

interface Props {
  actions: React.ReactNode;
  children?: React.ReactNode;
  onClickHandler?: () => void;
  size?: string;
  className?: string;
  bottomContent?: React.ReactNode;
  actionsStyle?: string;
  column?: boolean;
}

export const CardItem: FunctionComponent<Props> = React.memo(
  ({
    actions,
    children,
    onClickHandler,
    size,
    className,
    bottomContent,
    actionsStyle,
    column,
  }) => {
    return (
      <article
        className={`shadow-lg rounded-2xl overflow-hidden self-center ${
          size !== 'auto' ? '' : ''
        } ${className}`}
      >
        <div className={`flex ${column ? 'flex-col' : ''}`}>
          <CardContent content={children} onClickHandler={onClickHandler} />
          <CardAction list={actions} styles={actionsStyle} />
        </div>
        {bottomContent}
      </article>
    );
  }
);

export default CardItem;
