import { CouponSettings, GameCoupon } from '@wiply/repository';
import React, { FunctionComponent, useEffect, useRef, useState, useContext } from 'react';
import CouponBody from './CouponBody';
import SubdomainContext, { ErrorInterface } from '../../../context/subdomain';
import { environment } from '@env/environment';



interface Props {
  config: GameCoupon;
  getSVG: (svg: SVGSVGElement) => void;
  couponCode?: string;
  loosingCoupon?: boolean;
}

export const SVGCoupon: FunctionComponent<Props> = ({
  config,
  getSVG,
  couponCode,
  loosingCoupon
}) => {

  const [name,setName] = useState('');
  const [email,setEmail] = useState('');

  const {
    template,
    title,
    discount,
    button,
    note,
    background,
    showCode,
    logo,
    emailSvg,
    emailCoupon,
    fontSizeDiscount1,
    fontSizeDiscount2,
    fontSizeDiscount3,
    input2,
    input3,
    emailSettings
    

  } = config;
  const { updateSubdomain, subdomain } = useContext(SubdomainContext);
  const url = `https://${subdomain}.${environment.appsUrls.clientNext
    }`;
  const svg = useRef<SVGSVGElement>();
  useEffect(() => {
    getSVG(svg.current);
  }, [config]);



  const css = `.c-st0{fill:#FFFFFF;}
        .c-st1{fill:#231F20;}
        .c-st3{font-family:'Rubik';font-weight: 800}
        .c-st4{font-size:167.18px;}
        .c-st4-b{font-size:106.18px;}
        .c-st4-c{font-size:68.18px;}
        .c-st4-c-2{font-size:90.18px;}
        .c-st4b{font-size: 125px;}
        .c-st5{font-size:40px;text-align:center;}
        .c-st6{font-family:'Rubik';}
        .c-st7{font-size:53.8492px;}
        .c-st8{fill:#727272;}
        .c-st9{font-family:'Rubik'; font-weight:300}
        .c-st10{font-size:33.9641px;}
        .c-st11{font-size:50.73px;}
        .c-st12{font-size:77.1139px;}
        .c-st13{font-size:119.0128px;}
        .c-st14{font-size:30px; margin:auto; width:80%; text-align: center;}
        .c-st15{background-size:cover; background-position:center; background-repeat: none} 
        .c-st16{padding-top: 10px; padding-bottom: 10px;border-radius: 12px;margin-top:10px;margin-bottom:10px;font-size:40px; width:71%;background: ${button.backgroundColor};max-height: 60px; color: ${button.color}; text-align: center; height: 30%}
        #input-text::placeholder{color:${button.color}}`;
  return (
    <svg
      id={loosingCoupon ? 'lost-coupon' : 'winning-coupon'}
      viewBox="0 0 1080 1920"
      xmlns="http://www.w3.org/2000/svg"
      ref={svg}
      className="max-h-96 m-auto rounded-2xl shadow-xl c-st15"
      style={{ backgroundColor: background, backgroundImage: config?.emailSettings?.backgroundPhoto ? `url(${config?.emailSettings?.backgroundPhoto})` : "" }}
    >
      <defs>
        <style type="text/css">
          @import
          url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700;800');
        </style>
      </defs>
      <style type="text/css">{css}</style>
      {logo && (
        <image
          width="600"
          height="200"
          transform="translate(120 100) scale(1.42)"
          xlinkHref={logo}
          xmlnsXlink="http://www.w3.org/1999/xlink"
        />
      )}
      <path
        className="c-st1"
        d={`M295.67,${logo ? '400' : '151'
          }.36q260.82-10.61,521.91,0-260.82,10.68-521.91,0Z`}
      />
      <path
        d={`M295.67,${logo ? '716' : '506'
          }.63q260.82-10.61,521.91,0-260.82,10.7-521.91,0Z`}
        className="c-st1"
      />
      <CouponBody
        logo={logo}
        backgroundColor={discount.backgroundColor}
        template={template}
      />
      <text
        y={logo ? '1030' : '922.1914'}
        x="50%"
        textAnchor="middle"
        className={`c-st0 c-st3 ${fontSizeDiscount1?.length > 0 ? "c-st4-b" : "c-st4"}`}
        style={{ fill: discount.color }}
      >
        {discount.text[0]}
      </text>
      <text
        id='textDiscount1'
        y={logo ? '1180' : '1100'}
        x="50%"
        textAnchor="middle"
        className={`c-st0 c-st3 ${fontSizeDiscount2?.length > 0 ? "c-st4-c" : "c-st4b"}`}
        style={{ fill: discount.color }}
      >
        {discount.text[1]}
      </text>
      {fontSizeDiscount3?.length > 0 && (<text
        y={logo ? '1275' : '1100'}
        x="50%"
        textAnchor="middle"
        className={`c-st0 c-st3 ${fontSizeDiscount3?.length > 0 ? "c-st4-c-2" : "c-st4b"}`}
        style={{ fill: discount.color }}
      >
        {discount.text[2]}
      </text>)}
      {showCode && couponCode && !emailCoupon && (
        <foreignObject
          y={logo ? '1335' : '1290'}
          width="100%"
          height="55px"
          className="c-st5"
          style={{ color: note.color }}
        ><span>
            Coupon Code: <strong>{couponCode}</strong>
          </span>

        </foreignObject>
      )}
      {emailCoupon && (
        <foreignObject y={logo ? '1215' : '1170'} width="100%" height="300px">
          <h1 className="c-st14" style={{ color: "black", fontSize: "4em", width: "72%", backgroundColor: "white", border: "2px solid black", borderRadius: "25px" }}>
            NAME
          </h1>
        </foreignObject>
      )}
      {emailCoupon && (
        <foreignObject y={logo ? '1325' : '1280'} width="100%" height="300px">
          <h1 className="c-st14" style={{ color: "black", fontSize: "4em", width: "72%", backgroundColor: "white", border: "2px solid black", borderRadius: "25px" }}>
            Email Address
          </h1>
        </foreignObject>
      )}
      {emailSvg && (<>
  <foreignObject x="0" y="1280" width="100%" height="30%" style={{textAlign:'center'}}>
    <input  type="text"  onChange={(e)=>setName(e.target.value)} id="input-text" placeholder={emailSettings?.placeHolder1 || 'Full Name'} className='c-st16' />
  {input2 && ( <input  type="email" onChange={(e)=>setEmail(e.target.value)} id="input-email" placeholder={emailSettings?.placeHolder2 || 'Email'} className='c-st16' />)}
  </foreignObject>
<g id="claim-button" style={{ fill: button.backgroundColor }}>
        <g id="b0cf9f25-d2ad-46e0-9f2b-9b18c36553fd">
          <g id="e9ba3627-7591-4805-b4dd-c64d4781d3f0">
            <g>
              <path
                d={`M926.7,${input3 ? '1740' : input2 ? '1616' : '1515'}.8c0,6.6-7,11.9-15.7,11.9H169c-8.7,0-15.7-5.3-15.7-11.9v-99.7c0-6.6,7-11.9,15.7-11.9h742
					c8.7,0,15.7,5.3,15.7,11.9L926.7,1496.8z`}
              />
            </g>
          </g>
        </g>
        <text
          id={loosingCoupon ? 'lost-button' : 'winning-button'}
          y={input3 ? "1667" : input2 ? "1585" : "1480"}
          className="c-st0 c-st3 c-st11"
          x="50%"
          textAnchor="middle"
          style={{ fill: button.color }}
        >
          {button.text[0]}
        </text>
      </g>
      </>
  )}
  {!emailSvg && (
      <g id="claim-button" style={{ fill: button.backgroundColor }}>
        <g id="b0cf9f25-d2ad-46e0-9f2b-9b18c36553fd">
          <g id="e9ba3627-7591-4805-b4dd-c64d4781d3f0">
            <g>
              <path
                d="M926.7,1556.8c0,6.6-7,11.9-15.7,11.9H169c-8.7,0-15.7-5.3-15.7-11.9v-99.7c0-6.6,7-11.9,15.7-11.9h742
					c8.7,0,15.7,5.3,15.7,11.9L926.7,1496.8z"
              />
            </g>
          </g>
        </g>
        <text
          id={loosingCoupon ? 'lost-button' : 'winning-button'}
          y="1527"
          className="c-st0 c-st3 c-st11"
          x="50%"
          textAnchor="middle"
          style={{ fill: button.color }}
        >
          {button.text[0]}
        </text>
      </g>)}
      {note.text[0] && !emailCoupon && (
        <foreignObject y="1660" width="100%" height="300px">
          <p className="c-st14" style={{ color: note.color }}>
            {note.text[0]}
          </p>
        </foreignObject>
      )}
      {note.text[0].length < 3 && config?.isSocial && !emailCoupon && (
        <foreignObject y="1660" width="100%" height="300px">
          <div className="social" style={{ width: "100%", placeContent: "center", display: "flex", flexDirection: "column" }}>
            <h3 style={{fontSize: "30px", alignSelf: "center", color: note?.color || "black"  }}>Share Now:</h3>
            <div className="social" style={{ width: "100%", placeContent: "center", display: "flex", flexDirection: "row" }}>

              <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`} className="buttonwa"><img src="https://res.cloudinary.com/shulgirit/image/upload/v1653473928/wiply/Platform%20Default%20Images/_Global/Coupons/whatsapp-icon-logo-vector_zwsq0f.png" style={{ width: "100px", height: "100px", paddingLeft: "10px", paddingRight: "10px" }}></img></a>
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`} className="buttonfa"><img src="https://res.cloudinary.com/shulgirit/image/upload/v1653473914/wiply/Platform%20Default%20Images/_Global/Coupons/640px-Facebook_icon.svg_zivbj1.png" style={{ width: "100px", height: "100px", paddingLeft: "10px", paddingRight: "10px" }}></img></a></div></div>
        </foreignObject>
      )}

     <text
        y={logo ? '530' : '275.563'}
        x="50%"
        textAnchor="middle"
        className="c-st9 c-st12"
        style={{ fill: title.color }}
      >
        {title.text[0]}
      </text>
      <text>
        <tspan
          y={logo ? '655' : '424.9715'}
          x="50%"
          textAnchor="middle"
          className="c-st3 c-st13"
          style={{ fill: title.color }}
        >
          {title.text[1]}
        </tspan>
      </text>
    </svg>
  );
};

export default SVGCoupon;
