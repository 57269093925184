import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  UploadOutlined,
  DeleteOutlined,
  SwitcherOutlined,
  SyncOutlined,
  SelectOutlined
} from '@ant-design/icons';
import { Radio, Upload, message, Tooltip } from 'antd';
import CardItem from '../../shared/CardItem/index';
import CardActionItem from '../../shared/CardItem/CardActionItem/index';
import { Modal } from '../../shared/Modal';
import ModelViewer from '../../shared/ModelViewer';
import EditImageCardDescription from './EditImageCardDescription';
import ImgCrop from 'antd-img-crop';
import Button from '../../shared/Button';
import UploadFileForm from '../UploadFileForm';

const loadImage = (src: string, aspectRatio: number) =>
  new Promise((resolve) => {
    const img = new Image();
    img.src = src;
    img.onload = function (this: HTMLImageElement) {
      resolve(
        Number(this.width / this.height).toFixed(1) === aspectRatio.toFixed(1)
      );
    };
  });

const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : children;

const validImageDimensions = async (src: string, aspectRatio: number) =>
  await loadImage(src, aspectRatio);

export interface DescriptionField {
  text?: string | React.ReactNode;
  size?: string;
  type?: string;
  isOptional?: boolean;
  aspectRatio?: number;
}

export interface FormTitles {
  title1?: string;
  title2?: string;
  title3?: string;
  title4?: string;
  title5?: string;
}

interface EditFieldProps {
  title?: string;
  formTitles?: FormTitles;
  description?: DescriptionField;
  onChange: (
    field: string | File | any[],
    field2: string | File | any[]
  ) => void;
  imagePath: string;
  uploadImage?: (file: File, path: string) => Promise<string>;
  defaultValue?: string;
  defaultValue2?: string;
  accept?: string;
  multiple?: boolean;
  clean?: boolean;
  size?: string;
  defaultImagesToChoose?: any[];
  extraActions?: React.ReactNode;
  previewCss?: string;
  disableUpload?: boolean;
  galleryPreview?: string;
  removeDelete?: boolean;
  fileName?: string;
  containerCSS?: string;
  setUploadedImage?: any;
  uploadImagesModal?: boolean;
}

const EditImageCardMemory: FunctionComponent<EditFieldProps> = ({
  title,
  formTitles,
  description,
  onChange,
  imagePath,
  uploadImage,
  defaultValue,
  defaultValue2,
  accept,
  multiple,
  size,
  defaultImagesToChoose,
  extraActions,
  previewCss,
  disableUpload,
  galleryPreview,
  removeDelete,
  containerCSS,
  setUploadedImage,
  uploadImagesModal,
  fileName,
}) => {
  const [modalVisible, setIsModalVisible] = useState(false);
  const [modalNinjaVisible, setIsModalNinjaVisible] = useState(false);
  const [defaultImageSelected, setDefaultImageSelected] = useState(
    defaultValue
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showEditImage1, setShowEditImage1] = useState(false);
  const [showEditImage2, setShowEditImage2] = useState(false);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [srcFile, setSrcFile1] = useState(null);
  const [srcFile2, setSrcFile2] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [feedbackApi, feedbackContext] = message.useMessage();

  const imagePreview1 = useMemo(() => {
    if (isLoading)
      return <SyncOutlined spin className={`my-6 text-5xl text-violet`} />;
    return (
      <img
        className={`my-4 m-auto max-h-${multiple ? '12' : '24'} ${previewCss}`}
        src={srcFile || defaultValue || '../../../assets/images/default.png'}
      />
    );
  }, [defaultValue, isLoading, srcFile]);

  const imagePreview2 = useMemo(() => {
    if (isLoading)
      return <SyncOutlined spin className={`my-6 text-5xl text-violet`} />;
    return (
      <img
        className={`my-4 m-auto max-h-${multiple ? '12' : '24'} ${previewCss}`}
        src={srcFile2 || defaultValue2 || '../../../assets/images/default.png'}
      />
    );
  }, [defaultValue, isLoading, srcFile2]);

  // useEffect(() => {
  //   if (defaultImageSelected !== defaultValue) onChange(defaultImageSelected);
  // }, [defaultImageSelected]);

  const info = (message) => {
    messageApi.info(`Please select ${message} to upload`);
  };

  const ModalComponent = () => {
    return (
      <>
        {contextHolder}
        {!disableUpload && !uploadImagesModal && (
          <ConditionalWrap
            condition={multiple}
            wrap={(children) => (
              <Tooltip placement="right" title="Upload">
                {children}
              </Tooltip>
            )}
          >
            <CardActionItem
              title={'Select image 1'}
              icon={SelectOutlined}
              color="white"
              onClickHandler={() => {
                setShowEditImage1(true);
              }}
            />
            <CardActionItem
              title={'Select image 2'}
              icon={SelectOutlined}
              color="white"
              onClickHandler={() => {
                setShowEditImage2(true);
              }}
            />
            <CardActionItem
              title={'Upload'}
              icon={UploadOutlined}
              color="white"
              onClickHandler={() => {
                if (!file1) {
                  info('image 1');
                  return;
                }
                if (!file2) {
                  info('image 2');
                  return;
                }
                uploadImageHandler({ file1, file2 });
              }}
            />
            {showEditImage1 ? (
              <ModalWrapper
                setFile={setFile1}
                showEditImage={showEditImage1}
                setShowEditImage={setShowEditImage1}
                message={'Image 1'}
              />
            ) : showEditImage2 ? (
              <ModalWrapper
                setFile={setFile2}
                showEditImage={showEditImage2}
                setShowEditImage={setShowEditImage2}
                message={'Image 2'}
              />
            ) : null}
          </ConditionalWrap>
        )}
      </>
    );
  };

  const ModalWrapper = ({
    setFile,
    showEditImage,
    setShowEditImage,
    message,
  }) => {
    const feedback = (message) => {
      feedbackApi.success(`${message} successfully selected`);
    };

    return (
      <Modal
        title={description?.type != 'mp3' ? 'Upload Image' : 'Upload Music'}
        isModalVisible={showEditImage}
        handleCancel={() => setShowEditImage(false)}
      >
        <div className="grid md:grid-cols-2 items-center justify-items-center">
          {description?.type != 'mp3' && (
            <ImgCrop
              rotationSlider
              showGrid
              aspect={description?.aspectRatio || 1}
              minZoom={0}
              cropperProps={{
                objectFit: 'horizontal-cover',
                initialCroppedAreaPixels: { x: 0, y: 0, width: 100, height: 100},
                restrictPosition: false,
                style: undefined,
                zoomSpeed: undefined,
                mediaProps: undefined
              }}
              fillColor="transparent"
            >
              <Upload
                showUploadList={false}
                customRequest={(file) => {
                  setFile(file);
                  setShowEditImage(false);
                  feedback(message);
                }}
                accept={accept}
              >
                <Button
                  title="Upload and Edit"
                  icon={<UploadOutlined />}
                  className="h-16 px-12"
                  style="inverted"
                />
              </Upload>
            </ImgCrop>
          )}
          <Upload
            showUploadList={false}
            customRequest={(file) => {
              setFile(file);
              setShowEditImage(false);
              feedback(message);
            }}
            accept={accept}
          >
            <div className="py-2 flex items-center justify-center lg:justify-start">
              <Button
                title="Upload"
                icon={<UploadOutlined />}
                className="h-16 px-12"
              />
            </div>
          </Upload>
        </div>
      </Modal>
    );
  };

  const uploadImageHandler = async ({ file1, file2 }) => {
    setIsLoading(true);
    console.log(file1, file2);
    const uploadedFile: string = await uploadImage(file1.file, imagePath);
    const uploadedFile2: string = await uploadImage(file2.file, imagePath);
    console.log(uploadedFile, uploadedFile2);
    if (uploadedFile && uploadedFile2) {
      // if (description?.aspectRatio) {
      //   const isValid = await validImageDimensions(
      //     uploadedFile,
      //     description.aspectRatio
      //   );
      //   if (!isValid)
      //     message.warning(
      //       'Warning: Image aspect ratio is not the recommended.'
      //     );
      // }
      // setUploadedImage(true);
      onChange(uploadedFile, uploadedFile2);
      setSrcFile1(uploadedFile);
      setSrcFile2(uploadedFile2);
    }
    setIsLoading(false);
  };

  return (
    <>
      {file1 && feedbackContext}
      {file2 && feedbackContext}
      <CardItem
        actions={
          <>
            <ModalComponent />
            {!removeDelete && (
              <ConditionalWrap
                condition={multiple}
                wrap={(children) => (
                  <Tooltip placement="right" title="Delete">
                    {children}
                  </Tooltip>
                )}
              >
                <div>
                  <CardActionItem
                    title={!multiple ? 'Delete' : null}
                    icon={DeleteOutlined}
                    onClickHandler={() => onChange(null, null)}
                    color="white"
                  />
                </div>
              </ConditionalWrap>
            )}
            {extraActions}
          </>
        }
        size={size}
        className={containerCSS}
        actionsStyle="gradient"
      >
        <EditImageCardDescription title={title} imagePreview={imagePreview1} />
        <EditImageCardDescription
          imagePreview={imagePreview2}
          description={description}
        />
      </CardItem>
    </>
  );
};

export default EditImageCardMemory;
