import { Upload } from 'antd';
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Button from '../shared/Button';
import {
  UploadOutlined,
  DeleteOutlined,
  SwitcherOutlined,
} from '@ant-design/icons';
import { ClientGame } from '@wiply/repository';
import CardActionItem from '../shared/CardItem/CardActionItem';
interface Props {
  onChange: (field: string | File | any[], isFileVideo?: boolean) => void;
  filePath?: string;
  uploadFile?: (file: File, path: string) => Promise<string>;
  defaultValue?: string;
  title?: string
}
const UploadVideo: FunctionComponent<Props> = ({
  uploadFile,
  filePath,
  onChange,
  defaultValue,
  title
}) => {
  const [selectedFile, setSelectedFile] = useState(defaultValue);
  const [modalVisible, setIsModalVisible] = useState(false);
  const [modalNinjaVisible, setIsModalNinjaVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showEditImage, setShowEditImage] = useState(false);

  const uploadImageHandler = async ({ file }) => {
    let isFileVideo = false;
    if (file.type.match('video')) {
      isFileVideo = true;
    }
    setIsLoading(true);
    setShowEditImage(false);
    const uploadedFile: string = await uploadFile(file, filePath);
    onChange(uploadedFile, isFileVideo);
    setSelectedFile(uploadedFile);
    setIsLoading(false);
  };
  return !selectedFile ? (
    <div>
      <div>
        <Upload
          showUploadList={false}
          customRequest={(file) => uploadImageHandler(file)}
        >
          <Button
             title={title}
            icon={<UploadOutlined />}
            className="h-16 px-12"
            style="inverted"
          />
        </Upload>
      </div>
      {/* {this.fileData()} */}
    </div>
  ) : (
    <>
      <div>
        <video
          style={{ width: '250px', height: '250px' }}
          src={selectedFile}
          loop
          autoPlay
          muted
          playsInline
        />
      </div>
      <div>
        <Upload
          showUploadList={false}
          customRequest={(file) => uploadImageHandler(file)}
        >
          <Button
            title={title}
            icon={<UploadOutlined />}
            className="h-16 px-12"
            style="inverted"
          />
        </Upload>
        <Button  title={ 'Delete Video'}
                    icon={<DeleteOutlined/>}
    
                   onClick={() => {
                    onChange(null, true)
                    setSelectedFile(null)
                  }}
                    className="h-16 px-12"
            style="inverted"/>
                
 
      </div>
    </>
  );
};
export default UploadVideo;
