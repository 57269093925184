import React, { useContext, FunctionComponent, useEffect } from 'react';
import { Empty } from 'antd';
import ClientsContext from '../context/clients';
import { Link, useParams } from 'react-router-dom';
import Spinner from '../components/shared/Spinner';
import { SubdomainContextProvider } from '../context/subdomain';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import { environment } from '@env/environment';
import { useNavigate } from 'react-router-dom';
import GameList from '../components/games/GameList';

const Client: FunctionComponent = () => {
  const { validateClient, client } = useContext(ClientsContext);
  const currentUser = useSelector((state: RootState) => state.userReducer);
  const navigate = useNavigate();
  let clientId: string;
  if (currentUser?.isAdmin) {
    const { id } = useParams<{ id: string }>();
    clientId = id || environment.adminUID;
  } else {
    clientId = currentUser?.user?.id;
  }
  useEffect(() => {
    validateClient(clientId);
  }, []);

  if (client == null) return <Spinner />;
  if (!client)
    return (
      <div className="flex justify-center items-center flex-col my-12">
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{
            height: 60,
          }}
          description={<span>Client Not Found</span>}
        >
          <Link to="/" className="text-violet">
            Back to Clients
          </Link>
        </Empty>
      </div>
    );

  if (currentUser.user.email === undefined) {
    console.log('test');
  }

  return (
    <div className="my-8">
      <div className="md:flex justify-between mx-2 items-center mb-4"></div>
      <SubdomainContextProvider gameId={null}>
        <GameList clientId={clientId} />
      </SubdomainContextProvider>
    </div>
  );
};
export default Client;
