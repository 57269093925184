import React, { FunctionComponent, memo } from 'react';

interface Props {
  icon?: any;
  onClickHandler: any;
  color?: string;
}

export const ShareButtonItem: FunctionComponent<Props> = memo(
  ({ icon, onClickHandler, color }) => {
    const Icon = icon;
    return (
      <li onClick={onClickHandler} className="cursor-pointer">
        <Icon
          style={{ background: color }}
          className="text-md text-white transition ease-in duration-700  flex items-center justify-center"
        />
      </li>
    );
  }
);

export default ShareButtonItem;
