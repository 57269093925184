import React, { FunctionComponent, useState } from 'react';
import EditColorCard from './../GameDesignCard/EditColorCard';
import { message } from 'antd';
import CardActionItem from '../shared/CardItem/CardActionItem';
import { BgColorsOutlined } from '@ant-design/icons';
import Modal from '../shared/Modal';

const success = () => {
  message.success({
    content: 'Saved!',
  });
};

interface Props {
  defaultValue?: string;
  onChangeHandler: (field) => any;
  title: string;
  hasAlpha?: boolean;
}

export const EditColorAction: FunctionComponent<Props> = ({
  defaultValue,
  onChangeHandler,
  title,
  hasAlpha,
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <CardActionItem
        title={title}
        icon={BgColorsOutlined}
        onClickHandler={() => setShowModal(true)}
        color="white"
      />
      <Modal
        title={<span className="block font-medium">{title}</span>}
        handleCancel={() => setShowModal(false)}
        isModalVisible={showModal}
      >
        {/* Background Color */}
        <EditColorCard
          hasAlpha={hasAlpha}
          defaultValue={defaultValue}
          onChange={(field) => {
            onChangeHandler(field);
            setShowModal(false);
          }}
        />
      </Modal>
    </>
  );
};

export default EditColorAction;
