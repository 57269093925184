import React, { FunctionComponent } from 'react';
import CardActionItem from '../../components/shared/CardItem/CardActionItem/index';
import {
  EyeFilled,
  FormatPainterFilled,
  DashboardFilled,
  DeleteFilled,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';

interface Props {
  removeGame: (id: string) => VoidFunction;
  navigate: any;
  id: string;
  previewLink: string;
}

const GameActions: FunctionComponent<Props> = ({
  removeGame,
  navigate,
  id,
  previewLink,
}) => {
  const currentUser = useSelector((state: RootState) => state.userReducer);
  return (
    <>
      <CardActionItem
        title="Edit"
        icon={FormatPainterFilled}
        onClickHandler={() => navigate(`/${id}`)}
      />
      <CardActionItem title="View" icon={EyeFilled} linkTo={previewLink} />
      <CardActionItem
        title="Delete"
        icon={DeleteFilled}
        onConfirmHandler={() => removeGame(id)}
      />
      {currentUser?.isAdmin ? (
        <CardActionItem
          title="Dashboard"
          icon={DashboardFilled}
          onClickHandler={() => navigate(`/${id}/dashboard`)}
        />
      ) : (
        <Tooltip
          trigger={'hover'}
          title="Please contact yoel@Wiply.com for more information about this feature"
        >
          <span>
            <div style={{ pointerEvents: 'none', opacity: '0.4' }}>
              <CardActionItem
                title="Dashboard"
                icon={DashboardFilled}
                onClickHandler={() => navigate(`/${id}/dashboard`)}
              />
            </div>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default GameActions;
