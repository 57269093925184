import './CreateQuestions.css';
import Form from "@rjsf/core";
import { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import axios from "axios";
import validator from '@rjsf/validator-ajv8';

const schema =
{
  "title": "Questions",
  "type": "array",
  "items": [{
    "type": "object",
    "properties": {
      "question": { "type": "string" },
      "correct_answer": { "type": "string" },
      "incorrect_answers": {
        "type": "array",
        "items": [
            {"type": "string", "default": ""},
            {"type": "string", "default": ""},
            {"type": "string", "default": ""}
        ]
      }
    }
  }
  ],
  "additionalItems": {
    "type": "object",
    "properties": {
      "question": { "type": "string" },
      "correct_answer": { "type": "string" },
      "incorrect_answers": {
        "type": "array",
        "items": [
            {"type": "string", "default": ""},
            {"type": "string", "default": ""},
            {"type": "string", "default": ""}
        ]
      }
    }
  }
};


const schemaImage =
{
  "title": "Questions",
  "type": "array",
  "items": [{
    "type": "object",
    "properties": {
      "question": { "type": "string" },
      "image": {"type" :"string"},
      "correct_answer": { "type": "string" },
      "incorrect_answers": {
        "type": "array",
        "items": [
            {"type": "string", "default": ""},
            {"type": "string", "default": ""},
            {"type": "string", "default": ""}
        ]
      }
    }
  }
  ],
  "additionalItems": {
    "type": "object",
    "properties": {
      "question": { "type": "string" },
      "image": {"type" :"string"},
      "correct_answer": { "type": "string" },
      "incorrect_answers": {
        "type": "array",
        "items": [
            {"type": "string", "default": ""},
            {"type": "string", "default": ""},
            {"type": "string", "default": ""}
        ]
      }
    }
  }
};

function CreateQuestions({ onChangeHandler, quizOptions, instance }) {
  let data;
  const [formData, setFormData] = useState(instance || [{
    "image" : "",
    "question": "",
    "correct_answer": "",
    "incorrect_answers": ["", "", ""]
  }]);
  const [uploadedFile, setUploadedFile] = useState(false);
  // const [fData, setFData] = useState("");
  // let config = {
  //   method: 'get',
  //   url: 'https://res.cloudinary.com/dlwk8lsjb/raw/upload/v1660482414/questions_1_lfdu4l.json',
  //   headers: {}
  // };
  //let fData = [];

  // async function initialData() {
  //   let t = await axios(config);
  //   console.log(t);
  //   setFData(t.data);
  // }
  // initialData();
  //TODO add use effect update setData each time the text is changing

  // sends data to upload handler from type='submit' on button
  const onSubmit = ({ formData }, e) => {
    uploadHandler(formData);
  }

  const onChange = ({ formData }, e) => {
    formData.forEach(question => {
        // If incorrect_answers array contains non-strings, initialize them as empty strings
        if (question.incorrect_answers) {
          question.incorrect_answers = question.incorrect_answers.map(answer => 
            typeof answer === 'string' ? answer : ''
          );
        }
      });
      
    // Set the modified form data back
    setFormData(formData);
    data = formData;
  }
  
  const readFile = (event) => {
    setFormData(JSON.parse(event.target.result));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const jsonFile = document.querySelector(".json")
    let read = new FileReader();
    read.onload = readFile;
    read.readAsText(jsonFile.files[0])
  }

  // uploads to firebase
  const uploadHandler = ( formData ) => {
    onChangeHandler({
      quizOptions: {
        ...quizOptions,
        ['easy_questions']: formData,
      }
    });
  };

  const [schemaData,setSchemaData] = useState(schema)
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const imageurl = urlParams.get('image')
    if(imageurl != null){
      setSchemaData(schemaImage)
    }else{
      setSchemaData(schema)
    }

  },[])
  return (
    <>
    {/* <div className='header'>
      
        <h1 className="title" >
          Wiply Questions Creator
        </h1>
    </div> */}
    <div className="whole" >
      <div className="JSON" >
        {/* Form to create and add your own questions */}
        <Form schema={schemaData} onSubmit={onSubmit} onChange={onChange} formData={formData} validator={validator} liveValidate >
            <button className="download" type='submit' >
            Upload
            </button>
        </Form>
        {/* Form to upload and edit your own JSON */}
        <form className="JSONload" onSubmit={handleSubmit} >
          <label htmlFor="file"></label>
          <div className="addjson">
          <input type="file" className="json" accept=".json" onChange={() => setUploadedFile(true)} />
          {!uploadedFile ?  <div className="filetext">Add JSON file</div> :
          <div className="filetext">File successfully added, don't forget to submit!</div>}
           
          </div>
          <input type="submit" name="Submit" className='submit'/>
        </form>
      </div>
    </div >
  </>
  );
}
export default CreateQuestions;
