import React, { FunctionComponent, useContext, useState } from 'react';
import { GameCoupon } from '@wiply/repository';
import SettingsForm from './Forms/SettingsForm';
import { CustomCoupon } from './CustomCoupon';
import RadioButtonGroup from '../shared/RadioButtonGroup';
import GamesContext from '../../context/games';
import { Divider } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import UploadCoupon from './UploadCoupon';

interface Props {
  coupon: GameCoupon;
  updateCoupon: (fields: GameCoupon) => void;
  updateImage: ({ file }) => Promise<string>;
  defaultImagesToChoose?: string[];
  loosingCoupon?: boolean;
}

const cacheValues = [
  { value: 'single', label: 'Single Attemp' },
  { value: 'multiple', label: 'Unlimited Attemp' },
];

export const Coupon: FunctionComponent<Props> = ({
  coupon,
  updateCoupon,
  updateImage,
  loosingCoupon,
}) => {
  const { game, updateGame } = useContext(GamesContext);
  const [loading, setLoading] = useState(false);
  const updateImageHandler = async (file, settings?) => {
    setLoading(true);
    const img = await updateImage(file);
    if (img) {
      updateCoupon({ ...coupon, ...(settings && settings), imgUrl: img });
    }
    setLoading(false);
  };
  return (
    <>
      <div className="flex gap-12 justify-center">
        <UploadCoupon updateImage={updateImageHandler} />
        <div className="h-32 flex justify-center items-center">
          {loading ? (
            <SyncOutlined className="text-xl" spin />
          ) : (
            <img src={coupon.imgUrl} className="h-full" />
          )}
        </div>
      </div>
      <Divider />
      <p className="text-center text-gray-light">OR</p>
      <Divider />

      <div className="flex gap-12">
        <CustomCoupon 
          updateImageHandler={updateImageHandler}
          coupon={coupon}
          loosingCoupon={loosingCoupon}
        />
      </div>
      <div className="mt-4 p-4">
        <RadioButtonGroup
          values={cacheValues}
          onChange={(value) => {
            const enableCache = value === 'single';
            updateGame({ options: { ...game.options, enableCache } });
          }}
          defaultValue={!game.options.enableCache ? 'multiple' : 'single'}
          className="flex items-center gap-x-4"
        />
        <SettingsForm
          updateCoupon={updateCoupon}
          coupon={coupon}
          loosingCoupon={loosingCoupon}
        />
      </div>
    </>
  );
};

export default Coupon;
