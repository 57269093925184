export const getExt = (filename: string) => {
  var ext = filename.split('.').pop();
  if (ext == filename) return '';
  return ext;
};

export const csvJSON = (csv) => {
  const lines = csv.split('\n');
  const result = [];
  const headers = lines[0].split(',');

  for (let i = 1; i < lines.length; i++) {
    const obj = {};
    const currentline = lines[i].match(/("[^"]*")|[^,]+/g);
    const incorrectAnswers = [];
    for (let j = 0; j < headers.length; j++) {
      if (j === 2 || j === 3 || j === 4) {
        incorrectAnswers.push(currentline[j].trim());
      } else {
        obj[headers[j]] = currentline[j];
      }
    }
    obj['incorrect_answers'] = incorrectAnswers;
    result.push(obj);
  }
  return JSON.stringify(result);
};
