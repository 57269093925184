import { CouponSettings } from '@wiply/repository';
import {
  defaultBackgrounds,
  bricksContainers,
  ballGallery,
  getNinjaGalleryByType,
  defaultLoosingCoupon,
} from './defaultImages';

export const defaultAudio =
  'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';

export enum GameOutputEventEnum {
  GameLoaded = 'GAME_LOADED',
  GameWon = 'GAME_WON',
  GameLost = 'GAME_LOST',
  GameEnded = 'GAME_ENDED',
  CloseGame = 'CLOSE_GAME',
}

// export enum COLORS {
//   PRIMARY = 0,
//   SECONDARY
// };

export class GameOutputEvent {
  constructor(public type: GameOutputEventEnum) {}
}

class GameLoaded extends GameOutputEvent {}
class GameLost extends GameOutputEvent {}
class GameEnded extends GameOutputEvent {}
class GameWon extends GameOutputEvent {
  constructor(type: GameOutputEventEnum, private couponId: string) {
    super(type);
  }
}
class CloseGame extends GameOutputEvent {}

export const output = {
  gameLoaded: () =>
    parent.postMessage(
      JSON.stringify(new GameLoaded(GameOutputEventEnum.GameLoaded)),
      '*'
    ),
  gameEnded: () =>
    parent.postMessage(
      JSON.stringify(new GameEnded(GameOutputEventEnum.GameEnded)),
      '*'
    ),
  gameLost: () =>
    parent.postMessage(
      JSON.stringify(new GameLost(GameOutputEventEnum.GameLost)),
      '*'
    ),
  gameWon: (couponId: string) =>
    parent.postMessage(
      JSON.stringify(new GameWon(GameOutputEventEnum.GameWon, couponId)),
      '*'
    ),
  closeGame: () =>
    parent.postMessage(
      JSON.stringify(new CloseGame(GameOutputEventEnum.CloseGame)),
      '*'
    ),
};

// export function getTheme() {
//   try {
//     return JSON.parse(new URLSearchParams(location.search).get('theme'))
//   } catch (error) {
//     return null;
//   }
// }

export function remFromArr<T>(arr: T[], item: T) {
  const index = arr.indexOf(item);
  if (index !== -1) {
    arr.splice(index, 1);
  }
}

export function clamp(num: number, min: number, max: number): number {
  if (num > max) {
    return max;
  } else if (num < min) {
    return min;
  } else {
    return num;
  }
}

export function getCanvas(): HTMLCanvasElement {
  return <HTMLCanvasElement>document.getElementById('renderCanvas');
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const omitNull = (obj: object) => {
  Object.keys(obj)
    .filter((k) => obj[k] === null)
    .forEach((k) => delete obj[k]);
  return obj;
};

export const gamesList = [
  { name: 'Basketball', id: 'basketball' },
  { name: 'Bricks', id: 'bricks' },
  { name: 'DropIt', id: 'dropit' },
  { name: 'Puzzle', id: 'puzzle' },
  { name: 'Cups', id: 'cups' },
  { name: 'Tower', id: 'tower' },
  { name: 'Ninja', id: 'ninja' },
  { name: 'Hockey', id: 'hockey' },
  { name: 'Wheel', id: 'wheel' },
  { name: 'Memory', id: 'memory' },
  { name: 'Gems', id: 'gems' },
  { name: 'Quiz', id: 'quiz' },
  { name: 'Wordle', id: 'wordle' },
  { name: 'Jenga', id: 'jenga', unActive: true },
  { name: 'Maze', id: 'maze', unActive: true },
];

export const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
    // eslint-disable-next-line no-empty
  } catch (err) {}
  document.body.removeChild(textArea);
};

export const winningCouponTemplate = [
  {
    name: 'Title',
    values: {
      title1: 'CONGRATULATIONS', //20
      title2: 'YOU JUST', //12
      title3: 'WON!', //13
    },
    maxLength: {
      title1: 20,
      title2: 12,
      title3: 13,
    },
  },
  {
    name: 'Discount',
    values: {
      discount1: '5%', //5
      discount2: 'OFF', //7
      discountColor: '#000',
    },
    maxLength: {
      discount1: 5,
      discount2: 7,
    },
  },
  {
    name: 'Button',
    values: {
      btnText: 'Shop Now', //20
      btnColor: '#000',
    },
    maxLength: {
      btnText: 20,
    },
  },
  {
    name: 'Note',
    values: {
      note: null,
    },
  },
  {
    name: 'Background',
    values: {
      backgroundColor: '#9c80ea',
    },
  },
  {
    name: 'Code',
    values: {
      code: null,
    },
    maxLength: {
      code: 30,
    },
  },
];

export const couponTemplate: CouponSettings = {
  background: '#ffffff',
  template: 0,
  logo: null,
  title: {
    text: ['CONGRATULATIONS', 'YOU WON'],
    color: '#000000',
    maxLength: [20, 13],
  },
  discount: {
    text: ['5%', 'OFF'],
    color: '#ffffff',
    backgroundColor: '#5929c7',
    maxLength: [10, 12],
  },
  button: {
    backgroundColor: '#000000',
    color: '#ffffff',
    text: ['SHOP NOW'],
    maxLength: [20],
  },
  note: {
    text: ['Free Delivery over $20 purchase'],
    color: '#b3b3b3',
    maxLength: [50],
  },
  showCode: true,
};

export const couponTemplateLoose: CouponSettings = {
  ...couponTemplate,
  title: {
    ...couponTemplate.title,
    text: ['Oops..', 'TRY AGAIN'],
  },
  discount: {
    ...couponTemplate.discount,
    text: ["LET'S", 'DO IT'],
  },
  button: {
    ...couponTemplate.button,
    text: ['TRY AGAIN'],
  },
  note: {
    text: null,
    ...couponTemplate.note,
  },
};

export const loosingCouponTemplate = [
  {
    name: 'Title',
    values: {
      title1: 'Oops...',
      title2: 'TRY AGAIN',
      title3: ':(',
    },
    maxLength: {
      title1: 20,
      title2: 12,
      title3: 13,
    },
  },
  {
    name: 'Discount',
    values: {
      discount1: "LET'S",
      discount2: 'DO IT',
      discountColor: '#000',
    },
    maxLength: {
      discount1: 5,
      discount2: 7,
    },
  },
  {
    name: 'Button',
    values: {
      btnText: 'TRY AGAIN',
      btnColor: '#000',
    },
    maxLength: {
      btnText: 20,
    },
  },
  {
    name: 'Note',
    values: {
      note: null,
    },
    maxLength: {
      code: 30,
    },
  },
  {
    name: 'Background',
    values: {
      backgroundColor: '#9c80ea',
    },
  },
];

export const defaultOptions = {
  logoImgUrl:
    'https://res.cloudinary.com/shulgirit/image/upload/v1637506254/wiply/wiply_basketball_logo.png',
  isLosable: false,
  isCopyTextCoupon: true,
  colors: ['#D3D3D3', '#D3D3D3'],
  wheelColors: ['#BB00FF', '#060606'],
  socialShare: { title: '', url: '' },
  bricksOptions: {
    bgImgUrl: bricksContainers[0],
    rows: 2,
    mobileBgImgUrl: bricksContainers[0],
    paddleImgUrl:
      'https://res.cloudinary.com/shulgirit/image/upload/v1637511492/wiply/Platform%20Default%20Images/Bricks/gallery/paddle/7.png',
    ballImgUrl:
      'https://res.cloudinary.com/shulgirit/image/upload/v1630834730/wiply/Platform%20Default%20Images/Bricks/ball/BALL_60_60-03_o28pwj.png',
    logo: 'https://res.cloudinary.com/shulgirit/image/upload/v1637506254/wiply/wiply_basketball_logo.png',
    brickImgUrl:
      'https://res.cloudinary.com/shulgirit/image/upload/v1637512157/wiply/Platform%20Default%20Images/Bricks/gallery/brick/0.png',
  },
  basketballOptions: {
    boardImg:
      'https://res.cloudinary.com/shulgirit/image/upload/v1637506254/wiply/wiply_basketball_logo.png',
    ballImg: ballGallery[0],
    level: 3,
    balls: 6,
    gameMode: 'shotClock',
    shotClockTime: 60,
    scoreColor: 'purple',
    showScore: 'true',
  },
  bricks3dOptions: {},
  dropItOptions: {
    bgImgUrl:
      'https://res.cloudinary.com/shulgirit/image/upload/v1634470021/wiply/Platform%20Default%20Images/dropit/Purple_iujuyl.svg',
    // mobileBgImgUrl:
    // 'https://res.cloudinary.com/shulgirit/image/upload/v1634470021/wiply/Platform%20Default%20Images/dropit/Purple_iujuyl.svg',

    logoDropit:
      'https://res.cloudinary.com/shulgirit/image/upload/v1637506254/wiply/wiply_basketball_logo.png',
  },
  puzzleOptions: {},
  gemOptions: {
    bagImage:
      'https://res.cloudinary.com/dngwrudqa/image/upload/v1675076175/bag_fnr3qa.png',
    dropsImages: [
      'https://res.cloudinary.com/dngwrudqa/image/upload/v1675076170/gem_8_y13vlx.png',
    ],
  },
  ninjaOptions: {
    fruitsImages: { ...getNinjaGalleryByType('basketball') },
  },
  wordleOptions: {
    wordleTitle: 'Guess the word',
    activeTileColor: '#545859',
    correctTileColor: '#538d4e',
    wrongLocationTileColor: '#b59f3b',
    wrongTileColor: '#39393c',
    emptyTileColor: '#bbbbbb',
    difficulty: 'normal',
    language: 'english',
  },
  towerOptions: {
    floorsForFirstCoupon: 10,
    floorsForSecondCoupon: 20,
    numLives: 3,
    numCoupons: 1,
    stage:
      'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/tower/stage/0.png',
    block:
      'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/tower/main/0.png',
    perfectBlock:
      'https://res.cloudinary.com/shulgirit/image/upload/v1675173437/wiply/Platform%20Default%20Images/tower/perfectBlock/0.png',
    ropedBlock:
      'https://res.cloudinary.com/shulgirit/image/upload/v1675169403/wiply/Platform%20Default%20Images/tower/placeholder/0.png',
    hook: 'https://res.cloudinary.com/shulgirit/image/upload/v1675171216/wiply/Platform%20Default%20Images/tower/hook/0.png',

    gradientColors: {
      0: [200, 255, 150],
      1: [105, 230, 240],
      2: [90, 190, 240],
      3: [85, 100, 190],
      4: [55, 20, 35],
      5: [75, 25, 35],
      6: [25, 0, 10],
    },
  },
  memoryOptions: {
    cardImages: {
      0: 'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/0.png',
      1: 'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/1.png',
      2: 'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/2.png',
      3: 'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/3.png',
      4: 'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/4.png',
      5: 'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/5.png',
    },
    differentPictures: false,
    amountOfCards: 2,
    differentPicsObject: {
      0: [
        'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/0.png',
        0,
      ],
      1: [
        'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/0.png',
        0,
      ],
      2: [
        'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/1.png',
        1,
      ],
      3: [
        'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/1.png',
        1,
      ],
      4: [
        'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/2.png',
        2,
      ],
      5: [
        'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/2.png',
        2,
      ],
      6: [
        'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/3.png',
        3,
      ],
      7: [
        'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/3.png',
        3,
      ],
      8: [
        'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/4.png',
        4,
      ],
      9: [
        'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/4.png',
        4,
      ],
      10: [
        'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/5.png',
        5,
      ],
      11: [
        'https://res.cloudinary.com/shulgirit/image/upload/v1652619425/wiply/Platform%20Default%20Images/memory/images/wiply/5.png',
        5,
      ],
    },
  },
  menuScreenOptions: {
    quickLoad: false,
    bottomTextSize: 20,
  },
  wheelOptions: {
    base: 'https://res.cloudinary.com/shulgirit/image/upload/v1632294874/wiply/Platform%20Default%20Images/wheel/wheel-base-purple.png',
    headline: 'Fortune Wheel',
    logo: 'https://res.cloudinary.com/shulgirit/image/upload/v1637504938/wiply/logo_mrpiai.png',
    subtitle: 'Spin and Win',
    buttonText: 'Press to Play',
  },
  quizOptions: {
    questionsAmount: '3',
    numOfLives: '2',
    easy_questions: [
      {
        incorrect_answers: [
          '60 אחוז מהחידקים',
          '55 אחוז מהחיידקים',
          '80 אחוז מהחיידקים',
        ],
        question: '  כמה אחוז מהחיידקים הורגים מי האוזון של אפקטיבו??',
        correct_answer: '99.9 אחוז מהחידקים',
      },
      {
        correct_answer: 'משאיר אפס שיירים כימיים',
        question: 'מה שיירים כימיים מי אוזון משאירים לאחר החיטוי',
        incorrect_answers: [
          '20 אחוז שיירים כימיים',
          '30 אחוז שיירים כימיים',
          '40 אחוז שיירים כימיים',
        ],
      },
      {
        question: 'האם נכון שמי האוזון של אפקטיבו?',
        incorrect_answers: [
          'מפרקים כתמי דם',
          'עצמת החיטוי שווה לאלכוהול?',
          'מפרקים עובש',
        ],
        correct_answer: 'כל התשובות נכונות',
      },
    ],
  },
  cupsOptions: {
    level: 'medium',
  },
  gameLostPopUp: {
    couponId: '0',
    imgUrl: defaultLoosingCoupon,
  },
};

export const GTMData = [
  {
    label: 'Menu Screen View',
    value: null,
    id: 'menuScreenView',
    icon: 'play',
  },
  { label: 'Launched game', value: null, id: 'gameView', icon: 'rocket' },
  { label: 'Game Won', value: null, id: 'gameWon', icon: 'smile' },
  { label: 'Game Lost', value: null, id: 'gameLost', icon: 'sad' },
  { label: 'Claimed Coupon', value: null, id: 'couponClick', icon: 'claimed' },
  // {
  //   label: 'Coupon View but not clicked',
  //   id: 'notClaimedCoupon',
  //   value: null,
  //   icon: 'notClaimed',
  // },
  // { label: 'Shared Coupon', id: 'shareCoupon', value: null, icon: 'share' },
];

export const transformGTMData = (data: unknown) => {
  const transformedData = [...GTMData];
  transformedData.map((element) => {
    element.value = data[element.id] || 0;
    if (element.id === 'notClaimedCoupon') {
      element.value =
        parseInt(data['couponView'] || 0) - parseInt(data['couponClick']) || 0;
    }
  });
  return transformedData;
};
