import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  UploadOutlined,
  DeleteOutlined,
  SwitcherOutlined,
} from '@ant-design/icons';
import { Radio, Upload, message, Tooltip } from 'antd';
import CardItem from '../../shared/CardItem/index';
import CardActionItem from '../../shared/CardItem/CardActionItem/index';
import { Modal } from '../../shared/Modal';
import ModelViewer from '../../shared/ModelViewer';
import EditImageCardDescription from './EditImageCardDescription';
import { SyncOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import Button from '../../shared/Button';
import UploadFileForm from '../UploadFileForm';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';

const loadImage = (src: string, aspectRatio: number) =>
  new Promise((resolve) => {
    const img = new Image();
    img.src = src;
    img.onload = function (this: HTMLImageElement) {
      resolve(
        Number(this.width / this.height).toFixed(1) === aspectRatio.toFixed(1)
      );
    };
  });

const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : children;

const validImageDimensions = async (src: string, aspectRatio: number) =>
  await loadImage(src, aspectRatio);

export interface DescriptionField {
  text?: string | React.ReactNode;
  size?: string;
  type?: string;
  isOptional?: boolean;
  aspectRatio?: number;
}

export interface FormTitles {
  title1?: string;
  title2?: string;
  title3?: string;
  title4?: string;
  title5?: string;
}

interface EditFieldProps {
  title?: string;
  formTitles?: FormTitles;
  description?: DescriptionField;
  onChange: (field: string | File | any[]) => void;
  imagePath: string;
  uploadImage?: (file: File, path: string) => Promise<string>;
  defaultValue?: string;
  accept?: string;
  multiple?: boolean;
  clean?: boolean;
  size?: string;
  defaultImagesToChoose?: string[];
  extraActions?: React.ReactNode;
  previewCss?: string;
  disableUpload?: boolean;
  galleryPreview?: string;
  removeDelete?: boolean;
  fileName?: string;
  containerCSS?: string;
  setUploadedImage?: any;
  uploadImagesModal?: boolean;
  deleteGallery?: boolean;
}

const EditImageCard: FunctionComponent<EditFieldProps> = ({
  title,
  formTitles,
  description,
  onChange,
  imagePath,
  uploadImage,
  defaultValue,
  accept,
  multiple,
  size,
  defaultImagesToChoose,
  extraActions,
  previewCss,
  disableUpload,
  galleryPreview,
  removeDelete,
  containerCSS,
  setUploadedImage,
  uploadImagesModal,
  fileName,
  deleteGallery,
}) => {
  const [modalVisible, setIsModalVisible] = useState(false);
  const [modalNinjaVisible, setIsModalNinjaVisible] = useState(false);
  const [defaultImageSelected, setDefaultImageSelected] =
    useState(defaultValue);
  const [isLoading, setIsLoading] = useState(false);
  const [showEditImage, setShowEditImage] = useState(false);
  const [image, setImage] = useState(null);
  const currentUser = useSelector((state: RootState) => state.userReducer);

  const imagePreview = useMemo(() => {
    if (isLoading)
      return <SyncOutlined spin className={`my-6 text-5xl text-violet`} />;
    if (description?.type === 'GLB' && defaultValue)
      return <ModelViewer src={defaultValue} width="100%" />;
    if (description.type === 'mp3' && defaultValue)
      return <h1 style={{ textAlign: 'center', fontSize: '2em' }}>♪♪♪</h1>;
    // console.log(image, defaultImageSelected, defaultValue);
    return (
      <img
        className={`my-4 m-auto max-h-${multiple ? '12' : '24'} ${previewCss}`}
        src={image || defaultValue || '../../../assets/images/default.png'}
      />
    );
  }, [defaultValue, isLoading, image]);

  useEffect(() => {
    if (defaultImageSelected !== defaultValue) onChange(defaultImageSelected);
  }, [defaultImageSelected]);

  const uploadImageHandler = async ({ file }) => {
    setIsLoading(true);
    setShowEditImage(false);
    const uploadedFile: string = await uploadImage(file, imagePath);
    if (uploadedFile) {
      if (description?.aspectRatio) {
        const isValid = await validImageDimensions(
          uploadedFile,
          description?.aspectRatio
        );
        if (!isValid)
          message.warning(
            'Warning: Image aspect ratio is not the recommended.'
          );
      }
      setUploadedImage(true);
      setImage(uploadedFile);
      onChange(uploadedFile);
    }
    setIsLoading(false);
  };

  const galleryItems = useMemo(() => {
    if (!defaultImagesToChoose) return null;
    const items = defaultImagesToChoose.map((img, i) => (
      <div className="my-2 m-auto sm:m-0" key={i}>
        <Radio
          value={galleryPreview ? Object.keys(img)[0] : img}
          className="w-full"
        >
          {description?.type === 'GLB' && (
            <ModelViewer src={img} width="180px" />
          )}
          {!(description?.type === 'GLB') && (
            <img
              className={`w-full max-w-xs  ${previewCss}`}
              src={
                galleryPreview ? img[Object.keys(img)[0]][galleryPreview] : img
              }
              alt="preview"
            />
          )}
        </Radio>
      </div>
    ));
    return items;
  }, []);

  return (
    <>
      <CardItem
        actions={
          <>
            {title === 'Game Theme' && !currentUser?.isAdmin ? (
              <Tooltip
                trigger={'hover'}
                title="Please contact yoel@Wiply.com for more information about this feature"
              >
                <span>
                  {!deleteGallery && (
                    <div style={{ pointerEvents: 'none', opacity: '0.4' }}>
                      <ConditionalWrap
                        condition={multiple}
                        wrap={(children) => (
                          <Tooltip placement="right" title="Gallery">
                            {children}
                          </Tooltip>
                        )}
                      >
                        <div>
                          <CardActionItem
                            title={!multiple ? 'Gallery' : null}
                            icon={SwitcherOutlined}
                            onClickHandler={() => setIsModalVisible(true)}
                            color="white"
                          />
                        </div>
                      </ConditionalWrap>
                    </div>
                  )}
                </span>
              </Tooltip>
            ) : (
              <ConditionalWrap
                condition={multiple}
                wrap={(children) => (
                  <Tooltip placement="right" title="Gallery">
                    {children}
                  </Tooltip>
                )}
              >
                {!deleteGallery && (
                  <div>
                    <CardActionItem
                      title={!multiple ? 'Gallery' : null}
                      icon={SwitcherOutlined}
                      onClickHandler={() => setIsModalVisible(true)}
                      color="white"
                    />
                  </div>
                )}
              </ConditionalWrap>
            )}
            {uploadImagesModal && (
              <>
                <CardActionItem
                  title={!multiple ? 'Upload' : null}
                  icon={UploadOutlined}
                  color="white"
                  onClickHandler={() => setIsModalNinjaVisible(true)}
                />
                <Modal
                  title="Upload Your Images"
                  isModalVisible={modalNinjaVisible}
                  handleCancel={() => setIsModalNinjaVisible(false)}
                >
                  <div className="items-center justify-items-center place-content-center	">
                    <UploadFileForm
                      setIsModalNinjaVisible={setIsModalNinjaVisible}
                      formTitles={formTitles}
                    />
                  </div>
                </Modal>
              </>
            )}
            {!disableUpload && !uploadImagesModal && (
              <ConditionalWrap
                condition={multiple}
                wrap={(children) => (
                  <Tooltip placement="right" title="Upload">
                    {children}
                  </Tooltip>
                )}
              >
                {description?.type != 'mp3' && (
                  <Upload
                    showUploadList={false}
                    customRequest={uploadImageHandler}
                    accept={accept}
                  >
                    <CardActionItem
                      title={!multiple ? 'Upload' : null}
                      icon={UploadOutlined}
                      color="white"
                    />
                  </Upload>
                )}
                {description?.type === 'mp3' && (
                  <Upload
                    showUploadList={false}
                    customRequest={uploadImageHandler}
                    accept={accept}
                  >
                    <CardActionItem
                      title={!multiple ? 'Upload' : null}
                      icon={UploadOutlined}
                      color="white"
                    />
                  </Upload>
                )}

                <Modal
                  title={
                    description?.type != 'mp3' ? 'Upload Image' : 'Upload Music'
                  }
                  isModalVisible={showEditImage}
                  handleCancel={() => setShowEditImage(false)}
                >
                  <div className="grid md:grid-cols-2 items-center justify-items-center">
                    {description?.type != 'mp3' && (
                      <ImgCrop
                        rotationSlider
                        showGrid
                        aspect={description?.aspectRatio || 1}
                        minZoom={0}
                        cropperProps={{
                          objectFit: 'horizontal-cover',
                          initialCroppedAreaPixels: {
                            x: 0,
                            y: 0,
                            width: 100,
                            height: 100,
                          },
                          restrictPosition: false,
                          style: undefined,
                          zoomSpeed: undefined,
                          mediaProps: undefined,
                        }}
                        fillColor="transparent"
                      >
                        <Upload
                          showUploadList={false}
                          customRequest={(file) => uploadImageHandler(file)}
                          accept={accept}
                        >
                          <Button
                            title="Upload and Edit"
                            icon={<UploadOutlined />}
                            className="h-16 px-12"
                            style="inverted"
                          />
                        </Upload>
                      </ImgCrop>
                    )}

                    <Upload
                      showUploadList={false}
                      customRequest={(file) => uploadImageHandler(file)}
                      accept={accept}
                    >
                      <div className="py-2 flex items-center justify-center lg:justify-start">
                        <Button
                          title="Upload"
                          icon={<UploadOutlined />}
                          className="h-16 px-12"
                        />
                      </div>
                    </Upload>
                    {description?.type === 'mp3' && (
                      <>
                        <a href={fileName} target="_blank" id="musicLink">
                          Current Music Link
                        </a>
                      </>
                    )}
                  </div>
                </Modal>
              </ConditionalWrap>
            )}
            {!removeDelete && (
              <ConditionalWrap
                condition={multiple}
                wrap={(children) => (
                  <Tooltip placement="right" title="Delete">
                    {children}
                  </Tooltip>
                )}
              >
                <div>
                  <CardActionItem
                    title={!multiple ? 'Delete' : null}
                    icon={DeleteOutlined}
                    onClickHandler={() => onChange(null)}
                    color="white"
                  />
                </div>
              </ConditionalWrap>
            )}

            {extraActions}
          </>
        }
        size={size}
        className={containerCSS}
        actionsStyle="gradient"
      >
        <EditImageCardDescription
          title={title}
          imagePreview={imagePreview}
          description={description}
        />
      </CardItem>

      {/* Options modal when opening gallary and selecting a default image to choose from  */}
      <Modal
        title={<span className="block font-bold">Select your Image</span>}
        handleCancel={() => setIsModalVisible(false)}
        isModalVisible={modalVisible}
        width={'70vw'}
      >
        <Radio.Group
          onChange={(e) => {
            setImage(null);
            setDefaultImageSelected(e.target.value);
            setIsModalVisible(false);
          }}
          value={defaultImageSelected}
        >
          <div className="w-auto flex flex-wrap gap-4 m-auto place-content-center">
            {galleryItems}
          </div>
        </Radio.Group>
      </Modal>
    </>
  );
};

export default EditImageCard;
