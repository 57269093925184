import React, { FunctionComponent } from 'react';
import '@google/model-viewer/dist/model-viewer';

interface ModelViewerProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  src: string;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': ModelViewerProps;
    }
  }
}

interface Props {
  src: string;
  width: string;
}

export const ModelViewer: FunctionComponent<Props> = ({ src, width }) => {
  return (
    <model-viewer
      src={src}
      shadow-intensity="1"
      camera-controls
      auto-rotate
      style={{ width }}
    ></model-viewer>
  );
};

export default ModelViewer;
