import firebase from 'firebase/app';
import 'firebase/auth';
import { environment } from '@env/environment';

if (!firebase.apps.length) {
  firebase.initializeApp(environment.firebaseConfig);
}

export { ClientRepository } from './repositories/client.repository'; 
export { GameRepository, DefaultClientGame } from './repositories/game.repository';
export { PlayerRepository } from './repositories/player.repository';
export { UserRepository } from './repositories/user.repository';
export { AuthService } from './auth.service';
export { SubdomainRepository } from './repositories/subdomain.repository';

export type User = firebase.User;
