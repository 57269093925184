import React, { FunctionComponent } from 'react';
import { GameDesignAssetsProps } from '.';
import UploadFileCard from '../GameDesignCard/UploadFileCard';
import EditImageCard from '../GameDesignCard/EditImageCard/EditImageCard';
import { QuizAnswerBarImages, QuizCorrectBarImages, QuizIncorrectBarImages, QuizLivesImages, QuizQuestionImages, defaultQuizThemes, url } from '@wiply/utils';
import EditTextCard from '../GameDesignCard/EditTextCard';
import EditOptionsCard from '../GameDesignCard/EditOptionsCard';

export const GameDesignAssetsQuiz: FunctionComponent<GameDesignAssetsProps> = ({
  game,
  onChangeHandler,
  commonProps,
}) => {
  const { quizOptions } = game.options;
  return (
    <>
      <UploadFileCard
        title="Questions"
        game={game}
        onChangeHandler={onChangeHandler}
        instance="easy_questions"
      />
      <EditImageCard
        {...commonProps}
        title="Background Question Image"
        description={{
          type: 'PNG',
          aspectRatio: 1,
        }}
        accept="image/png"
        defaultValue={
          quizOptions?.questionBackground ||
          'https://res.cloudinary.com/dngwrudqa/image/upload/v1676964627/q_de3qrx_dq7wlu.png'
        }
        onChange={(field: string) => {
          onChangeHandler({
            quizOptions: {
              ...quizOptions,
              questionBackground: field,
            },
          });
        }}
        defaultImagesToChoose={QuizQuestionImages}
        disableUpload={false}
      />
      <EditImageCard
        {...commonProps}
        title="Lives Image"
        description={{
          type: 'PNG',
          aspectRatio: 1,
        }}
        accept="image/png"
        defaultValue={
          quizOptions?.livesImage ||
          'https://res.cloudinary.com/dngwrudqa/image/upload/v1676964664/atlas0_1_yrfvo5_nknmzt.png'
        }
        onChange={(field: string) => {
          onChangeHandler({
            quizOptions: {
              ...quizOptions,
              livesImage: field,
            },
          });
        }}
        defaultImagesToChoose={QuizLivesImages}
        disableUpload={false}
      />
      <EditImageCard
        {...commonProps}
        title="Answer Bar"
        description={{
          type: 'PNG',
          aspectRatio: 1,
        }}
        accept="image/png"
        defaultValue={
          quizOptions?.answerBar ||
          'https://res.cloudinary.com/dngwrudqa/image/upload/v1676964472/ans_slimwa_1_1_ppp9d1.png'
        }
        onChange={(field: string) => {
          onChangeHandler({
            quizOptions: {
              ...quizOptions,
              answerBar: field,
            },
          });
        }}
        defaultImagesToChoose={QuizAnswerBarImages}
        disableUpload={false}
      />
      <EditImageCard
        {...commonProps}
        title="Correct Answer Bar"
        description={{
          type: 'PNG',
          aspectRatio: 1,
        }}
        accept="image/png"
        defaultValue={
          quizOptions?.correctAnswerBar ||
          'https://res.cloudinary.com/dngwrudqa/image/upload/v1676964513/ans_slimwa_1_2_v6o1fw.png'
        }
        onChange={(field: string) => {
          onChangeHandler({
            quizOptions: {
              ...quizOptions,
              correctAnswerBar: field,
            },
          });
        }}
        defaultImagesToChoose={QuizCorrectBarImages}
        disableUpload={false}
      />
      <EditImageCard
        {...commonProps}
        title="Incorrect Answer Bar"
        description={{
          type: 'PNG',
          aspectRatio: 1,
        }}
        accept="image/png"
        defaultValue={
          quizOptions?.incorrectAnswerBar ||
          'https://res.cloudinary.com/dngwrudqa/image/upload/v1676964514/ans_slimwa_1_3_jyjieb.png'
        }
        onChange={(field: string) => {
          onChangeHandler({
            quizOptions: {
              ...quizOptions,
              incorrectAnswerBar: field,
            },
          });
        }}
        defaultImagesToChoose={QuizIncorrectBarImages}
        disableUpload={false}
      />
      <EditTextCard
        {...commonProps}
        type="text"
        title="Questions Amount"
        onSave={(fields: any) =>
          onChangeHandler({
            quizOptions: {
              ...game.options.quizOptions,
              ...fields,
            },
          })
        }
        items={{
          questionsAmount: game.options.quizOptions?.questionsAmount || '3',
        }}
        formItems={[
          {
            name: 'questionsAmount',
            title: 'How many questions do you want for winning the prize',
          },
        ]}
      />
      <EditOptionsCard
        {...commonProps}
        title="Number of lives"
        value1="1"
        value2="2"
        value3="3"
        defaultValue={game.options.quizOptions.numOfLives || '3'}
        onChange={(field: string) =>
          onChangeHandler({
            quizOptions: {
              ...game.options.quizOptions,
              numOfLives: field,
            },
          })
        }
      />
    </>
  );
};

export default GameDesignAssetsQuiz;
