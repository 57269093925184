import React, { FunctionComponent, useState } from 'react';
import {
  UploadOutlined,
  FileExcelOutlined,
  DatabaseOutlined,
  InfoCircleOutlined,
  EyeOutlined,
  ClockCircleOutlined,
  FileOutlined,
} from '@ant-design/icons';
import { CardItem } from '../shared/CardItem/index';
import { Popover, Radio, Upload } from 'antd';
import Button from '../shared/Button';
import CardActionItem from '../shared/CardItem/CardActionItem/index';
import { ClientGame } from '@wiply/repository';
import { csvJSON, getExt } from './uploadFile';
import { environment } from '@env/environment';
import { saveAs } from 'file-saver';
import Modal from '../shared/Modal';
import CreateQuestions from './CreateQuestions';
const exampleUrl =
  'https://res.cloudinary.com/shulgirit/raw/upload/v1640002187/wiply/Platform%20Default%20Images/quiz/example';

interface Props {
  game: ClientGame;
  onChangeHandler: any;
  title: string;
  instance: string;
}
enum TimerValues {
  Fast = 1,
  Medium = 2,
  Slow = 3,
  None = 0,
}

export const UploadFileCard: FunctionComponent<Props> = ({
  game,
  onChangeHandler,
  title,
  instance,
}) => {
  let url;
  if (environment.production) {
    url = 'https://wiply-json.netlify.app/';
  } else {
    url = 'https://wiply-json.netlify.app/';
  }

  const [showModal, setShowModal] = useState(false);
  const [timerModal, setTimerModal] = useState(false);
  const [createQModal, setCreateQModal] = useState(false);
  const [uploadedFile, setUpLoadedFile] = useState(
    game.options.quizOptions?.easy_questions
      ? new File(
          [JSON.stringify(game.options?.quizOptions[instance])],
          'questions.json'
        )
      : null
  );

  const timer = game.options.quizOptions?.timer;
  const [timerValue, setTimerValue] = React.useState<number>(
    timer && timer[instance] ? timer[instance] : TimerValues.None
  );
  const uploadHandler = ({ file }) => {
    setUpLoadedFile(file);
    const fileReader = new FileReader();
    fileReader.onload = (evt) => {
      try {
        const content = evt.target.result;
        if (typeof content === 'string') {
          const json = getExt(file.name) === 'csv' ? csvJSON(content) : content;
          onChangeHandler({
            quizOptions: {
              ...game.options.quizOptions,
              [instance]: JSON.parse(json),
            },
            labels: {
              ...game.options.labels,
              uploadFile: true,
            },
          });
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };
    fileReader.readAsText(file);
  };

  const download = () => {
    if (uploadedFile) saveAs(uploadedFile);
  };

  const onChangeTimer = (value: string) => {
    onChangeHandler({
      quizOptions: {
        ...game.options.quizOptions,
        timer: {
          ...game.options.quizOptions.timer,
          [instance]: value,
        },
      },
    });
  };
  return (
    <>
      <CardItem
        actions={
          <>
            <CardActionItem
              title="Get a CSV example"
              icon={FileExcelOutlined}
              linkTo={`${exampleUrl}.csv`}
            />
            <CardActionItem
              title="Click here to create your questions"
              icon={FileOutlined}
              onClickHandler={() => setCreateQModal(true)}
            />
            <CardActionItem
              title="Set timer"
              icon={ClockCircleOutlined}
              onClickHandler={() => setTimerModal(true)}
            />
          </>
        }
      >
        <div className="my-2">
          <h3 className="mt-2 text-md font-medium text-center flex items-center justify-center">
            {title}{' '}
            <Popover
              placement="left"
              content={
                <p className="text-gray text-center">
                  Must be a CSV or JSON File. Minimum 5 questions per lever
                </p>
              }
            >
              <InfoCircleOutlined className="text-violet cursor-pointer ml-2" />
            </Popover>
          </h3>

          <p className="text-xs text-center my-2">
            <strong>CSV</strong>: 4 columns, in this order: question,
            correct_answer, incorrect answer 1, incorrect answer 2, incorrect
            answer 3. Check the example{' '}
          </p>
          <div className="text-xs text-center mb-2">
            <p>
              <strong>JSON</strong>: Array of questions:{' '}
            </p>
            <ul>
              <li>question: string;</li>
              <li>correct_answer: string;</li>
              <li>incorrect_answers: string[];</li>
            </ul>
          </div>
        </div>
        <Upload
          name="import"
          maxCount={1}
          accept="application/json, .csv"
          showUploadList={false}
          customRequest={uploadHandler}
          className="flex justify-center"
        >
          <Button icon={<UploadOutlined />} title="Click to Upload" />
        </Upload>

        {game.options.quizOptions && game.options.quizOptions[instance] && (
          <Button
            className="flex justify-center m-auto my-2 w-1/2 items-center"
            onClick={download}
            title="Download"
          />
        )}
        {game.options.quizOptions && game.options.quizOptions[instance] && (
          <Button
            className="flex justify-center m-auto my-2 w-1/2 items-center"
            style="inverted"
            icon={<EyeOutlined />}
            title="Preview"
            onClick={() => setShowModal(true)}
          />
        )}
      </CardItem>
      {game.options.quizOptions && game.options.quizOptions[instance] && (
        <Modal
          title="Preview"
          isModalVisible={showModal}
          handleCancel={() => setShowModal(false)}
        >
          <div className="py-8 justify-center items-center">
            {game.options.quizOptions[instance].map((problem) => {
              return (
                <div key={problem.question}>
                  <div>
                    <h4>{problem.question}</h4>
                  </div>
                  <div>
                    <b>{problem.correct_answer}</b>
                  </div>
                  <div>
                    <ul>
                      <li>{problem.incorrect_answers[0]}</li>
                      <li>{problem.incorrect_answers[1]}</li>
                      <li>{problem.incorrect_answers[2]}</li>
                    </ul>
                    <br />
                  </div>
                </div>
              );
            })}
          </div>
        </Modal>
      )}

      <Modal
        title='Questions Creator'
        isModalVisible={createQModal}
        handleCancel={() => setCreateQModal(false)}
      >
        {/* Passing in onChangeHandler method and quizOptions to Create Questions */}
        <CreateQuestions onChangeHandler={onChangeHandler} quizOptions={game.options.quizOptions} instance={game.options.quizOptions[instance]} />
      </Modal>

      <Modal
        title="Set Timer"
        isModalVisible={timerModal}
        handleCancel={() => setTimerModal(false)}
      >
        <Radio.Group
          onChange={(event) => {
            setTimerValue(event.target.value);
            onChangeTimer(event.target.value);
          }}
          value={timerValue}
          className="text-center my-12"
        >
          <Radio value={TimerValues.None}>None</Radio>
          <Radio value={TimerValues.Slow}>x1</Radio>
          <Radio value={TimerValues.Medium}>x2</Radio>
          <Radio value={TimerValues.Fast}>x3</Radio>
        </Radio.Group>
      </Modal>
    </>
  );
};

export default UploadFileCard;
