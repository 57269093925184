import React, { FunctionComponent, memo, useContext } from 'react';
import { Table } from 'antd';
import PlayersContext from '../../context/players';
import Spinner from '../shared/Spinner';
import { CSVLink, CSVDownload } from 'react-csv';
import styles from './GameScore.module.scss';

const columns = [
  {
    title: 'Player ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'id',
    filters: [
      {
        text: 'Has Email',
        value: 'hasEmail',
      },
    ],
    onFilter: (_, record) => record.email,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'id',
  },
  {
    title: 'Score',
    dataIndex: 'score',
    key: 'id',
    sorter: (a, b) => a.score - b.score,
  },
];

const GameScores: FunctionComponent = () => {
  const { players } = useContext(PlayersContext);
  if (!players) return <Spinner />;
  return (
    <>
      <div className="border-b border-gray-light pb-2">
        <h2 className="text-2xl font-bold ml-4 ">Scores</h2>
      </div>
      <Table
        columns={columns}
        dataSource={players}
        rowKey={({ id }) => id}
        rowClassName="cursor-pointer"
      />
      <CSVLink data={players}>
        <span className={styles.button}> Download Table!</span>
      </CSVLink>
    </>
  );
};

export default GameScores;
