import React, {
  FunctionComponent,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import GamesContext from '../../context/games';
import { GameCoupon } from '@wiply/repository';
import Coupon from '../Coupons';
import { defaultLostCoupons, defaultWinningCoupons } from '@wiply/utils';
import { Tabs, message, Switch } from 'antd';

const { TabPane } = Tabs;

const success = () => {
  message.success({
    content: 'Saved!',
  });
};

export const GameCouponsTab: FunctionComponent = () => {
  const { game, updateGame, uploadImage } = useContext(GamesContext);
  const [coupons, setCoupons] = useState(game.coupons);
  const [fields, setFields] = useState(game);
  const [disabledCoupons, setDisabledCoupons] = useState<boolean>(
    game.options.disableCoupon || false
  );
  const [disableShare, setDisabledShare] = useState<boolean>(
    game.options.disableShare || false
  );
 

  const updateCouponHandler = useCallback(
    (entity: GameCoupon) => {
      const updatedCoupons = [...fields.coupons];
      const index = updatedCoupons.findIndex(
        (c) => c.couponId == entity.couponId
      );
      updatedCoupons[index] = entity;
      setCoupons(updatedCoupons);
      updateGame({
        coupons: updatedCoupons,
        options: {
          ...game.options,
          labels: { ...game.options.labels, uploadCoupon: true },
        },
      });
      setFields({ ...fields, coupons: updatedCoupons });
      success();
    },
    [coupons, game, fields]
  );

  useEffect(() => {
    setFields(game);
  }, [game]);

  const updateImageHandler = async ({ file }) => {
    const uploadedFile: string = await uploadImage(file, `games/${game.id}`);
    if (uploadedFile) return uploadedFile;
    return null;
  };

  const updateLostCouponHandler = (entity: GameCoupon) => {
    const updatedEntity = {
      options: {
        ...game.options,
        gameLostPopUp: {
          ...game.options.gameLostPopUp,
          ...entity,
        },
      },
    };
    updateGame(updatedEntity);
    setFields({ ...fields, ...updatedEntity });
    success();
  };

  const disableCouponsHandler = () => {
    const updatedEntity = {
      options: {
        ...game.options,
        disableCoupon: !disabledCoupons,
      },
    };
    setDisabledCoupons(!disabledCoupons);
    updateGame(updatedEntity);
    setFields({ ...fields, ...updatedEntity });
  };

  const disableShareHnadler = () => {
    const updatedEntity = {
      options: {
        ...game.options,
        disableShare: !disableShare,
      },
    };
    setDisabledShare(!disableShare);
    updateGame(updatedEntity);
    setFields({ ...fields, ...updatedEntity });
  };

  

  return (
    <div className="mx-6 my-4">
      <Switch
        checkedChildren="Enable Coupons"
        unCheckedChildren="Disable Coupons"
        defaultChecked={!disabledCoupons}
        onChange={disableCouponsHandler}
      />
      <Switch
        className="ml-1.5"
        checkedChildren="Enable Share Buttons"
        unCheckedChildren="Disable Share Buttons"
        defaultChecked={!disableShare}
        onChange={disableShareHnadler}
      />
     
      <div
        className={`my-8 grid ${
          disabledCoupons ? 'pointer-events-none opacity-40' : ''
        }`}
      >
        <Tabs type="card" className={`${game.type == "wheel" ? "" : "overflow-visible"}`}>
          {fields.coupons.map((item: GameCoupon, i) => (
            <TabPane tab={`Winning Coupon: ${i + 1}`} key={i} >
              <Coupon
                coupon={item}
                updateCoupon={updateCouponHandler}
                updateImage={updateImageHandler}
                defaultImagesToChoose={defaultWinningCoupons}
              />
            </TabPane>
          ))}
          {game.options.gameLostPopUp && (
            <TabPane tab={`Losing Coupon`} key="loosing">
              <Coupon
                coupon={fields.options.gameLostPopUp}
                updateCoupon={updateLostCouponHandler}
                updateImage={updateImageHandler}
                defaultImagesToChoose={defaultLostCoupons}
                loosingCoupon={true}
              />
            </TabPane>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default GameCouponsTab;
