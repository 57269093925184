import React, { useState, FunctionComponent } from 'react';
import { GameRepository } from '@wiply/firesbase';
import { ClientGame } from '@wiply/repository';
import { GameTypeEnum } from '@wiply/utils';

interface GamesContexProviderInterface {
  children: React.ReactNode;
}

interface GamesContextInterface {
  games: ClientGame[];
  getGame: (id: string) => Promise<void>;
  getGames: (clientId?: string) => () => void;
  game: ClientGame;
  resetGames: () => void;
  filterGames: (id: string) => void;
  updateGame: (entity: any) => void;
  uploadImage: (
    file: File,
    path: string,
    couponImage?: boolean
  ) => Promise<string>;
  subscribeGame: (id: string) => () => void;
  createGame: (name: string, clientId: string, type: GameTypeEnum, date: any) => void;
  removeGame: (id: string) => void;
  importJSON: (file: File) => void;
  exportJSON: () => void;
}

const GamesContext = React.createContext<GamesContextInterface>({
  games: [],
  game: null,
  getGame: async (id: string) => {},
  getGames: (clientId?: string) => () => {},
  resetGames: () => {},
  filterGames: (id: string) => {},
  updateGame: (entity: any) => {},
  uploadImage: (file: File, path: string, couponImage?: boolean) => null,
  subscribeGame: (id: string) => () => {},
  createGame: (name: string, clientId: string, type: GameTypeEnum, date: any) => {},
  removeGame: (id: string) => {},
  importJSON: (file: File) => {},
  exportJSON: () => {},
});

const gameRepository = new GameRepository();
export const GamesContextProvider: FunctionComponent<GamesContexProviderInterface> = ({
  children,
}) => {
  const [games, setGames] = useState<ClientGame[]>(null);
  const [game, setGame] = useState<ClientGame>();

  const getGamesHandler = (clientId?: string) => {
    return gameRepository.subscribeAll(
      setGames,
      clientId && {
        condition: 'clientId',
        value: clientId,
      }
    );
  };

  const subscribeGameHandler = (id: string) =>
    gameRepository.subscribe(id, setGame);

  const getGameHandler = async (id: string) => {
    const fetchedGame = await gameRepository.fetch(id);
    if (fetchedGame) setGame(fetchedGame);
  };

  const filterGamesHandler = (id: string) => {
    const filteredGame = games.filter((c) => c.id === id);
    setGames(filteredGame);
  };

  const resetGamesHandler = () => getGamesHandler();

  const updateGameHandler = (entity: ClientGame) =>
    gameRepository.update(game?.id || entity.id, entity);

  const uploadImageHandler = (file: File, path: string) =>
    gameRepository.uploadFile(file, path);

  const createGameHandler = (
    name: string,
    clientId: string,
    type: GameTypeEnum,
    date: string,
  ) => gameRepository.createDefaultGame(name, clientId, type, date);

  const removeGameHandler = (id: string) => gameRepository.remove(id);

  const importJSONHandler = (file: File) => {
    const fileReader = new FileReader();
    fileReader.onload = (evt) => {
      try {
        const content = evt.target.result;
        if (typeof content === 'string') {
          const { id, clientId } = game;
          const newContent = JSON.parse(content);
          newContent.id = id;
          newContent.clientId = clientId;
          updateGameHandler({ ...newContent, id });
        }
      } catch (e) {}
    };
    fileReader.readAsText(file);
  };

  const exportJSONHandler = () => {
    const data = JSON.stringify(game);
    const uri = 'data:text/json;charset=UTF-8,' + encodeURIComponent(data);
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', uri);
    downloadAnchorNode.setAttribute('download', `${game.id}.json`);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  const context = {
    games,
    game,
    getGame: getGameHandler,
    getGames: getGamesHandler,
    resetGames: resetGamesHandler,
    filterGames: filterGamesHandler,
    updateGame: updateGameHandler,
    uploadImage: uploadImageHandler,
    subscribeGame: subscribeGameHandler,
    createGame: createGameHandler,
    removeGame: removeGameHandler,
    importJSON: importJSONHandler,
    exportJSON: exportJSONHandler,
  };

  return (
    <GamesContext.Provider value={context}>{children}</GamesContext.Provider>
  );
};

export default GamesContext;
