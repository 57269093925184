import React, { useState } from 'react';
import { ClientRepository } from '@wiply/firesbase';
import { Client } from '@wiply/repository';

interface ClientContext {
  clients: Client[];
  client: boolean;
  validateClient: (id: string) => void;
  getClient: (id: string) => void;
  createClient: (name: string, logo: string) => void;
  removeClient: (id: string) => void;
  subscribeClients: () => () => void;
  updateGamesAmount: (id: string, increase: boolean) => void;
  updateClient: (id: string, entity) => void;
  checkTrialPeriodEnded: (dateCreated: string) => boolean;
}

const ClientsContext = React.createContext<ClientContext>({
  clients: [],
  client: null,
  validateClient: (id: string) => {},
  getClient: (id: string) => {},
  createClient: (name: string, logo: string) => {},
  removeClient: (id: string) => {},
  subscribeClients: () => () => {},
  updateGamesAmount: (id: string, increase: boolean) => {},
  updateClient: (id: string, entity) => {},
  checkTrialPeriodEnded: (dateCreated: string) => false,
});

const clientRespository = new ClientRepository();

export const ClientContextProvider = ({ children }) => {
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);

  const validateClientHandler = async (id: string) => {
    const client = await clientRespository.fetch(id?.toLowerCase());
    setClient(client ? true : false);
  };

  const getClientHandler = async (id: string) => {
    const clientFiltered = clients.filter((c) => c.id === id);
    return clientFiltered[0];
  };

  const updateClientHandler = async (id: string, entity) =>
    await clientRespository.update(id.toLowerCase(), entity);

  // const resetClientsHandler = () => setfilteredClients(clients);

  const createClientHandler = (name: string, email: string) =>
    clientRespository.createDefaultClient(name, email);

  const subscribeClientsHandler = () =>
    clientRespository.subscribeAll(setClients);

  const removeClientHandler = (id: string) => {
    clientRespository.remove(id);
  };

  const updateGamesAmountHandler = async (
    id: string,
    increase: boolean = true
  ) => {
    await clientRespository.updateGamesAmount(id.toLowerCase(), increase);
  };

  const checkTrialPeriodEndedHandler = (date: string) =>
    clientRespository.checkFreeTiralEnds(date);

  const context = {
    clients,
    client,
    validateClient: validateClientHandler,
    getClient: getClientHandler,
    createClient: createClientHandler,
    removeClient: removeClientHandler,
    subscribeClients: subscribeClientsHandler,
    updateGamesAmount: updateGamesAmountHandler,
    updateClient: updateClientHandler,
    checkTrialPeriodEnded: checkTrialPeriodEndedHandler,
  };
  return (
    <ClientsContext.Provider value={context}>
      {children}
    </ClientsContext.Provider>
  );
};

export default ClientsContext;
