import React, { FunctionComponent, memo, useState } from 'react';
import { Radio } from 'antd';

interface RadioProps {
  label: string;
  value: string | number;
}

interface Props {
  className?: string;
  values: RadioProps[];
  defaultValue?: string | number;
  onChange: (value: string) => void;
}

export const RadioButtonGroup: FunctionComponent<Props> = memo(({
  values,
  className,
  defaultValue,
  onChange
}) => {
  const [value, setValue] = useState(defaultValue);
  const onChangeHandler = (e) => {
    const target = e.target.value;
    setValue(target);
    onChange(target);
  }
  return (
    <Radio.Group
      onChange={onChangeHandler}
      value={value}
      className={className}
    >
      {values.map(({value, label}) => (
        <Radio value={value} key={value} className="text-violet text-lg">{label}</Radio>
      ))}
    </Radio.Group>
  );
});

export default RadioButtonGroup;
