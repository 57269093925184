import React, { FunctionComponent } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Modal from '../../Modal';
import Button from '../../Button';
import SVG from 'react-inlinesvg';

interface Props {
  title?: string;
  showConfirmModal: boolean;
  setShowConfirmModal?: (value: boolean) => void;
  onConfirmHandler?: () => void;
}

const CardActionConfirmModal: FunctionComponent<Props> = ({
  setShowConfirmModal,
  onConfirmHandler,
  showConfirmModal,
}) => (
  <Modal
    title={<ExclamationCircleOutlined className="text-7xl" />}
    isModalVisible={showConfirmModal}
    handleCancel={() => setShowConfirmModal(false)}
  >
    <SVG
      src="https://res.cloudinary.com/shulgirit/image/upload/v1641113990/wiply/Platform%20Default%20Images/confirm-2.svg"
      className="absolute w-2/6 top-24 -left-6"
    />
    <h3 className="font-medium text-center text-4xl py-4 text-violet">
      Are you sure
      <span className="text-xl block">you want to delete it?</span>
      <div className="grid md:grid-cols-2 gap-4 w-2/6 m-auto mt-8">
        <Button title="No" onClick={() => setShowConfirmModal(false)} />
        <Button
          title="Delete"
          style="inverted"
          onClick={() => {
            onConfirmHandler();
            setShowConfirmModal(false);
          }}
        />
      </div>
    </h3>
    <SVG
      src="https://res.cloudinary.com/shulgirit/image/upload/v1641113990/wiply/Platform%20Default%20Images/confirm.svg"
      className="absolute w-2/6 top-12 -right-6"
    />
  </Modal>
);

export default CardActionConfirmModal;
