import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';

interface VideoModalButtonProps {
    videoSrc: string;
}

const VideoModalButton: React.FC<VideoModalButtonProps> = ({ videoSrc }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const showModal = () => {
        console.log('Clicked help');
        if (iframeRef.current) {
            iframeRef.current.src = videoSrc; // set the src when opening the modal
        }
        setIsModalVisible(true);
    };

    const handleOk = () => {
        console.log('Clicked ok');
        setIsModalVisible(false);
        if (iframeRef.current) {
            iframeRef.current.src = ''; // empty the src when closing the modal
        }
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setIsModalVisible(false);
        if (iframeRef.current) {
            iframeRef.current.src = ''; // empty the src when closing the modal
        }
    };

    useEffect(() => {
        return () => { // cleanup function to unload the video when component is unmounted
            if (iframeRef.current) {
                iframeRef.current.src = '';
            }
        }
    }, []);

    return (
        <>
            <button type='button' title='Help' onClick={showModal}>
                <QuestionCircleFilled style={{ fontSize: '25px', color: '#8830c9' }} />
            </button>
            <Modal
                title="Help Video"
                visible={isModalVisible}
                onCancel={handleCancel}
                width={720}
                footer={[
                    <Button key="submit" type="primary" onClick={handleOk} style={{ backgroundColor: "#8830c9", borderColor: "#8830c9", color: "#fff" }}>
                        OK
                    </Button>,
                ]}
            >
                <div style={{ height: 0, paddingBottom: '56.25%', position: 'relative' }}>
                    <iframe
                        ref={iframeRef}
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        width="100%"
                        height="100%"
                        src={videoSrc}
                        title="Cloudinary video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>
            </Modal>
        </>
    );
};

export default VideoModalButton;
