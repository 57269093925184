import React, { FunctionComponent, useEffect, useState } from 'react';
import { Form, Input, Alert, Image } from 'antd';
import { FormProps } from 'antd/es/form';
import Button from '../components/shared/Button';
import { useSelector, useDispatch } from 'react-redux';
import { createUser } from '../features/users';
import { RootState } from '../reducers';
import Spinner from '../components/shared/Spinner';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import LoginWithSocial from '../components/LoginWithSocial';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

const SignUp: FunctionComponent = () => {
  const [error, setError] = useState<FormProps['onFinishFailed'] | any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector((state: RootState) => state.userReducer);
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();
  const navigate = useNavigate();

  const onFinish = async ({ username, password }) => {
    setIsLoading(true);
    const clientId = await dispatch(createUser(username, password));
    if (clientId) navigate('/');
  };

  useEffect(() => {
    if (currentUser?.errors?.register) {
      setError(currentUser.errors.register);
      setIsLoading(false);
    } else {
      setError(null);
    }
    if (currentUser.loggedIn && !currentUser.errors?.register) {
      setIsLoading(false);
      navigate('/');
    }
  }, [currentUser]);

  useEffect(()=> {
    setError(null);
  }, []);

  const onFinishFailed: FormProps['onFinishFailed'] = (errorInfo) =>
    console.log('Failed:', errorInfo);

  return (
    <div className="w-full h-screen flex ">
      <div
        className="h-full hidden lg:flex w-1/2  sm:pr-8  m-auto self-center my-12 sm:my-0 signup"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/shulgirit/image/upload/v1641896455/wiply/Platform%20Default%20Images/background.jpg')",
        }}
      >
        <Image
          preview={false}
          className="m-auto max-w-full h-auto text-gray-600 align-middle box-border"
          src="https://res.cloudinary.com/shulgirit/image/upload/c_scale,h_700/v1640174509/wiply/Platform%20Default%20Images/auth-cover.png"
        />
      </div>
      <div className="flex flex-shrink-0 justify-center items-center px-6 w-full max-w-full tracking-normal leading-5 text-gray-600 bg-white box-border lg:flex-none lg:p-16 lg:w-1/2">
        <div className="leading-5 xl:px-6 box-border md:flex-none lg:flex-none md:w-9/12">
          <h2 className="mt-0 mb-4 font-medium text-gray-700 xl:text-3xl box-border">
            Welcome To Wiply 👋
          </h2>
          <p className="mt-0 mb-6 leading-6 box-border">
            Create your free account
          </p>
          {isLoading && <Spinner />}
          {!isLoading && (
            <Form
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="mt-6 text-gray-600 box-border"
            >
              <div className="mb-4 box-border">
                <label
                  htmlFor="login-email"
                  className="inline-block mb-1 text-sm leading-4 text-gray-700 cursor-default box-border"
                >
                  Email
                </label>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input a correct email',
                      type: 'email',
                    },
                  ]}
                  hasFeedback
                >
                  <Input className="rounded-lg py-2" />
                </Form.Item>
              </div>
              <div className="mb-4 box-border">
                <div className="flex justify-between box-border">
                  <label
                    htmlFor="login-password"
                    className="inline-block mb-1 text-sm leading-4 text-gray-700 cursor-default box-border"
                  >
                    Password
                  </label>
                </div>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: 'Please input your password!' },
                    { type: 'string', min: 6 },
                  ]}
                  hasFeedback
                >
                  <Input.Password className="rounded-lg" />
                </Form.Item>
              </div>
              <div className="mb-4 box-border">
                <div className="flex justify-between box-border">
                  <label
                    htmlFor="confirm"
                    className="inline-block mb-1 text-sm leading-4 text-gray-700 cursor-default box-border"
                  >
                    Confirm Password
                  </label>
                </div>
                <Form.Item
                  name="confirm"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          'The two passwords that you entered do not match!'
                        );
                      },
                    }),
                    { type: 'string', min: 6 },
                  ]}
                >
                  <Input.Password className="rounded-lg" />
                </Form.Item>
              </div>
              <Form.Item>
                <Button
                  className="h-full bg-violet block overflow-hidden relative py-3 px-6 m-0 w-full text-base font-medium leading-none text-center text-white normal-case align-middle  rounded-md border border-indigo-500 border-solid shadow-none select-none box-border hover:bg-violet hover:border-indigo-700 hover:text-white focus:bg-indigo-600 focus:border-indigo-700 focus:shadow-none focus:text-white"
                  submit={true}
                  title="SIGN UP"
                  style="inverted"
                />
              </Form.Item>
              {/* {error && (
                <Alert type="error" message={error} className="rounded-xl" />
              )} */}
            </Form>
          )}
          <p className="mt-6 mb-4 text-center box-border">
            <span className="mr-1 text-gray-600 box-border">
              Already have an account?
            </span>
            <Link
              to="/"
              className="text-violet no-underline box-border hover:text-indigo-600 hover:no-underline"
            >
              <span className="cursor-pointer box-border">Sign in instead</span>
            </Link>
          </p>
          <LoginWithSocial />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
