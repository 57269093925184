import { BaseRepository } from './base.repository';
import { Client } from '@wiply/repository';
import { slugify } from '@wiply/utils';
import moment from 'moment';

export class ClientRepository extends BaseRepository<Client> {
  constructor() {
    super('clients');
  }

  updateGamesAmount(id: string, increment: boolean) {
    this.increment(id, 'games', increment ? 1 : -1);
  }

  async createDefaultClient(name: string, email: string) {
    const id = `${slugify(name)}`;
    const entity = {
      name,
      email,
      dateCreated: moment().format('YYYYMMDDhhmmss'),
      freePlan: true,
    };
    const userId = await this.create(slugify(name), entity);
    if (userId) return { ...entity, id: id.toLocaleLowerCase() };
    return id;
  }

  checkFreeTiralEnds = (dateCreated: string) => {
    const expiralDate = moment(dateCreated, 'YYYYMMDDhhmmss').add(
      21,
      'days'
    );
    return expiralDate.isBefore(moment())
  };
}
