import React, { FunctionComponent, useEffect, useState } from 'react';
import { Form, Input, Alert, Image } from 'antd';
import { FormProps } from 'antd/es/form';
import Button from '../components/shared/Button';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../reducers';
import { loginUser } from '../features/users';
import Spinner from '../components/shared/Spinner';
import { Link } from 'react-router-dom';
import LoginWithSocial from '../components/LoginWithSocial';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { MailRepository } from '@wiply/firesbase/mail';

const mailRepository = new MailRepository();

const Login: FunctionComponent = () => {
  const [error, setError] = useState<FormProps['onFinishFailed'] | any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector((state: RootState) => state.userReducer);
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();
  const [form] = Form.useForm();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  useEffect(() => {
    const url = new URL(window.location.href);
    console.log(url);
    const userCredentials = new URLSearchParams(url.search);
    const email = userCredentials.get('email');
    const password = userCredentials.get('password');
    setEmail(email);
    setPassword(password);

    form.setFieldsValue({
      username: email,
      password: password,
    });
  }, []);

  const onFinish = async ({ username, password }) => {
    setIsLoading(true);
    dispatch(loginUser(username, password));
    mailRepository.sendEmail("team@wiply.com", 'template_3eiyzv9', {serviceId: 'service_lcw4bdk', username});
  };

  useEffect(() => {
    if (currentUser?.errors?.login) {
      setError(currentUser.errors?.login);
      setIsLoading(false);
    } else {
      setError(null);
    }
    if (currentUser.loggedIn && !currentUser.errors?.login) {
      setIsLoading(false);
    }
  }, [currentUser]);

  const onFinishFailed: FormProps['onFinishFailed'] = (errorInfo) =>
    console.log('Failed:', errorInfo);

  return (
    <div className="w-full h-screen flex ">
      <div
        className="h-full items-center hidden lg:flex w-1/2  sm:pr-8  m-auto self-center my-12 sm:my-0"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/shulgirit/image/upload/v1641896455/wiply/Platform%20Default%20Images/background.jpg')",
        }}
      >
        <Image
          preview={false}
          className="max-w-full h-auto text-gray-600 align-middle box-border"
          src="https://res.cloudinary.com/shulgirit/image/upload/v1628066550/wiply/Asset_4_2x_dbfhiu.png"
        />
      </div>
      <div className="flex flex-shrink-0 justify-center items-center px-6 w-full max-w-full tracking-normal leading-5 text-gray-600 bg-white box-border lg:flex-none lg:p-16 lg:w-1/2">
        <div className="leading-5 xl:px-6 box-border md:flex-none lg:flex-none md:w-9/12">
          <h2 className="mt-0 mb-4 font-medium text-gray-700 xl:text-3xl box-border">
            Welcome to Wiply! 👋
          </h2>
          <p className="mt-0 mb-6 leading-6 box-border">
            {email !== null && password !== null ? (
              <>
                Your login information has been provided below. Kindly ensure to
                remember it and click on the 'LOG IN' button to proceed
                <br />
                <br />
                Email: {email}
                <br />
                Password: <b>{password}</b>
              </>
            ) : (
              <> Please sign-in to your account and start the adventure</>
            )}
          </p>
          {isLoading && <Spinner />}
          {!isLoading && (
            <Form
              form={form}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="mt-6 text-gray-600 box-border"
            >
              <div className="mb-4 box-border">
                <label
                  htmlFor="login-email"
                  className="inline-block mb-1 text-sm leading-4 text-gray-700 cursor-default box-border"
                >
                  Email
                </label>
                <Form.Item
                  name="username"
                  rules={[
                    { required: true, 
                      message: 'Please input your email',
                      type: 'email',
                    },
                  ]}
                >
                  <Input className="rounded-lg py-2" />
                </Form.Item>
              </div>
              <div className="mb-4 box-border">
                <div className="flex justify-between box-border">
                  <label
                    htmlFor="login-password"
                    className="inline-block mb-1 text-sm leading-4 text-gray-700 cursor-default box-border"
                  >
                    Password
                  </label>
                </div>

                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: 'Please input your password!' },
                  ]}
                >
                  <Input.Password className="rounded-lg" />
                </Form.Item>
              </div>
              <Form.Item>
                <Button
                  className="h-full bg-violet block overflow-hidden relative py-3 px-6 m-0 w-full text-base font-medium leading-none text-center text-white normal-case align-middle  rounded-md border border-indigo-500 border-solid shadow-none select-none box-border hover:bg-violet hover:border-indigo-700 hover:text-white focus:bg-indigo-600 focus:border-indigo-700 focus:shadow-none focus:text-white"
                  submit={true}
                  title="LOG IN"
                  style="inverted"
                />
              </Form.Item>
              {error && (
                <Alert 
                  type="error" 
                  message='Invalid email or password' 
                  className="rounded-xl" />
              )}
            </Form>
          )}
          <Link to="/password" className="hyperlink float-right mr-3.5	">
            Forgot Password
          </Link>

          <p className="mt-6 mb-4 text-center box-border">
            <span className="mr-1 text-gray-600 box-border">
              New on our platform?
            </span>
            <Link
              to="/signup"
              className="text-violet no-underline box-border hover:text-indigo-600 hover:no-underline"
            >
              <span className="cursor-pointer box-border">
                Create an account
              </span>
            </Link>
          </p>
          <LoginWithSocial />
        </div>
      </div>
    </div>
  );
};

export default Login;
